import { Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from '../../../assets/themes/colors/colors'
import BgContainer from '../../../components/UI/BgContainer/BgContainer'
import Connect from '../../../components/UI/Connect/Connect'
import Loading from '../../../components/UI/Loading/Loading'
import { keys } from '../../../constants/keys'
import { getSinglePartnerInfo } from '../../../data/reducers/partners/info/asyncAction'
import { fetchSalonServices } from '../../../data/reducers/services/partner/asyncAction'
import { getDataFromLocalStorage } from '../../../utils/helpers/localStorage'
import PartnerProfile from './PartnerProfile/PartnerProfile'
import PartnerServices from './PartnerServices/PartnerServices'
import { indexToParams, paramsToIndex } from './tabRoutes'

const SinglePartner = () => {
  const params = useParams()
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { singlePartner, loading } = useSelector((state) => state.partners_info)
  const { role } = useSelector(state => state.auth)
  const [selectedTab, setSelectedTab] = useState(
    paramsToIndex[params?.tab] || 0
  )

  useEffect(() => {
    setSelectedTab(paramsToIndex[params?.tab] || 0)
  }, [params?.tab])

  useEffect(() => {
    if (+params?.partnerId !== +getDataFromLocalStorage(keys.KEY_PARTNER_ID))
      dispatch(getSinglePartnerInfo(params?.partnerId))
  }, [params?.partnerId])

  useEffect(
    () => params?.partnerId && dispatch(fetchSalonServices(params?.partnerId)),
    [params?.partnerId]
  )

  const handleChange = (_, newValue) => {
    navigate(`/partner/${params?.partnerId}/${indexToParams[newValue]}`, { replace: true })
    setSelectedTab(newValue)
  }

  if (loading)
    return (
      <div className='app-loading'>
        <Loading size='loader-medium' />
      </div>
    )

  return (
    <BgContainer
      title={singlePartner?.profileDetails?.name || '  '}
      noBack={role === 'Partner' ? true : false}
      rightComponent={
        <Connect
          email={singlePartner?.profileDetails?.email}
          phone={singlePartner?.profileDetails?.phone}
        />
      }
      component={
        <div style={{ backgroundColor: colors.background, marginBottom: -20 }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab label={'profile'} aria-label={'profile'} />
            <Tab label={'services'} />
          </Tabs>
        </div>
      }
    >
      <div
        style={{
          display: selectedTab === 0 ? 'block' : 'none',
        }}
      >
        <PartnerProfile />
      </div>
      <div style={{ display: selectedTab === 1 ? 'block' : 'none' }}>
        <PartnerServices />
      </div>
    </BgContainer>
  )
}

const useStyles = makeStyles({
  tabs: {
    '& .MuiButtonBase-root.MuiTab-root': {
      fontSize: 16,
      paddingBottom: 2,
      textTransform: 'capitalize',
    },
  },
})

export default SinglePartner
