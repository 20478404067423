import axios from '../../utils/axios/axiosInstance'
import { constants } from '../../utils/config'

export const api = constants.endpoint

export const error = (e) => {
  return { status: 'error', isSuccessful: false, message: e }
}
export const success = (data, isCached = false) => {
  return { status: 'success', isSuccessful: true, data: data, isCached }
}

export const getAuthHeaders = async () => {
  return {
    Authorization:
      'Bearer ' + window.localStorage.getItem(constants.KEY_AUTH_TOKEN),
  }
}

export const get = async (url, headers) => {
  try {
    let response = await axios.get(url, { headers })
    return success(response.data)
  } catch (e) {
    console.log(e)
    if (e.response?.data) {
      return error(e.response.data.statusMessage)
    }
    return error(e)
  }
}

export const post = async (url, data, headers) => {
  try {
    let response = await axios.post(url, data, { headers })
    return success(response.data)
  } catch (e) {
    if (e.response?.data) {
      return error(e.response.data.statusMessage)
    }
    return error(e)
  }
}

export const download = async (url, data, headers) => {
  try {
    let response = await axios.post(url, data, {
      headers,
      responseType: 'blob',
    })
    return success(response.data)
  } catch (e) {
    console.log(e)
    if (e.response?.data) {
      return error(e.response.data.statusMessage)
    }
    return error(e)
  }
}

export const update = async (url, data, headers) => {
  try {
    let response = await axios.put(url, data, { headers })
    return success(response.data)
  } catch (e) {
    console.log('e', e)
    if (e.response?.data) {
      return error(e.response.data.statusMessage)
    }
    return error(e)
  }
}

export const del = async (url, headers) => {
  try {
    let response = await axios.delete(url, { headers })
    return success(response.data)
  } catch (e) {
    console.log(e)
    if (e.response?.data) {
      return error(e.response.data.statusMessage)
    }
    return error(e)
  }
}

export const request = async (url = '', method = 'GET', data = null) => {
  try {
    const response = await axios({ url, method, data })
    return { response, status: 'success' }
  } catch (error) {
    console.log('error', error)
    if (error.response) {
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }

    return {
      response: error.response ? error.response.data : 'Something went wrong ',
      status: 'failure',
    }
  }
}
