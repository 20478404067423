import React, { memo } from 'react'
import './Modal.css'

const Modal = ({ children, show = false, close = () => {} }) => {
  return (
    <>
      <main
        onClick={close}
        className={`${show ? 'overlay show-overlay' : ''} overlay`}
      ></main>
      <section className={`${show ? 'show-modal' : ''} modal-container`}>
        {children}
      </section>
    </>
  )
}

export default memo(Modal)
