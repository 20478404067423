import React, { useState } from 'react'
import { Typography as Text, Checkbox, Box, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { submitData } from '../../../data/reducers/onboarding/formData.reducer'
import Modal from './Modal'
import { Link } from 'react-router-dom'
import { icons } from '../../../assets/icons/icons'
import Loading from '../../../components/UI/Loading/Loading'
import { useToggle } from '../../../hooks/useToggle'

function Onboarding3({ data, setCurrentScreen }) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [value, toggle] = useToggle()
  const [load, setLoad] = useState(false)
  const handleSubmit = async () => {
    setLoad(true)
    await dispatch(submitData(data))
    setOpen(true)
    setLoad(false)
  }
  return (
    <div className='basic'>
      <Text gutterBottom variant='h4'>
        Agreement - Terms & conditions
      </Text>
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in luctus
        lorem. Sed non augue consequat, pellentesque leo ac, porta arcu. Vivamus
        ultricies orci erat, ut finibus metus feugiat eget. Integer quis
        pulvinar ligula, id faucibus leo. Nunc lectus turpis, aliquet vel
        lacinia id, porttitor nec massa. Praesent vulputate ante nec lacus
        suscipit ultrices. Ut porta tellus eget enim malesuada, sollicitudin
        varius est scelerisque. Aliquam pulvinar commodo lacus. Mauris vel
        malesuada libero, ut auctor neque. Etiam id efficitur nisi, sagittis
        suscipit velit. Nam at lacus eget magna scelerisque tincidunt non non
        sem. Suspendisse pellentesque congue justo, id pulvinar felis dignissim
        sed. Aenean sit amet dolor diam. Nullam eu quam a ipsum tempus viverra
        nec eget libero. Duis ac nisi eu tellus lobortis egestas. Proin laoreet,
        sapien vel consectetur aliquet, mauris nulla blandit enim, at
        condimentum mauris tellus non est. Suspendisse auctor, mauris sed
        aliquam finibus, elit leo convallis ipsum, eget hendrerit nisl arcu at
        neque. Sed ullamcorper, diam non consectetur aliquet, mi tortor
        fermentum ligula, rutrum euismod mi ante ac nisl. Suspendisse gravida
        augue quis erat finibus hendrerit. Maecenas dictum imperdiet ultricies.
        Duis quis blandit turpis, at congue mauris. Proin sit amet justo justo.
        Quisque pharetra euismod justo non pulvinar. Proin velit risus, luctus
        ac condimentum et, venenatis nec leo. Maecenas imperdiet enim ac arcu
        tincidunt porta. Sed quis iaculis elit, non interdum nibh. Maecenas
        scelerisque diam lorem, ut tincidunt dolor placerat varius. Suspendisse
        porta, ex sed euismod sodales, leo sem cursus erat, ut viverra magna
        purus at eros. Donec nec scelerisque dui, sit amet bibendum ipsum.
        Pellentesque tortor eros, tincidunt vitae fermentum at, euismod quis
        lectus. Nam eget sodales ex. Duis ante turpis, rhoncus a feugiat
        laoreet, pulvinar in diam. Praesent efficitur ipsum a risus efficitur
        porttitor. Vestibulum tempor ut augue at facilisis. Aenean nunc purus,
        vestibulum at justo a, varius rhoncus nisi. Phasellus feugiat urna in
        mauris dignissim, eget cursus justo consectetur. Nam varius lobortis mi.
        Nullam sed mauris ac tellus ornare viverra vel id eros. Praesent maximus
        id orci ac imperdiet. Vivamus faucibus, lectus efficitur pharetra
        feugiat, nisi turpis posuere libero, eget porta libero quam nec felis.
        Nulla a risus odio. Mauris fringilla nec massa id bibendum. Maecenas
        placerat est magna, at fringilla urna tempus et. Phasellus pulvinar
        vulputate laoreet. Morbi placerat quis tortor vel faucibus. Sed posuere
        nec augue id porta. Aliquam vitae hendrerit tortor. Aliquam laoreet
        feugiat suscipit. Pellentesque condimentum dignissim augue ut euismod.
        Sed eu accumsan orci. Mauris euismod massa ut enim laoreet laoreet.
        Maecenas porttitor consectetur molestie. Morbi id eros ac nibh accumsan
        semper. Curabitur at enim sed leo fringilla auctor eget sed elit.
      </Text>{' '}
      <br />
      <Text>
        <Checkbox value={value} onChange={toggle} /> I agree to MakeOver's Terms
        and Conditions
      </Text>
      <Box className='d-flex'>
        <Button
          onClick={() => setCurrentScreen((p) => p - 1)}
          sx={{ mr: 2 }}
          variant='outlined'
        >
          PREVIOUS
        </Button>
        <Button
          color='primary'
          onClick={handleSubmit}
          disabled={!value}
          variant='contained'
        >
          {load ? <Loading /> : 'submit'}
        </Button>
      </Box>
      <Modal open={open} setOpen={setOpen}>
        <Text>
          <b>Welcome Aboard!</b>
        </Text>
        <img src={icons().onBoardSuccess} alt='success-onboard' />
        <Box className='mt-3 d-flex w-100 justify-content-between'>
          <Link style={{ width: '47%' }} to='/'>
            <Button variant='outlined' sx={{ width: '100%' }}>
              Homepage
            </Button>
          </Link>
          <Link style={{ width: '47%' }} to='/login'>
            <Button variant='contained' sx={{ width: '100%' }}>
              Login
            </Button>
          </Link>
        </Box>
      </Modal>
    </div>
  )
}

export default Onboarding3
