import { Box } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CategoryDrawer from '../../../components/ServicesPageComponents/CategoryDrawer/CategoryDrawer'
import ServiceAccordion from '../../../components/ServicesPageComponents/ServiceAccordion/ServiceAccordion'
import Button from '../../../components/UI/Button/Button'
import { PageLoading } from '../../../components/UI/Loading/Loading'
import { getServicesList } from '../../../data/reducers/services/admin/asyncAction'
import AddService from '../AddService/AddService'

const SingleCategory = () => {
  const params = useParams()
  const [showService, setShowService] = useState(false)
  const dispatch = useDispatch()
  const [selectedCategory, setSelectedCategory] = useState({})
  const { servicesList, servicesLoading, categories } = useSelector(
    (state) => state.services_admin
  )

  useEffect(() => {
    const selectedCategory = categories?.find(
      (category) => category.id === +params?.categoryId
    )
    selectedCategory && setSelectedCategory(selectedCategory)
  }, [params?.categoryId, categories])

  useEffect(() => {
    if (params?.categoryId) {
      dispatch(getServicesList({ id: params.categoryId }))
    }
  }, [params?.categoryId])

  const _renderServices = useMemo(
    () =>
      servicesList?.map((service, index) => (
        <div key={index} className='margin-t-s'>
          <ServiceAccordion {...service} />
        </div>
      )),
    [servicesList]
  )

  return (
    <Box sx={{ pl: 6.5, pr: 5, mt: 8 }}>
      <div className='flex-row'>
        <CategoryDrawer />
        <section className='list-section'>
          <div className='flex-x-btw'>
            <h3>{selectedCategory?.label}</h3>
            <Button label='Add Service' onClick={() => setShowService(true)} />
          </div>
          {servicesLoading ? (
            <PageLoading />
          ) : (
            <div className='margin-t-m' style={{ maxWidth: 900 }}>
              {_renderServices}
            </div>
          )}
        </section>
      </div>
      {showService && (
        <AddService
          showModal={showService}
          close={() => setShowService(false)}
          serviceCategoryId={+params?.categoryId}
        />
      )}
    </Box>
  )
}

export default SingleCategory
