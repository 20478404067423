import { createAsyncThunk } from '@reduxjs/toolkit'
import { keys } from '../../../../../constants/keys'
import { convertJsonToFormData } from '../../../../../utils/helpers/JsonToFormData'
import { request } from '../../../../services/services.common'
import { showAlert } from '../../../common/alert.reducer'

/*------------------Edit User Account-------------------*/

export const editAccount = createAsyncThunk(
  'partner_info/edit',

  async (payload, { rejectWithValue, dispatch }) => {
    const { data, id, goBack } = payload
    const url = `UserDetails/Partner/update/${id}`
    const getUserUrl = `/Values/getpartnerdetails?id=${id}`
    const formData = convertJsonToFormData(data)

    const editResponse = await request(url, 'PUT', formData)
    const { response, status } = await request(getUserUrl, 'GET')

    /*----- Success Response----- */

    if (editResponse.status === 'success' && status === 'success') {
      const singlePartner = response.data?.data
      localStorage.setItem(keys.KEY_PARTNER_DATA, JSON.stringify(singlePartner))
      typeof goBack === 'function' && goBack()
      dispatch(
        showAlert({
          type: 'success',
          message: 'Updated partner profile successfully.',
        })
      )
      return {
        singlePartner,
      }
    }
    /*----- Failure Response----- */

    if (editResponse.status === 'failure' || status === 'failure') {
      dispatch(
        showAlert({
          type: 'error',
          message: `Couldn't update the profile. Please try again.`,
        })
      )

      return rejectWithValue(response)
    }
  }
)
