import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DataInput from '../../../../../components/PartnerPageComponents/DataInput/DataInput'
import BgContainer from '../../../../../components/UI/BgContainer/BgContainer'
import Button from '../../../../../components/UI/Button/Button'
import Connect from '../../../../../components/UI/Connect/Connect'
import Loading from '../../../../../components/UI/Loading/Loading'
import { URL_PATTERN } from '../../../../../constants/pattern'
import {
  getPartnerSalonImages,
  getSinglePartnerInfo,
  postCoverImage,
} from '../../../../../data/reducers/partners/info/asyncAction'
import coverImgPlaceholder from '../../../../../assets/images/Square placeholder.svg'
import { styles } from './styles'
import { editAccount } from '../../../../../data/reducers/partners/info/update/asyncAction'
import { getDropDownValues } from '../../../../../data/reducers/common/asyncAction'
import { getDataFromLocalStorage } from '../../../../../utils/helpers/localStorage'
import { keys } from '../../../../../constants/keys'

const Account = () => {
  const {
    singlePartner,
    loading,
    btnLoading,
    coverImage: coverPic,
  } = useSelector((state) => state.partners_info)
  const { businessType } = useSelector((state) => state.common)
  const [coverImage, setCoverImage] = useState({
    fileUrl: '',
    image: {},
  })
  const [editMode, setEditMode] = useState(false)
  const params = useParams()
  const dispatch = useDispatch()
  const accountData = singlePartner?.profileDetails
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' })

  const inputProps = { control, error: errors }

  useEffect(() => {
    if (+params?.partnerId !== +getDataFromLocalStorage(keys.KEY_PARTNER_ID))
      dispatch(getSinglePartnerInfo(params?.partnerId))
  }, [params?.partnerId])

  useEffect(() => !businessType?.length && dispatch(getDropDownValues()), [])

  useEffect(
    () =>
      accountData?.id &&
      dispatch(getPartnerSalonImages({ partnerId: accountData?.id })),
    [accountData?.id]
  )

  const uploadImage = useCallback((e) => {
    const files = e.target?.files ? e.target?.files : []
    if (URL) {
      const fileUrl = URL.createObjectURL(files?.[0])
      setCoverImage({ image: files?.[0], fileUrl })
    }
  }, [])

  const onSubmit = useCallback(
    (data) => {
      const partnerId = accountData?.id
      const payload = {
        id: partnerId,
        data: { ...accountData, ...data },
        goBack: () => setEditMode(false),
      }
      delete payload.data?.coverImage
      dispatch(editAccount(payload))
      dispatch(postCoverImage({ partnerId, coverImage: coverImage.image }))
    },
    [accountData, coverImage.image]
  )

  if (loading)
    return (
      <div className='app-loading'>
        <Loading size='loader-medium' />
      </div>
    )

  return (
    <BgContainer
      contentStyle={{ paddingBottom: 25 }}
      title={singlePartner?.profileDetails?.name || '  '}
      rightComponent={
        <Connect
          email={singlePartner?.profileDetails?.email}
          phone={singlePartner?.profileDetails?.phone}
        />
      }
    >
      <div className='flex-x-btw'>
        <p style={{ fontSize: 30 }}>Account</p>
        <div className='flex-row'>
          {editMode && (
            <Button
              label={'cancel'}
              variant='outlined'
              onClick={() => setEditMode(false)}
            />
          )}
          <Button
            btnLoading={btnLoading}
            label={editMode ? 'Save' : 'Edit'}
            onClick={
              editMode ? handleSubmit(onSubmit) : () => setEditMode(true)
            }
          />
        </div>
      </div>

      <div
        className='margin-t-s flex-row remove-flex-900'
        style={styles.container}
      >
        <div style={{ flex: 1 / 2 }}>
          <div>
            <DataInput
              label={`Name of Business${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.name || ''}
              inputProps={{
                ...inputProps,
                name: 'name',
                placeholder: 'Name of Business',
                rules: {
                  required: { value: true, message: 'Name must be provided' },
                },
              }}
            />

            <DataInput
              label={`Owner Name${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.nameOfOwner || ''}
              inputProps={{
                ...inputProps,
                name: 'nameOfOwner',
                placeholder: 'Username',
                rules: {
                  required: {
                    value: true,
                    message: 'username must be provided',
                  },
                },
              }}
            />
            <DataInput
              label={`Email Id${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.email || ''}
              inputProps={{
                ...inputProps,
                name: 'email',
                placeholder: 'Email',
                rules: {
                  required: { value: true, message: 'email must be provided' },
                },
              }}
            />

            <DataInput
              label={`Contact No.${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.phone || ''}
              inputProps={{
                ...inputProps,
                placeholder: 'Contact No.',
                name: 'phone',
                rules: {
                  required: { value: true, message: 'Required field' },
                },
              }}
            />

            <DataInput
              label={`Business Type${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.partnerTypeId || 1}
              salonTypeInput
              inputProps={{
                ...inputProps,
                data: businessType,
                setValue,
              }}
            />

            <div
              className={`flex-row data-input margin-t-m`}
              style={{ position: 'relative' }}
            >
              <div className='label'>Cover Image</div>
              <div>
                <img
                  style={styles.img}
                  src={
                    coverImage.fileUrl ||
                    coverPic?.fileUrl ||
                    coverImgPlaceholder
                  }
                  alt='CoverImage'
                />
                {editMode && (
                  <DataInput
                    editMode={editMode}
                    inputProps={{
                      ...inputProps,
                      type: 'file',
                      accept: 'image/*',
                      customChange: uploadImage,
                      label: 'Upload Image',
                      containerStyle: styles.containerStyle,
                      labelStyle: styles.labelStyle,
                      name: 'coverImage',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* -------------------------------------------------------------------------- */}
        <div style={{ flex: 1 / 2 }}>
          <div>
            <DataInput
              label={`City${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.city || ''}
              inputProps={{
                ...inputProps,
                name: 'city',
                placeholder: 'City',
                rules: {
                  required: { value: true, message: 'City must be provided' },
                },
              }}
            />

            <DataInput
              label={`State${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.state || ''}
              inputProps={{
                ...inputProps,
                name: 'state',
                placeholder: 'State',
                rules: {
                  required: {
                    value: true,
                    message: 'State must be provided',
                  },
                },
              }}
            />
            <DataInput
              label={`Postal Address${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.address || ''}
              inputProps={{
                ...inputProps,
                name: 'address',
                placeholder: 'Postal Address',
                rules: {
                  required: {
                    value: true,
                    message: 'Address must be provided',
                  },
                },
              }}
            />

            <DataInput
              label={`Pin code${editMode ? '*' : ''}` }
              editMode={editMode}
              defaultValue={accountData?.pinCode || ''}
              inputProps={{
                ...inputProps,
                placeholder: 'Pin code',
                name: 'pinCode',
                rules: {
                  required: { value: true, message: 'Required field' },
                },
              }}
            />
            <DataInput
              label='Website'
              editMode={editMode}
              defaultValue={accountData?.websiteLink || ''}
              inputProps={{
                ...inputProps,
                placeholder: 'Website',
                name: 'websiteLink',
                rules: {
                  pattern: {
                    value: URL_PATTERN,
                    message: 'Must be valid url of your website',
                  },
                },
              }}
            />
            <DataInput
              label='Description'
              editMode={editMode}
              defaultValue={accountData?.description || ''}
              inputProps={{
                ...inputProps,
                placeholder: 'Description',
                name: 'description',
                Component: 'textarea',
                row: 20,
                column: 5,
                maxLength: 245
              }}
            />
          </div>
        </div>
      </div>
    </BgContainer>
  )
}

export default Account
