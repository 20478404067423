import React, { memo, useEffect } from 'react'
import {
  Typography,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  CssBaseline,
  Autocomplete,
  ListItem,
} from '@mui/material'
import { colors } from '../../../assets/themes/colors/colors'
import { icons } from '../../../assets/icons/icons'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import SideDrawer from '../SideDrawer/SideDrawer'
import AppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { SearchRounded } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Header = ({ selectedLink = {}, setSelectedLink = () => { } }) => {
  const [shouldClose, setShouldClose] = useState(true)
  const [drawer, setDrawer] = useState(false)

  // temporarily adding for the search functionality only for partners list
  const navigate = useNavigate()
  const { allPartners, loading } = useSelector((state) => state.partners_info)
  const { role } = useSelector(state => state.auth)
  const [_allPartners, _setAllPartners] = useState(allPartners)
  useEffect(() => {
    const _allPartners = allPartners
      .map((data) => ({
        id: +data.partnerDetails?.id,
        label: data.partnerDetails?.name?.toLowerCase(),
      }))
      ?.filter(
        (data) => typeof data.label === 'string' && typeof data.id === 'number'
      )
    _allPartners?.length
      ? _setAllPartners(_allPartners)
      : _setAllPartners([{ label: '' }])
  }, [allPartners])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          sx={{ pl: 1, bgcolor: colors.deepPurple, zIndex: 1300 }}
          position='fixed'
        >
          <CssBaseline />
          <Toolbar className='flex-row'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ marginLeft: '-23px' }}
              onClick={() => {
                setDrawer((v) => !v)
                setShouldClose((v) => !v)
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container item xs={12} justifyContent='space-between'>
              <Grid container alignItems='center' item xs={4} gap={1}>
                <Avatar src={icons().makeover_icon_small} />
                <Typography variant='body1'>MakeOver</Typography>
              </Grid>

              {
                //------------------- temporary  features------------------------------//
              }

              {role === 'Admin' && <Grid
                alignItems='center'
                justifyContent='center'
                container
                item
                xs={4}
              >
                <Autocomplete
                  loading={loading}
                  options={_allPartners}
                  freeSolo
                  disablePortal
                  sx={{ width: '100%' }}
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  renderOption={(props, option) => {
                    return (
                      <ListItem
                        {...props}
                        onClick={(e) => {
                          navigate(`/partner/${option.id}/profile`)
                          props.onClick(e)
                        }}
                        sx={{ textTransform: 'capitalize' }}
                        key={option.id}
                      >
                        {option.label}
                      </ListItem>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      placeholder='Search for partners'
                      sx={{
                        bgcolor: 'white',
                        borderRadius: 1.5,
                        height: 35,
                        pr: 1,
                      }}
                      InputProps={{
                        ...params?.InputProps,
                        style: { fontSize: 15 },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton>
                              <SearchRounded fontSize='small' />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>}
              {
                //------------------- temporary  features------------------------------//
              }

              <ProfileMenu />
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <SideDrawer
        drawer={drawer}
        selectedLink={selectedLink}
        setSelectedLink={setSelectedLink}
        setDrawer={setDrawer}
        shouldClose={shouldClose}
        setShouldClose={setShouldClose}
      />
    </>
  )
}

export default memo(Header)
