import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import Button from '../../components/UI/Button/Button'
import { DataGrid } from '@mui/x-data-grid'
import useWindowDimensions from '../../hooks/useWindow'
import './styles.css'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPartnersList, verifyPartnerFromAdmin } from '../../data/reducers/partners/info/asyncAction'
import { columns, rows } from './datagrid'

const Partners = () => {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const classes = useStyles()
  const { allPartners, loading } = useSelector((state) => state.partners_info)
  const dispatch = useDispatch()

  // console.log(allPartners)
  useEffect(() => {
    !allPartners?.length && dispatch(getAllPartnersList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const verifyToggle = useCallback((data) => {
    dispatch(verifyPartnerFromAdmin(data))
      .then(() => dispatch(getAllPartnersList()))
  }, [])

  const _cols = useMemo(
    () =>
      columns({ width, verifyToggle })?.map((data) => ({
        ...data,
        cellClassName: 'super-app-theme--cell',
        renderHeader: (cellValues) => {
          return (
            <div className='col-title'>{cellValues?.colDef?.headerName}</div>
          )
        },
      })),
    [width, verifyToggle]
  )

  const onRowClick = useCallback((data) => {
    navigate(`/partner/${data?.row.id}/profile`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ pl: 10, pr: 5, mt: 10 }}>
      <div className='flex-x-btw'>
        <div xs={10.4}>
          <Typography variant='h4'>Partners</Typography>
        </div>
        <div xs={1.6}>
          <Button label='Add Partner' onClick={() => navigate('add-partner')} />
        </div>
      </div>
      <Box>
        <div
          style={{ height: 560, width: '100%', flexGrow: 1 }}
          className={`${classes.root} margin-t-s`}
        >
          <DataGrid
            loading={loading}
            pagination
            rows={rows(allPartners)}
            columns={_cols}
            hideFooterSelectedRowCount
            onRowClick={onRowClick}
            rowBuffer={100}
            rowThreshold={100}
          />
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      outline: 'none !important',
      textTransform: 'capitalize',
      cursor: 'pointer',
    },
  },
})

export default Partners
