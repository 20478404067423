import { IconButton, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import TimePicker from '@mui/lab/TimePicker'
import { DeleteOutline } from '@mui/icons-material'
import './style.css'
import { useController, useFormContext } from 'react-hook-form'

const SelectTime = ({
  title = '',
  from = 'from',
  to = 'to',
  uncontrolled = true,
  onRemove = () => {},
  remove = true,
  defaultFromTime = null,
  defaultToTime = null,
  editMode,
  rules,
  disabled = false,
  getTimings = () => {},
}) => {
  const [fromTime, setFromTime] = useState(null)
  const initialMount = useRef(false)
  const [toTime, setToTime] = useState(null)
  const { control } = useFormContext()
  const fromController = useController({
    control,
    name: from,
    defaultValue: null,
    rules,
  })
  const toController = useController({
    control,
    name: to,
    defaultValue: null,
    rules,
  })

  useEffect(() => {
    if (initialMount.current === true) {
      getTimings({ fromTime, toTime })
    }
    return () => (initialMount.current = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTime, toTime])

  useEffect(() => {
    if (defaultFromTime && defaultToTime) {
      setFromTime(defaultFromTime)
      setToTime(defaultToTime)
      return
    }
    setFromTime(defaultFromTime)
    setToTime(defaultToTime)
  }, [defaultToTime, defaultFromTime, editMode])

  return (
    <div className='margin-t-s'>
      <h6 style={{ fontWeight: 'bold' }}>{title}</h6>
      <div className='flex-x-around margin-t-s time-picker'>
        <div>
          <TimePicker
            disabled={disabled}
            minutesStep={5}
            label='From'
            value={uncontrolled ? fromTime : fromController.field?.value}
            renderInput={(params) => <TextField variant='filled' {...params} />}
            onChange={
              uncontrolled
                ? (newValue) => setFromTime(newValue)
                : (newValue) => fromController.field?.onChange(newValue)
            }
          />
        </div>

        <div>
          <TimePicker
            disabled={disabled}
            showTodayButton
            minutesStep={5}
            label='To'
            value={uncontrolled ? toTime : toController.field?.value}
            renderInput={(params) => <TextField variant='filled' {...params} />}
            onChange={
              uncontrolled
                ? (newValue) => setToTime(newValue)
                : (newValue) => toController.field?.onChange(newValue)
            }
          />
        </div>

        <div>
          {remove ? (
            <IconButton onClick={onRemove}>
              <DeleteOutline />
            </IconButton>
          ) : (
            <div className='m-right-m'></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SelectTime
