import React, { memo } from 'react'
import { Button as MuiButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'

const Button = ({ label, sx = {}, btnLoading = false, ...rest }) => {
  if (btnLoading) {
    return (
      <LoadingButton
        loading
        sx={{ minWidth: 130, ...sx }}
        loadingPosition='start'
        startIcon={<SaveIcon />}
        variant='outlined'
        {...rest}
      >
        {label}
      </LoadingButton>
    )
  }
  return (
    <MuiButton
      variant='contained'
      sx={{ borderRadius: 1.4, minWidth: 130, ...sx }}
      {...rest}
    >
      {label}
    </MuiButton>
  )
}

export default memo(Button)
