import React from 'react'
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { Lock, LockOpen } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Background from '../../../components/AuthCompoenets/Background/Background'
import { loginUser } from '../../../data/reducers/auth/auth.reducer'
import { useToggle } from '../../../hooks/useToggle'
import { Loading } from '../../../components/UI/Loading/Loading'

const LoginPage = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, error } = useSelector((state) => state.auth)
  const [showPassword, setShow] = useToggle()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  function onSubmit(data) {
    dispatch(loginUser({ ...data, navigate: () => navigate('/partner') }))
  }
  return (
    <>
      <Background title='Login'>
        <Box sx={styles.box}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ mb: 3 }}>
              <Typography variant='p' sx={{ fontWeight: 'bold' }}>
                Username
              </Typography>
              <TextField
                name='name'
                color='primary'
                variant='outlined'
                className='w-100'
                placeholder='Username'
                {...register('name', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                })}
                helperText={errors.name?.message}
                error={errors['name'] ? true : false}
              />
            </Box>
            <Box>
              <Typography variant='p' sx={{ fontWeight: 'bold' }}>
                Password
              </Typography>
              <TextField
                name='password'
                variant='outlined'
                className='w-100'
                placeholder='Password'
                type={!showPassword ? 'password' : 'text'}
                {...register('password', {
                  required: {
                    value: true,
                    message: 'This field is required',
                  },
                })}
                helperText={errors.password?.message}
                error={errors['password'] ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <IconButton onClick={setShow}>
                        {!showPassword ? <Lock /> : <LockOpen />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {error && (
              <p
                className='margin-t-s'
                style={{
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                {error}
              </p>
            )}
            <Box className='text-right d-flex justify-content-end'>
              <Button
                type='submit'
                variant='contained'
                className='w-50 my-4 text-right'
                disabled={loading}
                sx={{ height: 48 }}
              >
                {loading ? <Loading color='white' /> : 'Login'}
              </Button>
            </Box>
            <Box className='d-flex'>
              New To MakeOver?
              <Link to='/become-partner'>
                <Typography
                  sx={{ ml: 3, textDecoration: 'underline' }}
                  color='primary'
                >
                  <span>Become a Partner</span>
                </Typography>{' '}
              </Link>
            </Box>
          </form>
        </Box>
      </Background>
    </>
  )
}

const styles = {
  box: {
    maxWidth: 430,
    width: '100%',
    minHeight: `60vh`,
    mx: 'auto',
    my: 5,
  },
}

export default LoginPage
