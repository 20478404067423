export const getDataFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key)
    if (data) return JSON.parse(data)

    return null
  } catch (error) {
    console.log(error)
    return null
  }
}
