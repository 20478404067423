import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { theme } from './assets/themes/theme'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import reducers from './data/reducers'
import { checkLogin } from './data/reducers/auth/auth.reducer'
import HomeRoutes from './routes/HomeRoutes/HomeRoutes'
import AuthRoutes from './routes/AuthRoutes/AuthRoutes'
import PartnerRoutes from './routes/PartnerRoutes/PartnerRoutes'
import Loading from './components/UI/Loading/Loading'

const reduxStore = configureStore({
  reducer: reducers,
})

function App() {
  const dispatch = useDispatch()
  const { loggedIn, appLoading, role } = useSelector((state) => state.auth)

  console.log({ role })
  React.useEffect(() => {
    !loggedIn && dispatch(checkLogin())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  if (appLoading)
    return (
      <div className='app-loading'>
        <Loading size='loader-large' />
      </div>
    )
  return (
    <BrowserRouter>
      {
        !loggedIn ? <AuthRoutes /> :
          role === 'Admin' ? <HomeRoutes /> : <PartnerRoutes />
      }
    </BrowserRouter>
  )
}

const AppWrapper = () => {
  return (
    <Provider store={reduxStore}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default AppWrapper
