import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import googlePlay from '../../../assets/images/google-play.svg'
import { icons } from '../../../assets/icons/icons'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <footer>
      <div className='footer'>
        <div className='upperSection'>
          <div className='leftMenu'>
            <Link to='/policy'>
              <li className='footerText'>Privacy Policy</li>
            </Link>
            <Link to='/'>
              <li className='footerText'>{`Terms & Conditions`}</li>
            </Link>
            <a href='mailto:info@Make-Over.in'>
              <li className={'footerText'}>Contact Us</li>
            </a>
          </div>
          <div className='rightMenu'>
            <a href='https://play.google.com/store/apps/details?id=in.makeover.customer' target='_blank'>
              <img src={googlePlay} alt='google_play_icon' />
            </a>
          </div>
        </div>
        <div className='footerDivider' />
        <div className='lowerSection'>
          <div className='mainIcon'>
            <img src={icons().makeover_icon_small} alt='make_over_icon' />
            <span className='footerText'>MakeOver</span>
          </div>
          <div className='footerText'>
            &copy; {`  E-Bindle Services Private Limited, All Rights Reserved`}
          </div>
          <div className='socialIcons'>
            <TwitterIcon style={{ color: '#fff', fontSize: 22, marginLeft: 10, cursor: 'pointer' }} />
            <FacebookRoundedIcon style={{ color: '#fff', fontSize: 22, marginLeft: 10, cursor: 'pointer' }} />
            <InstagramIcon style={{ color: '#fff', fontSize: 22, marginLeft: 10, cursor: 'pointer' }} />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
