import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import CustomRoutes from '../Routes'
import SinglePartner from '../../pages/PartnerPages/SinglePartner/SinglePartner'
import Account from '../../pages/PartnerPages/SinglePartner/PartnerProfile/Account/Account'
import Schedule from '../../pages/PartnerPages/SinglePartner/PartnerProfile/Schedule/Schedule'
import ScheduleByDays from '../../pages/PartnerPages/SinglePartner/PartnerProfile/Schedule/ScheduleByDays'
import Amenities from '../../pages/PartnerPages/SinglePartner/PartnerProfile/Amenities/Amenities'
import AddService from '../../pages/PartnerPages/SinglePartner/PartnerServices/AddService/AddService'
import EditService from '../../pages/PartnerPages/SinglePartner/PartnerServices/EditService/EditService'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { keys } from '../../constants/keys'

const PartnerRoutes = () => {

    const [user] = useLocalStorage(keys.KEY_USER_DATA)

    return (
        <CustomRoutes>
            <Route path='/*' element={<Navigate to={`/partner/${user.partnerId}/profile`} />}></Route>
            <Route path='/partner/:partnerId/profile' element={<SinglePartner />} />
            <Route path='/partner/:partnerId/:tab' element={<SinglePartner />} />
            <Route path='/partner/:partnerId/profile/account' element={<Account />} />
            <Route path='/partner/:partnerId/profile/schedule' element={<Schedule />} />
            <Route path='/partner/:partnerId/profile/schedule/days' element={<ScheduleByDays />} />
            <Route path='/partner/:partnerId/profile/amenities' element={<Amenities />} />
            <Route path='/partner/:partnerId/add-service' element={<AddService />} />
            <Route path='/partner/:partnerId/services/:serviceId' element={<EditService />} />
        </CustomRoutes>
    )
}

export default PartnerRoutes
