import { EmailSharp, Phone } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

const Connect = ({
  email = '',
  phone = '',
  showLabel = true,
  containerStyle,
}) => {
  return (
    <div style={containerStyle}>
      {email && (
        <a
          href={`mailto:${email}`}
          className='flex-x-btw'
          style={{ gap: 8 }}
          onClick={(e) => e.stopPropagation()}
        >
          <IconButton>
            <EmailSharp />
          </IconButton>
          {showLabel && (
            <p style={{ color: 'black', alignSelf: 'center' }}>{email}</p>
          )}
        </a>
      )}
      {phone && (
        <a
          href={`tel:${phone}`}
          className='flex-x-start'
          style={{ gap: 8 }}
          onClick={(e) => e.stopPropagation()}
        >
          <IconButton>
            <Phone />
          </IconButton>
          {showLabel && (
            <p style={{ color: 'black', alignSelf: 'center' }}>{phone}</p>
          )}
        </a>
      )}
    </div>
  )
}

export default Connect
