import { makeStyles } from '@mui/styles'
import { colors } from '../../../assets/themes/colors/colors'

export const styles = {
  flagImage: {
    height: 28,
    width: 28,
  },

  line: {
    height: 28,
    width: 3,
    background: colors.grey4,
  },

  dial_code: {
    alignSelf: 'center',
    width: '75px ',
    marginLeft: '5px',
  },

  listPadding: {
    marginTop: '15px',
    marginLeft: '12px',
    paddingHorizontal: 5,
    paddingVertical: 12,
    display: 'flex',
    justifyContent: 'center',
  },
}

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    // minWidth: 90,
  },
  selectEmpty: {
    marginTop: 0,
  },
  icon: {
    left: -1,
    top: 0,
  },
  iconOpen: {
    transform: 'none',
  },
}))
