import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import imagePlaceholder from '../../../assets/images/Square placeholder.svg'
import { colors } from '../../../assets/themes/colors/colors'
import AddCategory from '../../../pages/ServicePages/AddCategory/AddCategory'

const CategoryCard = ({ onClick = () => {}, ...categoryData }) => {
  const [showModal, setShowModal] = React.useState(false)
  const close = React.useCallback(() => setShowModal(false), [])
  return (
    <>
      <Card sx={{ maxWidth: 400 }}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            component='img'
            height='160'
            image={categoryData?.imageUrl || imagePlaceholder}
            alt={categoryData?.name}
          />
        </CardActionArea>

        <CardContent>
          <div className='flex-x-btw'>
            <div>
              <Typography
                gutterBottom
                variant='h6'
                sx={{ fontWeight: 'bold' }}
                component='div'
              >
                {categoryData?.name}
              </Typography>
              {categoryData?.serviceCount ? (
                <Typography variant='p' sx={{ color: colors.primary }}>
                  {`${categoryData?.serviceCount} services  `}&nbsp;{' '}
                  <b style={{ fontWeight: 'bold' }}>&#8594;</b>
                </Typography>
              ) : null}
            </div>

            <IconButton onClick={() => setShowModal(true)}>
              <Edit htmlColor={colors.primary} />
            </IconButton>
          </div>
        </CardContent>
      </Card>

      {showModal && (
        <AddCategory
          editMode
          showModal={showModal}
          close={close}
          {...categoryData}
        />
      )}
    </>
  )
}
export default React.memo(CategoryCard)
