import React, { memo } from 'react'
import MuiModal from '../../../components/UI/Modal/MuiModal'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/UI/FormInput/FormInput'
import { useDispatch, useSelector } from 'react-redux'
import SelectInput from '../../../components/UI/SelectInput/SelectInput'
import DropZoneField from '../../../components/UI/DropZone/DropZoneField'
import {
  createOrUpdateService,
  removeServiceById,
} from '../../../data/reducers/services/admin/asyncAction'
import { useNavigate } from 'react-router-dom'

const AddService = ({
  editMode = false,
  showModal = false,
  close = () => {},
  ...serviceData
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categories, deleteBtnLoading, btnLoading } = useSelector(
    (state) => state.services_admin
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    const url = editMode
      ? `/Services/update/${serviceData?.id}`
      : '/Services/create'
    const payload = {
      url,
      method: editMode ? 'PUT' : 'POST',
      data: { ...serviceData, ...data, serviceImage: data.serviceImage?.[0] },
      closeDialog: () => {
        navigate(`/categories/${data?.serviceCategoryId}`)
        close()
      },
    }
    if (editMode && !data.serviceImage?.length)
      delete payload.data?.serviceImage
    dispatch(createOrUpdateService(payload))
  }

  const removeService = () =>
    dispatch(
      removeServiceById({ serviceId: serviceData?.id, closeDialog: close })
    )

  return (
    <>
      <MuiModal
        containerStyle={{ width: '620px' }}
        isOpen={showModal}
        close={close}
        title={editMode ? serviceData?.name : 'New Service'}
        showButtons
        rightLabel={editMode ? 'Update' : 'Add'}
        leftLabel={editMode ? 'Remove' : 'Cancel'}
        rightBtnLoading={btnLoading}
        leftBtnLoading={deleteBtnLoading}
        onLeftBtnClick={editMode ? removeService : close}
        onRightBtnClick={handleSubmit(onSubmit)}
      >
        <div
          className='margin-t-s'
          style={{ height: 450, overflowY: 'auto', paddingRight: 5 }}
        >
          <FormInput
            readOnly={editMode}
            control={control}
            name='name'
            label='Service Name *'
            placeholder='Service Name'
            error={errors}
            defaultValue={serviceData?.name}
            labelStyle={{ fontWeight: 'bold' }}
            rules={{
              required: { value: true, message: 'Please enter a service name' },
            }}
          />
          <SelectInput
            muiLabelStyle={{ fontWeight: 'bolder' }}
            label='Category *'
            name='serviceCategoryId'
            control={control}
            defaultValue={serviceData?.serviceCategoryId}
            setValue={setValue}
            data={categories}
            disabled={editMode}
            textFieldProps={{
              placeholder: 'Select a category',
            }}
          />
          <div className='margin-t-s'>
            <FormInput
              control={control}
              name='featuredDescription'
              label='Description'
              defaultValue={serviceData?.featuredDescription}
              labelStyle={{ fontWeight: 'bold' }}
              Component='textarea'
              row={20}
              column={25}
            />
          </div>

          <div>
            <DropZoneField
              label='Image *'
              control={control}
              name='serviceImage'
              errors={errors}
              defaultValue={serviceData?.featuredImageUrl}
              labelStyle={{ fontWeight: 'bold' }}
              rules={{
                required: {
                  value: serviceData?.featuredImageUrl ? false : true,
                  message: 'Please upload the image',
                },
              }}
            />
          </div>
        </div>
      </MuiModal>
    </>
  )
}

export default memo(AddService)
