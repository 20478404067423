import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { onboard } from "../../services/onboarding/form.service";

export const submitData = createAsyncThunk(
  "onboard/form",
  async (payload, thunkAPI) => {
    const res = await onboard.submitForm(payload);
    console.log({ res });
  }
);

// const formSlice = createSlice({
//   initialState: {
//     formData: null,
//   },
//   reducers: {
//     updateData: (state, action) => {
//       state.formData = action.payload;
//     },
//   },
// });
