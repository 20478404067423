import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Avatar, IconButton } from '@mui/material'
import { DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { colors } from '../../../assets/themes/colors/colors'
import AddService from '../../../pages/ServicePages/AddService/AddService'
import MuiModal from '../../UI/Modal/MuiModal'
import { useDispatch, useSelector } from 'react-redux'
import { removeServiceById } from '../../../data/reducers/services/admin/asyncAction'

const ServiceAccordion = ({ ...serviceData }) => {
  const [showService, setShowService] = React.useState(false)
  const [deleteModal, setShowDeleteModal] = React.useState(false)
  const [expanded, setExpanded] = React.useState(true)
  const closeServiceModal = React.useCallback(() => setShowService(false), [])
  const { deleteBtnLoading } = useSelector((state) => state.services_admin)
  const dispatch = useDispatch()

  const removeService = () =>
    dispatch(
      removeServiceById({
        serviceId: serviceData?.id,
        closeDialog: () => setShowDeleteModal(false),
      })
    )
  return (
    <div>
      <Accordion
        onClick={() => setExpanded((prev) => !prev)}
        expanded={expanded}
        sx={{
          border: `1.2px solid ${colors.grey5}`,
          boxShadow: 'none',
          borderRadius: 2,
        }}
      >
        <AccordionSummary
          sx={{ my: -0.8, py: 0 }}
          expandIcon={<ExpandMoreIcon htmlColor={colors.grey3} />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Avatar
            src={serviceData?.featuredImageUrl || ''}
            alt={serviceData?.name || 'Service name'}
          ></Avatar>
          <Typography sx={{ ml: 2, alignSelf: 'center', fontWeight: 'bold' }}>
            {serviceData?.name || 'Service Name'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{serviceData?.featuredDescription}</Typography>
          <div className='flex-row-rev' style={{ gap: 10 }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setShowDeleteModal(true)
              }}
            >
              <DeleteOutlined htmlColor={colors.primary} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setShowService(true)
              }}
            >
              <EditOutlined htmlColor={colors.primary} />
            </IconButton>
          </div>
        </AccordionDetails>
      </Accordion>
      {showService && (
        <AddService
          editMode
          showModal={showService}
          close={closeServiceModal}
          {...serviceData}
        />
      )}
      {deleteModal && (
        <MuiModal
          isOpen={deleteModal}
          close={() => setShowDeleteModal(false)}
          showButtons
          title={`Delete ${serviceData?.name} ?`}
          rightLabel='Delete'
          onLeftBtnClick={() => setShowDeleteModal(false)}
          onRightBtnClick={removeService}
          rightBtnLoading={deleteBtnLoading}
          message={
            <p>
              Are you sure you want to delete <b>{serviceData?.name}</b>{' '}
              service.
            </p>
          }
        />
      )}
    </div>
  )
}
export default React.memo(ServiceAccordion)
