import { createAsyncThunk } from '@reduxjs/toolkit'
import { request } from '../../../services/services.common'
import { showAlert } from '../../common/alert.reducer'

/*----------------Get Partner Amenities--------------*/

export const getPartnerAmenities = createAsyncThunk(
  'partner_amenities/getPartnerAmenities',

  async (payload, { rejectWithValue, dispatch }) => {
    const { partnerId } = payload
    const url = `/UserDetails/Partner/GetAmenities?partnerId=${partnerId}`
    const { response, status } = await request(url, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      const partnerAmenities = response.data?.data
      return { partnerAmenities }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*----------------Add Amenities in User's Account--------------*/

export const addPartnerAmenities = createAsyncThunk(
  'partner_amenities/addPartnerAmenities',

  async (payload, { rejectWithValue, dispatch }) => {
    const { data, partnerId, closeDialog } = payload
    const url = `/UserDetails/Partner/UpdateAmenities?partnerId=${partnerId}`
    const getUrl = `/UserDetails/Partner/GetAmenities?partnerId=${partnerId}`

    await request(url, 'POST', data)
    const { response, status } = await request(getUrl, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      const partnerAmenities = response.data?.data

      typeof closeDialog === 'function' && closeDialog()

      dispatch(
        showAlert({
          type: 'success',
          message: 'Successfully updated the amenities',
        })
      )

      return { partnerAmenities }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      dispatch(
        showAlert({
          type: 'error',
          message: "Couldn't update the amenities. Please try again.",
        })
      )
      return rejectWithValue(response)
    }
  }
)

/*----------------Add Amenities in User's Account--------------*/

export const removePartnerAmenities = createAsyncThunk(
  'partner_amenities/removePartnerAmenities',
  async (payload) => {
    const { data, partnerId } = payload
    const url = `/UserDetails/Partner/RemoveAmenities?partnerId=${partnerId}`
    await request(url, 'DELETE', data)
  }
)
