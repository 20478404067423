import React, { memo } from 'react'
import { useController } from 'react-hook-form'
import { colors } from '../../../assets/themes/colors/colors'
import './FormInput.css'

const FormInput = ({
  control,
  rules = {},
  inputRef,
  error,
  defaultValue = '',
  containerStyle,
  inputStyle = {},
  labelStyle = {},
  icon,
  iconStyle = {},
  customChange,
  customValue = '',
  iconPosition = '',
  Component = 'input',
  ...props
}) => {
  const { field } = useController({
    name: props?.name,
    control,
    rules,
    defaultValue,
  })

  const _onChange = (event) => {
    if (typeof customChange === 'function') customChange(event)
    return field.onChange(event)
  }
  const _value = () => {
    if (typeof customChange === 'function') return customValue
    return field.value
  }

  return (
    <section className={`form-control-custom`} style={containerStyle}>
      <div className='form-input' style={{ position: 'relative' }}>
        {props.label ? (
          <label
            className={`label margin-b-xs`}
            style={labelStyle}
            htmlFor={props.name}
          >
            {props.label}
          </label>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: iconPosition
              ? !(iconPosition === 'left')
                ? 'row-reverse'
                : 'row'
              : 'inherit',
          }}
        >
          {icon && (
            <div
              style={{
                position: 'absolute',
                top: '30px',
                zIndex: 100,
                ...iconStyle,
              }}
            >
              {icon}
            </div>
          )}
          <Component
            value={_value()}
            onChange={_onChange}
            id={props.name}
            placeholder={props.placeholder || props.label}
            ref={inputRef}
            maxlength={props.maxLength}
            style={{
              border: error
                ? error[props.name] && '1px solid red'
                : `1px solid ${colors.grey5}`,
              ...inputStyle,
            }}
            {...props}
          />
        </div>
      </div>
      {error
        ? error[props.name] && (
          <p className='formError'>{error[props.name].message}</p>
        )
        : null}
    </section>
  )
}
export default memo(FormInput)
