import axios from 'axios'
import { keys } from '../../constants/keys'
import { getDataFromLocalStorage } from '../helpers/localStorage'

const URL = 'https://makeoverapi-dev.azurewebsites.net/api/'
let headers = {}
const axiosInstance = axios.create({
  baseURL: URL,
  headers,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getDataFromLocalStorage(keys.KEY_AUTH_TOKEN)
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

export default axiosInstance
