import Connect from '../../components/UI/Connect/Connect'
import { styled } from '@mui/system'
import { Switch } from '@mui/material'
import { colors } from '../../assets/themes/colors/colors'
import React, { useState } from 'react'

const MyCustomSwitch = ({ data, verifyToggle }) => {
  const [checked, setChecked] = useState(data.row.isVerified ? true : false)
  return (
    <Switch
      checked={checked}
      size='small'
      color='primary'
      onClick={(e) => e.stopPropagation()}
      onChange={(e, value) => {
        setChecked(value)
        verifyToggle({ id: data?.row.id, value })
      }}
    />
  )
}

export const columns = ({ width, verifyToggle = () => { } }) => [
  {
    field: 'salonName',
    headerName: 'Salon name',
    width: (width - 40) / 5.1,
  },
  {
    field: 'city',
    headerName: 'City',
    width: (width - 40) / 6.1,
  },
  {
    field: 'servicesCount',
    headerName: 'Services',
    width: (width - 40) / 6.1,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: (width - 40) / 6.1,
  },
  {
    field: 'email',
    headerName: 'Email',
    disableColumnFilter: true,
    disableColumnMenu: true,
    sortable: false,
    width: (width - 40) / 8.5,
    renderCell: (data) =>
      data?.row?.email ? (
        <Connect email={data?.row?.email} showLabel={false} />
      ) : (
        <p>No Email</p>
      ),
  },
  {
    field: 'isVerified',
    headerName: 'Verified',
    disableColumnFilter: true,
    disableColumnMenu: true,
    sortable: false,
    width: (width - 40) / 8.5,
    renderCell: (data) => <MyCustomSwitch data={data} verifyToggle={verifyToggle} />,
    // renderCell: (data) => (
    //   <div>
    //     {data?.row?.isVerified ?
    //       <span style={{ paddingLeft: 10 }}>Verified</span> :
    //       <button
    //       className='col-verified'
    //         onClick={e => {
    //           e.stopPropagation()
    //           verifyToggle({ id: data?.row.id })
    //         }}
    //       >Verify</button>
    //     }
    //   </div>
    // ),
  },
]

export const rows = (data = []) => {
  const salons = data
    .map((list) => ({
      ...list.partnerDetails,
      servicesCount: list.servicesCount,
    }))
    // ?.filter((list) => list.name && list.phone)
    ?.map((list) => ({
      salonName: list.name?.toLowerCase(),
      id: list.id,
      city: list.city?.toLowerCase(),
      phone: list.phone,
      email: list.email,
      servicesCount: list.servicesCount,
      isVerified: list.isVerified,
    }))
  return salons ? salons : []
}

const CustomSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.purpleTint1,
  },
}))