import React from 'react'
import { useSelector } from 'react-redux'
import BgContainer from '../../../../../components/UI/BgContainer/BgContainer'
import Connect from '../../../../../components/UI/Connect/Connect'

const Gallery = () => {
  const { singlePartner, loading } = useSelector((state) => state.partners_info)

  return (
    <BgContainer
      title={singlePartner?.profileDetails?.name || '  '}
      rightComponent={
        <Connect
          email={singlePartner?.profileDetails?.email}
          phone={singlePartner?.profileDetails?.phone}
        />
      }
    ></BgContainer>
  )
}

export default Gallery
