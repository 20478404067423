import { useCallback, useState } from 'react'
import { keys } from '../constants/keys'
// import { getDataFromLocalStorage } from '../utils/helpers/localStorage'

export const useNavLocation = () => {
  const [foundLocation, setFoundLocation] = useState({})
  // getDataFromLocalStorage(keys.KEY_USER_LOCATION)

  const onSuccess = useCallback((data) => {
    if (data?.coords.latitude && data?.coords.longitude) {
      const location = {
        lat: data.coords.latitude,
        lon: data.coords.longitude,
      }
      setFoundLocation(location)
      // localStorage.setItem(keys.KEY_USER_LOCATION, JSON.stringify(location))
    }
  }, [])

  const getLocation = useCallback(() => {
    if (window.navigator?.geolocation) {
      window.navigator.geolocation.getCurrentPosition(onSuccess, () =>
        alert('Please Turn on your location')
      )
    } else {
      setFoundLocation({})
      alert(`your browser does not support geolocation`)
    }
  }, [onSuccess])

  const resetLocation = useCallback(() => {
    localStorage.removeItem(keys.KEY_USER_LOCATION)
    setFoundLocation({})
  }, [])

  return { foundLocation, getLocation, resetLocation }
}
