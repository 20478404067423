import { DeleteOutline, Edit } from '@mui/icons-material'
import { IconButton, Switch } from '@mui/material'
import { styled } from '@mui/system'
import { colors } from '../../../../assets/themes/colors/colors'

export const columns = ({
  width,
  removeRow = () => {},
  editRow = () => {},
  editListing = () => {},
}) => [
  {
    field: 'serviceName',
    headerName: 'Service name',
    width: (width - 40) / 4.5,
  },
  {
    field: 'tierLength',
    headerName: 'Tiers',
    width: (width - 40) / 4.5,
  },
  {
    field: 'pricingRange',
    headerName: 'Pricing range',
    width: (width - 40) / 4.5,
  },
  {
    field: 'listing',
    headerName: 'Listing',
    sortable: false,
    disableColumnFilter: true,
    disableColumnMenu: true,
    renderCell: (data) => (
      <CustomSwitch
        defaultChecked={data.row?.isFeatured}
        size='small'
        color='primary'
        onClick={(e) => e.stopPropagation()}
        onChange={(e, value) => editListing({ id: data?.row.id, value })}
      />
    ),
  },
  {
    field: 'edit',
    headerName: 'Edit',
    sortable: false,
    disableColumnFilter: true,
    disableColumnMenu: true,
    renderCell: (data) => (
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          editRow(data.row)
        }}
      >
        <Edit />
      </IconButton>
    ),
  },
  {
    field: 'remove',
    headerName: 'Remove',
    sortable: false,
    disableColumnFilter: true,
    disableColumnMenu: true,
    renderCell: (data) => (
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          removeRow({ id: data.row?.id, serviceName: data.row?.serviceName })
        }}
      >
        <DeleteOutline />
      </IconButton>
    ),
  },
]

export const rows = (data = []) => {
  const services = data?.map((service) => {
    const prices = service.listPricingTiers?.map((data) => data.price)
    const max = prices?.length > 0 ? parseInt(Math.max(...prices)) : 0
    const min = prices?.length > 0 ? parseInt(Math.min(...prices)) : 0

    return {
      serviceName: service?.serviceName,
      id: service?.id,
      tierLength: service?.listPricingTiers?.length,
      isFeatured: service?.isFeatured,
      pricingRange: max && min ? `\u20B9 ${min} - \u20B9 ${max}` : 'No Prices',
    }
  })

  return services ? services : []
}

const CustomSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.purpleTint1,
  },
}))
