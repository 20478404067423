import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { colors } from '../../../assets/themes/colors/colors'
import Button from '../../UI/Button/Button'
import Tiers from './Tiers'

const AddPricingTiers = ({ name = 'tiers' }) => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({ control, name })

  const _tiers = fields?.map((field, index) => (
    <Tiers
      tierName={`${name}[${index}].name`}
      priceName={`${name}[${index}].price`}
      genderName={`${name}[${index}].genderId`}
      showLabel={index === 0 ? true : false}
      key={field.id}
      onRemove={() => remove(index)}
    />
  ))

  return (
    <div>
      {_tiers}
      <div className='margin-t-s'>
        <Button
          label='Add tier'
          sx={{
            ':hover': {
              bgcolor: 'secondary.main',
            },
            bgcolor: colors.pinkTint2,
            color: colors.primary,
          }}
          onClick={append}
        />
      </div>
    </div>
  )
}

export default AddPricingTiers
