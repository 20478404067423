import { DeleteTwoTone } from '@mui/icons-material'
import {
  Autocomplete,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
} from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const Tiers = ({
  tierName = 'tierName',
  priceName = 'price',
  genderName = 'gender',
  onRemove = () => { },
  showLabel = false,
}) => {
  const { control, setValue } = useFormContext()
  const { genderList } = useSelector((state) => state.common)

  return (
    <div className='margin-t-s'>
      <Controller
        control={control}
        name={tierName}
        rules={{ required: true }}
        render={({ field: { onChange, value = '' } }) => (
          <FormControl sx={{ minWidth: 350, mb: 2, mr: 5 }}>
            {/* {showLabel && ( */}
            <FormLabel sx={{ fontWeight: 'bold' }}> Tier name *</FormLabel>
            {/* )} */}
            <TextField
              variant='standard'
              placeholder='Tier Name'
              onChange={onChange}
              value={value}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name={genderName}
        rules={{ required: true }}
        render={({ field: { value = '' } }) => (
          <FormControl sx={{ minWidth: 350, mb: 2, mr: 5 }}>
            {/* {showLabel && ( */}
            <FormLabel sx={{ fontWeight: 'bold' }}>Gender *</FormLabel>
            {/* )} */}
            <Autocomplete
              onChange={(e, value) =>
                setValue(genderName, value !== undefined ? value?.id : {})
              }
              value={
                genderList?.find((gender) => gender.id === value)?.label || ''
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              disablePortal
              options={genderList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  placeholder='Select Gender'
                />
              )}
            />
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={priceName}
        rules={{ required: true }}
        render={({ field: { onChange, value = '' } }) => (
          <FormControl sx={{ minWidth: 350, mb: 2, mr: 5 }}>
            {/* {showLabel && ( */}
            <FormLabel sx={{ fontWeight: 'bold' }}>Pricing ({`\u20B9`}){' *'}</FormLabel>
            {/* )} */}
            <TextField
              variant='standard'
              placeholder='price'
              onChange={onChange}
              value={value}
            />
          </FormControl>
        )}
      />

      <FormControl>
        {showLabel && (
          <FormLabel>
            <div style={{ height: 25 }}></div>
          </FormLabel>
        )}
        <IconButton onClick={onRemove}>
          <DeleteTwoTone />
        </IconButton>
      </FormControl>
    </div>
  )
}

export default Tiers
