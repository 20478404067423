import { createAsyncThunk } from '@reduxjs/toolkit'
import { keys } from '../../../../constants/keys'
import { getDataFromLocalStorage } from '../../../../utils/helpers/localStorage'
import { request } from '../../../services/services.common'
import { showAlert } from '../../common/alert.reducer'

/*----------------Create Work Schedule For Partner --------------*/

export const createPartnerSchedule = createAsyncThunk(
  'partner_schedule/createPartnerSchedule',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/UserDetails/Partner/WorkSchedule/Create`
    const getUrl = `/UserDetails/Partner/WorkSchedule/get/${payload.data?.partnerId}`

    await request(url, 'POST', payload.data)
    const { response, status } = await request(getUrl, 'GET')

    if (status === 'success') {
      typeof payload.navigate === 'function' && payload.navigate()
      const partnerSchedule = response.data?.data
      dispatch(
        showAlert({
          type: 'success',
          message: 'Successfully created schedule for the partner',
        })
      )
      return {
        partnerSchedule,
        partnerScheduleByDays: partnerSchedule?.workScheduleByDays,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      dispatch(
        showAlert({
          type: 'error',
          message: `Sorry, Couldn't create schedule. Please try again.`,
        })
      )
      return rejectWithValue(response)
    }
  }
)

/*----------------Get Work Schedule For Partner --------------*/

export const getPartnerSchedule = createAsyncThunk(
  'partner_schedule/getPartnerSchedule',
  async (partnerId, { rejectWithValue }) => {
    const url = `/UserDetails/Partner/WorkSchedule/get/${partnerId}`
    const { response, status } = await request(url, 'GET')

    if (status === 'success') {
      const partnerSchedule = response.data?.data
      return {
        partnerSchedule,
        partnerScheduleByDays: partnerSchedule?.workScheduleByDays,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*----------------Update Work Schedule For Partner --------------*/

export const updatePartnerSchedule = createAsyncThunk(
  'partner_schedule/updatePartnerSchedule',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/UserDetails/Partner/WorkSchedule/Update`
    const getUrl = `/UserDetails/Partner/WorkSchedule/get/${payload.data?.partnerId}`

    await request(url, 'PUT', payload.data)
    const { response, status } = await request(getUrl, 'GET')

    if (status === 'success') {
      typeof payload.navigate === 'function' && payload.navigate()
      const partnerSchedule = response.data?.data
      const partnerScheduleByDays = partnerSchedule?.workScheduleByDays
      dispatch(
        showAlert({
          type: 'success',
          message: 'Successfully update schedule.',
        })
      )
      return {
        partnerSchedule,
        partnerScheduleByDays,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      dispatch(
        showAlert({
          type: 'error',
          message: `Sorry, Couldn't update schedule. Please try again.`,
        })
      )
      return rejectWithValue(response)
    }
  }
)

/*----------------Update Work Schedule By Day For Partner --------------*/

export const updatePartnerScheduleByDay = createAsyncThunk(
  'partner_schedule/updatePartnerScheduleByDay',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/UserDetails/Partner/WorkSchedule/UpdateByDay`
    const { response, status } = await request(url, 'POST', payload.data)

    if (status === 'success') {
      dispatch(getPartnerSchedule())

      typeof payload.goBack === 'function' && payload.goBack()
      const partnerSchedule = response.data?.data
      return {
        partnerSchedule,
        partnerScheduleByDays: partnerSchedule?.workScheduleByDays,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*----------------Reset Work Schedule By Days For Partner --------------*/

export const resetDaySchedule = createAsyncThunk(
  'partner_schedule/resetDaySchedule',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `UserDetails/Partner/WorkSchedule/ResetDaySchedule/${payload?.id}`
    const { response, status } = await request(url, 'DELETE')

    if (status === 'success') {
      dispatch(getPartnerSchedule())

      typeof payload.goBack === 'function' && payload.goBack()
      const partnerSchedule = response.data?.data
      return {
        partnerSchedule,
        partnerScheduleByDays: partnerSchedule?.workScheduleByDays,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*----------------Get Unavailability timeline of salon--------------*/

export const getUnavailableTimeline = createAsyncThunk(
  'partner_schedule/getUnavailableTimeline',
  async (_, { rejectWithValue }) => {
    const SALON_ID = getDataFromLocalStorage(keys.KEY_PARTNER_ID)

    const url = `/UserDetails/Partner/UnavailibilityDates/Get/${SALON_ID}`
    const { response, status } = await request(url, 'GET')

    if (status === 'success') {
      const unavailable = response.data?.data
      return {
        unavailable,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*----------------Create Unavailability timeline of salon--------------*/

export const createUnavailableTimeline = createAsyncThunk(
  'partner_schedule/createUnavailableTimeline',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/UserDetails/Partner/UnavailibilityDates/create/`
    const { response, status } = await request(url, 'POST', payload.data)

    if (status === 'success') {
      const unavailable = response.data?.data

      return {
        unavailable,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*----------------Update Unavailability timeline of salon--------------*/

export const updateUnavailableTimeline = createAsyncThunk(
  'partner_schedule/updateUnavailableTimeline',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/UserDetails/Partner/UnavailibilityDates/update/`
    const { response, status } = await request(url, 'PUT', payload.data)

    if (status === 'success') {
      const unavailable = response.data?.data
      return {
        unavailable,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)
