import { Search } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import FormInput from '../FormInput/FormInput'

const LocalSearch = ({
  control,
  searchTerm = '',
  data = [],
  inputRef,
  reset = () => {},
  getSearchResults = () => {},
  filterTerm = 'name',
}) => {
  const [searched, setSearched] = useState([])
  const initialMount = useRef(false)

  const searchCustomers = useCallback(() => {
    const filter = data?.filter((data) =>
      data[filterTerm]?.toLowerCase()?.startsWith(searchTerm)
    )
    filter?.length > 0 ? setSearched(filter) : setSearched([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    if (searchTerm?.length === 0) {
      setSearched(data)
      return
    }
    if (searchTerm?.length > 0) {
      let clear = searched?.length
        ? searchCustomers()
        : setTimeout(searchCustomers, 800)
      return () => clearTimeout(clear)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCustomers])

  useEffect(() => {
    initialMount.current === true && getSearchResults(searched)
    return () => (initialMount.current = true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched])

  // const _reset = () => {
  //   typeof inputRef?.setNativeProps === 'function' &&
  //     inputRef?.setNativeProps({ text: '' })
  //   setSearched(data)
  //   reset()
  // }

  return (
    <div style={{ width: '40%' }}>
      <FormInput
        inputRef={(i) => (inputRef = i)}
        placeholder='Search services'
        inputStyle={{ paddingLeft: '35px' }}
        name='search'
        control={control}
        iconPosition='left'
        iconStyle={{ top: 0 }}
        icon={
          <IconButton sx={{ zIndex: 0 }}>
            <Search />
          </IconButton>
        }
      />
    </div>
  )
}

export default LocalSearch
