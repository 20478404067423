import React, { useState } from 'react'
import { Button, Typography as Text } from '@mui/material'
import { FileUpload, Delete } from '@mui/icons-material'
import { resetOtpStates } from '../../../data/reducers/auth/otp.reducer'
import { useDispatch } from 'react-redux'

function Onboarding2({ setCurrentScreen, setData, data }) {
  const [uploadedFiles, setUploadedFiles] = useState({
    'Documents[0].DocumentType': 1, //PAN of business
    'Documents[0].File': !!data['Documents[0].File']
      ? data['Documents[0].File']
      : null,
    'Documents[1].DocumentType': 2, //Proof of address
    'Documents[1].File': !!data['Documents[1].File']
      ? data['Documents[1].File']
      : null,
    'Documents[2].DocumentType': 3, //Store License
    'Documents[2].File': !!data['Documents[2].File']
      ? data['Documents[2].File']
      : null,
    'Documents[3].DocumentType': 4, //GSTIN
    'Documents[3].File': !!data['Documents[3].File']
      ? data['Documents[3].File']
      : null,
  })

  const [allUploaded, setAllUploaded] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    setAllUploaded(false)
    for (let i = 0; i < 4; i++) {
      if (!uploadedFiles[`Documents[${i}].File`]) {
        setAllUploaded(true)
        return
      }
    }
    setData(uploadedFiles)
    setCurrentScreen(3)
  }

  return (
    <div className='basic'>
      <Text variant='h4'>Documents</Text>
      <form>
        <div className='basic_radio_section'>
          <div className='basic_files'>
            <div>
              <label>PAN of Business</label>
              <br />
              <Text variant='caption' sx={{ color: '#8e278f' }}>
                {uploadedFiles['Documents[0].File'] &&
                  uploadedFiles['Documents[0].File'].name}
              </Text>
            </div>
            {!uploadedFiles['Documents[0].File'] ? (
              <Button
                startIcon={<FileUpload />}
                variant='outlined'
                color='primary'
                component='label'
              >
                Upload File
                <input
                  type='file'
                  hidden
                  accept='application/pdf, image/jpeg, image/png'
                  onChange={(event) => {
                    const files = event.target.files[0]
                    setUploadedFiles({
                      ...uploadedFiles,
                      'Documents[0].File': files,
                    })
                  }}
                />
              </Button>
            ) : (
              <Button
                startIcon={<Delete />}
                variant='outlined'
                onClick={() =>
                  setUploadedFiles({
                    ...uploadedFiles,
                    ['Documents[0].File']: null,
                  })
                }
              >
                Remove file
              </Button>
            )}
          </div>
          <div className='basic_files'>
            <div>
              <label>GSTIN</label>
              <br />
              <Text variant='caption' sx={{ color: '#8e278f' }}>
                {uploadedFiles['Documents[3].File'] &&
                  uploadedFiles['Documents[3].File'].name}
              </Text>
            </div>

            {!uploadedFiles['Documents[3].File'] ? (
              <Button
                startIcon={<FileUpload />}
                variant='outlined'
                color='primary'
                component='label'
              >
                Upload File
                <input
                  type='file'
                  hidden
                  accept='application/pdf, image/jpeg, image/png'
                  onChange={(event) => {
                    const files = event.target.files[0]
                    setUploadedFiles({
                      ...uploadedFiles,
                      'Documents[3].File': files,
                    })
                  }}
                />
              </Button>
            ) : (
              <Button
                startIcon={<Delete />}
                variant='outlined'
                onClick={() =>
                  setUploadedFiles({
                    ...uploadedFiles,
                    ['Documents[3].File']: null,
                  })
                }
              >
                Remove file
              </Button>
            )}
          </div>
          <div className='basic_files'>
            <div>
              <label>Proof of Address</label>
              <br />
              <Text variant='caption' sx={{ color: '#8e278f' }}>
                {uploadedFiles['Documents[1].File'] &&
                  uploadedFiles['Documents[1].File'].name}
              </Text>
            </div>
            {!uploadedFiles['Documents[1].File'] ? (
              <Button
                startIcon={<FileUpload />}
                variant='outlined'
                color='primary'
                component='label'
              >
                Upload File
                <input
                  type='file'
                  accept='application/pdf, image/jpeg, image/png'
                  hidden
                  onChange={(event) => {
                    const files = event.target.files[0]
                    setUploadedFiles({
                      ...uploadedFiles,
                      'Documents[1].File': files,
                    })
                  }}
                />
              </Button>
            ) : (
              <Button
                startIcon={<Delete />}
                variant='outlined'
                onClick={() =>
                  setUploadedFiles({
                    ...uploadedFiles,
                    ['Documents[1].File']: null,
                  })
                }
              >
                Remove file
              </Button>
            )}
          </div>
          <div className='basic_files'>
            <div>
              <label>Store License</label>
              <br />
              <Text variant='caption' sx={{ color: '#8e278f' }}>
                {uploadedFiles['Documents[2].File'] &&
                  uploadedFiles['Documents[2].File'].name}
              </Text>
            </div>
            {!uploadedFiles['Documents[2].File'] ? (
              <Button
                startIcon={<FileUpload />}
                variant='outlined'
                color='primary'
                component='label'
              >
                Upload File
                <input
                  type='file'
                  accept='application/pdf, image/jpeg, image/png'
                  hidden
                  onChange={(event) => {
                    const files = event.target.files[0]
                    setUploadedFiles({
                      ...uploadedFiles,
                      'Documents[2].File': files,
                    })
                  }}
                />
              </Button>
            ) : (
              <Button
                startIcon={<Delete />}
                variant='outlined'
                onClick={() =>
                  setUploadedFiles({
                    ...uploadedFiles,
                    ['Documents[2].File']: null,
                  })
                }
              >
                Remove file
              </Button>
            )}
          </div>
        </div>
        {allUploaded && (
          <Text
            className='basic_files'
            sx={{ textAlign: 'right' }}
            gutterBottom
            color='error'
          >
            Please upload all files
          </Text>
        )}
        <div
          className='basic_files'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              dispatch(resetOtpStates())
              setCurrentScreen(1)
            }}
            type='secondary'
            sx={{ color: '#8e278f' }}
          >
            PREVIOUS
          </Button>
          <Button
            className='mx-3'
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            NEXT
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Onboarding2
