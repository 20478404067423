import { createAsyncThunk } from '@reduxjs/toolkit'
import { keys } from '../../../../constants/keys'
import { getDataFromLocalStorage } from '../../../../utils/helpers/localStorage'
import { request } from '../../../services/services.common'
import { showAlert } from '../../common/alert.reducer'

/*-----------------Verify newly created partner-------------------*/

export const verifyPartnerFromAdmin = createAsyncThunk(
  'partner_info/verifyPartnerFromAdmin',
  async (payload, { rejectWithValue, dispatch }) => {
    const { id, value } = payload
    const url = `/PartnerOnboarding/VerifyPartner/${id}?isVerified=${value}`
    const { response, status } = await request(url, 'PUT')
    if (status === 'success') {
      return dispatch(
        showAlert({
          message: `Partner ${value ? 'Verified' : 'unverify'} Successfully`,
          type: `success`,
        })
      )
    }
    if (status === 'failure') {
      dispatch(
        showAlert({
          message: `Oops.. something went wrong`,
          type: `warning`,
        })
      )
      return rejectWithValue(response)
    }
  }
)

/*-----------------Get All Partners List-------------------*/

export const getAllPartnersList = createAsyncThunk(
  'partner_info/getAllPartnersList',

  async (_, { rejectWithValue }) => {
    const url = '/PartnerOnboarding/GetallPartners'
    const { response, status } = await request(url, 'GET')
    /*----- Success Response----- */

    if (status === 'success') {
      const allPartners = response.data?.data
      return {
        allPartners,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*-----------------Get Single Partner Details-------------------*/

export const getSinglePartnerInfo = createAsyncThunk(
  'partner_info/getSinglePartnerInfo',

  async (partnerId, { rejectWithValue }) => {
    const url = `/Values/getpartnerdetails?id=${partnerId}`
    const { response, status } = await request(url, 'GET')
    /*----- Success Response----- */

    if (status === 'success') {
      const singlePartner = response.data?.data
      localStorage.setItem(
        keys.KEY_PARTNER_ID,
        JSON.stringify(singlePartner.profileDetails?.id)
      )
      localStorage.setItem(keys.KEY_PARTNER_DATA, JSON.stringify(singlePartner))
      return {
        singlePartner: getDataFromLocalStorage(keys.KEY_PARTNER_DATA),
        profileImageUrl: singlePartner?.profileImageUrl,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*------------------Get Partner Salon Images and CoverImage-------------------*/

export const getPartnerSalonImages = createAsyncThunk(
  'partner_info/getPartnerSalonImages',

  async (_, { rejectWithValue }) => {
    const url = `/UserDetails/UserImages/Get?partnerId=${_.partnerId}`
    const { response, status } = await request(url, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      const images = response.data?.data
      let salonImages = images?.filter(({ isCoverPic }) => isCoverPic === false)
      salonImages = salonImages?.sort((a, b) => a.id - b.id)
      const coverImage = images?.find(({ isCoverPic }) => isCoverPic === true)

      return {
        userImages: salonImages,
        coverImage,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*------------------Update Partner Listing-------------------*/

export const updatePartnerListing = createAsyncThunk(
  'partner_info/updatePartnerListing',

  async (payload = {}, { rejectWithValue }) => {
    const SALON_ID = getDataFromLocalStorage(keys.KEY_PARTNER_ID)
    const url = `/UserDetails/UpdatePartnerListing/${SALON_ID}?isFeatured=${payload?.isFeatured}`
    const { response, status } = await request(url, 'PUT')

    /*----- Success Response----- */

    if (status === 'success') {
      const singlePartner = response.data?.data
      localStorage.setItem(
        keys.KEY_PARTNER_LISTING,
        JSON.stringify(singlePartner?.isFeatured)
      )
      return {
        isFeatured: singlePartner?.isFeatured,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/*-------------------Post Cover Image-------------------*/

export const postCoverImage = createAsyncThunk(
  'partner_info/postCoverImage',

  async (payload, { rejectWithValue }) => {
    const url = `/UserDetails/UserImages/Upload?partnerId=${payload?.partnerId}`
    const imagesUrl = `/UserDetails/UserImages/Get?partnerId=${payload?.partnerId}`
    const data = new FormData()
    data.append('coverImage', payload.coverImage)

    const postResponse = await request(url, 'POST', data)
    const { response, status } = await request(imagesUrl, 'GET')

    /*----- Success Response----- */

    if (status === 'success' && postResponse.status === 'success') {
      const images = response.data?.data
      const coverImage = images?.find(({ isCoverPic }) => isCoverPic === true)
      return { coverImage }
    }
    /*----- Failure Response----- */

    if (postResponse.status === 'failure' || status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/*-------------------Update Cover Image-------------------*/

export const updateCoverImage = createAsyncThunk(
  'partner_info/updateCoverImage',

  async (payload, { rejectWithValue }) => {
    const url = '/UserDetails/UserImages/UpdateCoverImage'
    const imagesUrl = '/UserDetails/UserImages/Get'

    const updateResponse = await request(url, 'PUT', payload.data)
    const { response, status } = await request(imagesUrl, 'GET')

    /*----- Success Response----- */

    if (updateResponse.status === 'success' && status === 'success') {
      const images = response.data?.data
      const coverImage = images?.find(({ isCoverPic }) => isCoverPic === true)

      typeof payload.goBack === 'function' && payload.goBack()

      return { coverImage }
    }
    /*----- Failure Response----- */

    if (updateResponse.status === 'failure' || status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/*------------------Upload Salon Images-------------------*/

export const postImages = createAsyncThunk(
  'partner_info/postImages',

  async (payload, { rejectWithValue }) => {
    const url = '/UserDetails/UserImages/Upload'
    const imageUrl = '/UserDetails/UserImages/Get'
    const data = new FormData()
    data.append('formFiles', payload.image)

    const uploadResponse = await request(url, 'POST', data)
    const { response, status } = await request(imageUrl, 'GET')

    /*----- Success Response----- */

    if (uploadResponse.status === 'success' && status === 'success') {
      const images = response.data?.data
      const salonImages = images?.sort((a, b) => a.id - b.id)

      typeof payload.closeDialog === 'function' && payload.closeDialog()
      typeof payload.goBack === 'function' && payload.goBack()

      return { userImages: salonImages }
    }
    /*----- Failure Response----- */

    if (uploadResponse.status === 'failure' || status === 'failure') {
      typeof payload.closeDialog === 'function' && payload.closeDialog()
      rejectWithValue(response)
    }
  }
)

/*------------------Delete Salon Images-------------------*/

export const deleteImage = createAsyncThunk(
  'partner_info/deleteImage',

  async (payload, { rejectWithValue, dispatch }) => {
    const url = '/UserDetails/UserImages/Delete'
    const data = [payload.id]
    const deleteResponse = await request(url, 'DELETE', data)

    /*----- Success Response----- */

    if (deleteResponse.status === 'success') {
      typeof payload.closeDialog === 'function' && payload.closeDialog()
      typeof payload.goBack === 'function' && payload.goBack()
      return {
        id: payload.id,
      }
    }
    /*----- Failure Response----- */

    if (deleteResponse.status === 'failure') {
      typeof payload.closeDialog === 'function' && payload.closeDialog()
      rejectWithValue(deleteResponse.response)
    }
  }
)
