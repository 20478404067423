import { createSlice } from '@reduxjs/toolkit'
import { addPartnerAmenities, getPartnerAmenities } from './asyncAction'

const initialState = {
  partnerAmenities: [],
  amenitiesLoading: false,
  btnLoading: false,
}
export const amenitiesSlice = createSlice({
  name: 'partner_amenities',
  initialState,

  extraReducers: {
    /*----------------Get Partner Amenities--------------*/
    [getPartnerAmenities.pending]: (state) =>
      void (state.amenitiesLoading = true),
    [getPartnerAmenities.fulfilled]: (state, { payload }) => {
      state.amenitiesLoading = false
      if (payload) {
        state.partnerAmenities = payload.partnerAmenities
          ? payload.partnerAmenities
          : []
      }
    },
    [getPartnerAmenities.rejected]: (state) =>
      void (state.amenitiesLoading = false),

    /*----------------Add Amenities in Partner's Account--------------*/
    [addPartnerAmenities.pending]: (state) => void (state.btnLoading = true),
    [addPartnerAmenities.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.partnerAmenities = payload.partnerAmenities
          ? payload.partnerAmenities
          : []
      }
    },
    [addPartnerAmenities.rejected]: (state) => void (state.btnLoading = false),
  },
})
export default amenitiesSlice.reducer
