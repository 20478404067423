import { ChevronRightSharp } from '@mui/icons-material'
import { Box, ListItemButton } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'
const Accordion = ({
  children,
  title = '',
  page = '',
  contentContainerStyle = {},
  onClick,
  ...props
}) => {
  const navigate = useNavigate()
  const path = window.location.pathname
  return (
    <ListItemButton
      sx={{ p: 0 }}
      onClick={() =>
        typeof onClick === 'function'
          ? onClick()
          : page && navigate(`${path}/${page}`)
      }
      {...props}
    >
      <Box className='accordion-box flex-x-btw'>
        <div className='m-right-s flex-row'>
          <div className='flex-row'>
            <p style={{ fontWeight: 'bold', fontSize: 16.5 }}>{title}</p>
            <div className='line'></div>
          </div>
          <div
            className='flex-row remove-flex-900'
            style={contentContainerStyle}
          >
            {children}
          </div>
        </div>
        <ChevronRightSharp />
      </Box>
    </ListItemButton>
  )
}

export default Accordion
