import { request } from '../../services/services.common'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getDropDownValues = createAsyncThunk(
  'common/getDropDownValues',
  async (_, { rejectWithValue }) => {
    const url = `/Values/getdropdownvalues`
    const { response, status } = await request(url)

    if (status === 'success') {
      let daysRange = response.data?.data?.daysLookup
      let genderList = response.data?.data?.genderLookup
      let businessType = response.data?.data.partnerDocumentTypeLookup
      let amenitiesList = response?.data?.data?.partnerAmenityLookup

      return {
        daysRange,
        amenitiesList,
        businessType: businessType?.map((data) => ({
          id: data.id,
          label: data.type,
        })),
        genderList: genderList?.map((data) => ({
          id: data.id,
          label: data.name,
        })),
      }
    }
    /*----- Failure Response----- */

    if (status === 'error') {
      return rejectWithValue()
    }
  }
)
