import { api, success, error } from '../services.common'
import axios from 'axios'
import { decode } from 'jsonwebtoken'
import { keys } from '../../../constants/keys'
import { getDataFromLocalStorage } from '../../../utils/helpers/localStorage'

const loginAuthentication = async (username, password) => {
  try {
    const response = await axios.post(`${api}Authenticate/login`, {
      username,
      password,
    })
    if (response.status === 200) {
      const credentials = response.data
      localStorage.setItem(
        keys.KEY_AUTH_TOKEN,
        JSON.stringify(credentials.data.token)
      )
      localStorage.setItem(
        keys.KEY_USER_DATA,
        JSON.stringify(credentials?.data?.user)
      )
      localStorage.setItem(
        keys.KEY_USER_ROLE,
        JSON.stringify(credentials.data.role)
      )
      return success(credentials)
    }
  } catch (e) {
    return error(e?.response.data?.statusMessage)
  }
}

const checkLogin = () => {
  const token = getDataFromLocalStorage(keys.KEY_AUTH_TOKEN)
  const role = getDataFromLocalStorage(keys.KEY_USER_ROLE)
  const jsonToken = decode(token)
  if (!token)
    return {
      loggedIn: false,
    }
  if (jsonToken.exp < (new Date().getTime() + 1) / 1000) {
    return {
      loggedIn: false,
    }
  }
  return {
    loggedIn: true,
    role,
  }
}

const logoutUser = () => {
  localStorage.clear()
  sessionStorage.clear()
  checkLogin()
}

export const authServices = {
  loginAuthentication,
  checkLogin,
  logoutUser,
}
