import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { keys } from '../../../constants/keys'
import { getDataFromLocalStorage } from '../../../utils/helpers/localStorage'
import { authServices } from '../../services/auth/auth.service'
import { checkUsername, followUpdates } from './asyncAction'

export const loginUser = createAsyncThunk('auth/loginUser', async (payload) => {
  const response = await authServices.loginAuthentication(
    payload.name,
    payload.password
  )
  if (response.isSuccessful === true) {
    typeof payload?.navigate === 'function' && payload?.navigate()
    return {
      ...response.data.data,
      loggedIn: true,
    }
  }
  return {
    loggedIn: false,
    error: response.message || 'Something went wrong. please try again.',
  }
})

export const checkLogin = createAsyncThunk('auth/checkLogin', async () => {
  const loginInfo = authServices.checkLogin()
  console.log({ loginInfo })
  return loginInfo
})

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (payload) => {
    authServices.logoutUser()
    typeof payload.redirect === 'function' && payload.redirect()
    return { loggedIn: false }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    role: getDataFromLocalStorage(keys.KEY_USER_ROLE),
    user: {},
    loggedIn: getDataFromLocalStorage(keys.KEY_AUTH_TOKEN) ? true : false,
    appLoading: false,
    loading: false,
    btnLoading: false,
    error: '',
    usernameTaken: false,
  },
  extraReducers: {
    [followUpdates.pending]: (state) => void (state.btnLoading = true),
    [followUpdates.fulfilled]: (state) => void (state.btnLoading = false),
    [followUpdates.rejected]: (state) => void (state.btnLoading = false),

    [checkUsername.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.usernameTaken = payload.usernameTaken
      }
    },

    [checkUsername.rejected]: (state, { payload }) => {
      if (payload) {
        state.usernameTaken = false
      }
    },

    [loginUser.pending]: (state, action) => {
      state.loading = true
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.loggedIn = payload?.loggedIn
      state.user = payload?.user
      state.role = payload?.role
      state.error = payload?.error || ''
    },

    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.loggedIn = false
    },

    [checkLogin.pending]: (state, action) => {
      state.appLoading = true
    },
    [checkLogin.fulfilled]: (state, { payload }) => {
      state.appLoading = false
      state.loggedIn = payload?.loggedIn
      state.role = payload?.role
    },

    [checkLogin.rejected]: (state, { payload }) => {
      state.appLoading = false
      state.loggedIn = payload?.loggedIn
    },

    [logoutUser.fulfilled]: (state, { payload }) => {
      state.loggedIn = payload?.loggedIn
    },
  },
})

export default authSlice.reducer
