import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import CustomRoutes from '../Routes'
import Dashboard from '../../pages/Dashboard/Dashboard'
import Verification from '../../pages/VerificationPages/Verification'
import Customer from '../../pages/CustomerPages/Customer'
import Insights from '../../pages/InsightPages/Insights'
import Support from '../../pages/SupportPages/Support'
import PartnerPageRoutes from './PartnerPageRoutes/PartnerPageRoutes'
import ServicePageRoutes from './ServicePageRoutes/ServicePageRoutes'


const HomeRoutes = () => {
  return (
    <>
      <CustomRoutes>
        <Route path='/*' element={<Navigate to='/partner' />}></Route>
        <Route>{PartnerPageRoutes()}</Route>
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/verifications' element={<Verification />}></Route>
        <Route path='/customer' element={<Customer />}></Route>
        <Route path='/insights' element={<Insights />}></Route>
        <Route>{ServicePageRoutes()}</Route>
        <Route path='/support' element={<Support />}></Route>
      </CustomRoutes>
    </>
  )
}

export default HomeRoutes
