export const removeSeconds = (time = '') => {
  if (/((1[0-2]|0?[1-9]):([0-5][0-9]:([0-5][0-9])) ?([AaPp][Mm]))/.test(time)) {
    const [hh, mm, ss, amORpm] = time.split(/:| /)
    return `${hh}:${mm} ${amORpm}`
  }
  return time
}

export const convertFrom24To12Format = (time24) => {
  try {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1)
    const period = +sHours < 12 ? 'AM' : 'PM'
    const hours = +sHours % 12 || 12

    return `${hours}:${minutes} ${period}`
  } catch (error) {
    return ''
  }
}

export const convertFrom12to24 = (time12h) => {
  try {
    const [time, modifier] = time12h.split(' ')
    let [hours, minutes] = time.split(':')
    if (hours && minutes) {
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
      }
      return `${hours}:${minutes}`
    }
    return '00:00'
  } catch (error) {
    return '00:00'
  }
}

export const getDateObjectFromTime = (time) => {
  //time 12h Am, Pm format ex-:  06:00 AM
  if (typeof time !== 'string') return null
  const in24Format = convertFrom12to24(time)
  const [hh1, mm1] = in24Format ? in24Format.split(':') : []
  const today = new Date()
  const date = today.getDate()
  const month = today.getMonth()
  const year = today.getFullYear()

  return new Date(year, month, date, hh1, mm1, 0)
}
