import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { OTPService } from '../../services/auth/otp.service'
import { api } from '../../services/services.common'

export const sendOTP = createAsyncThunk(
  'OTP/sendOTP',
  async (payload, thunkAPI) => {
    const url = payload?.forPhone
      ? `${api}Authenticate/sendotp?number=${
          payload.phoneNumber
        }&checkAvail=${true}`
      : `${api}PartnerOnboarding/SendVerifyEmail?email=${payload.email}`
    const response = await OTPService.sendOTP(url)
    if (response.isSuccessful) {
      return true
    } else {
      return thunkAPI.rejectWithValue(response.message)
    }
  }
)

export const checkOTP = createAsyncThunk(
  'OTP/checkOTP',
  async (payload, thunkAPI) => {
    const url = payload?.forPhone
      ? `${api}PartnerOnboarding/VerifyPhoneOtp?number=${payload.phoneNumber}&otp=${payload.OTP}`
      : `${api}PartnerOnboarding/VerifyEmail?email=${payload.email}&otp=${payload.OTP}`
    const res = await OTPService.checkOTP(url)

    if (res.isSuccessful) {
      return true
    } else {
      return thunkAPI.rejectWithValue(res.message)
    }
  }
)

const initialState = {
  OTPSent: false,
  checkedOTP: false,
  loading: false,
  wrongOtp: false,
  error: '',
}

const OTPSlice = createSlice({
  name: 'OTP',
  initialState,

  reducers: {
    resetOtpStates: (state) => {
      state.OTPSent = false
      state.checkedOTP = false
      state.wrongOtp = false
      state.error = ''
    },
  },
  extraReducers: {
    [sendOTP.fulfilled]: (state, action) => {
      state.OTPSent = true
      state.loading = false
      state.error = ''
    },
    [sendOTP.pending]: (state, action) => {
      state.loading = true
    },
    [sendOTP.rejected]: (state, { payload }) => {
      state.checkedOTP = false
      state.loading = false
      state.error = payload
    },

    [checkOTP.fulfilled]: (state, action) => {
      state.checkedOTP = true
      state.OTPSent = false
      state.wrongOtp = false
    },
    [checkOTP.rejected]: (state, action) => {
      state.checkedOTP = false
      state.wrongOtp = true
      state.OTPSent = true
    },
  },
})
export const { resetOtpStates } = OTPSlice.actions
export default OTPSlice.reducer
