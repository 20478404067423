import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItemButton,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { icons } from '../../../assets/icons/icons'
import { colors } from '../../../assets/themes/colors/colors'
import { getDateObjectFromTime } from '../../../utils/helpers/time'
import SelectTime from './SelectTime'

const Schedule = ({
  getWorkSchedule = () => {},
  closeCheckbox = true,
  day,
  editMode = false,
  data = {},
  ...props
}) => {
  const { control, setValue } = useFormContext()
  const [work, setWork] = useState(null) // uncontrolled
  const [defaultFromTime, setDefaultFromTime] = useState(null)
  const [defaultToTime, setDefaultToTime] = useState(null)
  const [close, setClose] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const { partnerSchedule } = useSelector((state) => state.partners_schedule)
  const { fields, append, remove } = useFieldArray({
    name: day ? `${day}` : 'break',
    control,
  })

  useEffect(() => {
    getWorkSchedule({ work, isWorkDay: !close })
  }, [work, close]) // dont add getWorkSchedule as dependency, coz it will trigger infinite render cycle

  useEffect(() => {
    props?.holiday && setClose(props?.holiday)
  }, [props?.holiday])

  useEffect(() => {
    setDisabled(close)
  }, [close])

  useEffect(() => {
    if (editMode && !day) {
      const _breakTimings = partnerSchedule?.listBreakTimes?.map((data) => ({
        fromTime: getDateObjectFromTime(data.breakStartTime),
        toTime: getDateObjectFromTime(data.breakEndTime),
      }))
      setValue('break', _breakTimings)
      setDefaultToTime(getDateObjectFromTime(partnerSchedule?.workEndTime))
      setDefaultFromTime(getDateObjectFromTime(partnerSchedule?.workStartTime))
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    day,
    editMode,
    partnerSchedule?.listBreakTimes,
    partnerSchedule?.workEndTime,
    partnerSchedule?.workStartTime,
  ])

  //  Controlled SelectTime Component, the state managed by React hook form for break time
  const _renderBreaks = useMemo(
    () =>
      fields.map((data, index) => (
        <div key={data.id}>
          <SelectTime
            disabled={disabled}
            title={`Break ${index + 1}`}
            uncontrolled={false}
            from={
              day ? `${day}[${index}].fromTime` : `break[${index}].fromTime`
            }
            to={day ? `${day}[${index}].toTime` : `break[${index}].toTime`}
            onRemove={() => remove(index)}
            close={close}
            rules={{ required: true }}
          />
        </div>
      )),
    [fields, remove, close, day, disabled]
  )

  const toggleCheckbox = (event) => {
    const is24hrsActive = event?.target.checked
    setOpen(is24hrsActive)
    if (is24hrsActive) {
      const today = new Date()
      const date = today.getDate()
      const month = today.getMonth()
      const year = today.getFullYear()

      setDefaultFromTime(new Date(year, month, date, 0, 0, 0))
      setDefaultToTime(new Date(year, month, date, 23, 59, 59))
      setDisabled(true)
    } else if (editMode) {
      setDefaultFromTime(getDateObjectFromTime(partnerSchedule?.workStartTime))
      setDefaultToTime(getDateObjectFromTime(partnerSchedule?.workEndTime))
      setDisabled(false)
    } else {
      setDefaultToTime(null)
      setDefaultFromTime(null)
      setDisabled(false)
    }
  }

  return (
    <div>
      <FormGroup sx={{ mt: 2, width: '65%' }}>
        {!close && (
          <FormControlLabel
            control={<Checkbox />}
            label='Open 24 hours'
            onChange={toggleCheckbox}
          />
        )}
        {!open
          ? closeCheckbox && (
              <div className='margin-t-s'>
                <FormControlLabel
                  checked={close}
                  control={<Checkbox />}
                  label='Closed'
                  onChange={(e, checked) => {
                    setClose(checked)
                    getWorkSchedule({ ...work, isWorkDay: !checked })
                  }}
                />
              </div>
            )
          : null}
      </FormGroup>
      {/* 
Uncontrolled SelectTime Component, managing state manually for work time 
*/}
      <div className='margin-b-s'>
        <SelectTime
          title='Work'
          remove={false}
          defaultFromTime={defaultFromTime}
          defaultToTime={defaultToTime}
          getTimings={(timings) => setWork(timings)}
          disabled={disabled}
        />
      </div>

      {_renderBreaks && <div className='margin-b-s'>{_renderBreaks}</div>}
      <ListItemButton
        onClick={append}
        className='flex-row margin-t-s'
        style={{ width: 'max-content' }}
      >
        <img src={icons().add_icon} alt='add' />
        <p
          style={{
            textTransform: 'uppercase',
            color: colors.primary,
            fontSize: '16px',
          }}
        >
          Add Break
        </p>
      </ListItemButton>
    </div>
  )
}

export default Schedule
