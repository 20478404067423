import { createAsyncThunk } from '@reduxjs/toolkit'
import { request } from '../../services/services.common'
import { showAlert } from '../common/alert.reducer'

export const followUpdates = createAsyncThunk(
  'auth/followUpdates',
  async (payload, { dispatch }) => {
    const url = `/Dashboard/followupdates?email=${payload.email}`
    const { response, status } = await request(url, 'POST')
    if (status === 'success') return { followUpdates: true }
    if (status === 'failure') {
      dispatch(
        showAlert({
          type: 'error',
          message:
            response?.statusMessage ||
            `Couldn't submit your email. Please try again.`,
        })
      )
      return { followUpdates: false }
    }
  }
)

export const checkUsername = createAsyncThunk(
  'auth/checkUsername',
  async (payload) => {
    const url = `/PartnerOnboarding/checkusername?username=${payload.username}`
    const { status } = await request(url, 'POST')
    if (status === 'success') return { usernameTaken: false }
    if (status === 'failure') {
      return {
        usernameTaken: true,
      }
    }
  }
)
