import React, { memo } from 'react'
import './styles.css'

export const Loading = memo(({ size = 'loader-small', color = 'primary' }) => {
  return <div className={`loader ${size} ${color} `}></div>
})

export const PageLoading = memo(
  ({ size = 'loader-medium', color = 'primary' }) => {
    return (
      <div className='page-loading '>
        <div
          style={{ alignSelf: 'center' }}
          className={`loader ${size} ${color}`}
        ></div>
      </div>
    )
  }
)

export default Loading
