import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { colors } from '../../../../../assets/themes/colors/colors'
import AddPricingTiers from '../../../../../components/PartnerPageComponents/AddPricingTiers/AddPricingTiers'
import BgContainer from '../../../../../components/UI/BgContainer/BgContainer'
import Button from '../../../../../components/UI/Button/Button'
import { getDropDownValues } from '../../../../../data/reducers/common/asyncAction'
import {
  getServiceCategories,
  getServicesList,
} from '../../../../../data/reducers/services/admin/asyncAction'
import { updateService } from '../../../../../data/reducers/services/partner/asyncAction'

const AddService = () => {
  const methods = useForm()
  const params = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { categories, loading, servicesList, servicesLoading } = useSelector(
    (state) => state.services_admin
  )
  const { updateLoading } = useSelector((state) => state.services_partner)

  const [selectedCategory, selectedService] = methods.watch([
    'category',
    'service',
  ])
  useEffect(() => {
    if (!categories?.length) {
      dispatch(getServiceCategories())
      dispatch(getDropDownValues())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    selectedCategory?.id &&
      dispatch(getServicesList({ id: selectedCategory.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory?.id])

  const onSubmit = useCallback((data) => {
    const url = '/SalonServices/create'
    const listPricingTiers = data.tiers?.map((tier) => ({
      genderId: tier.genderId,
      name: tier.name,
      price: tier.price,
      isFeatured: true,
      serviceId: data?.service.id,
    }))
    const payload = {
      url,
      goBack: () => navigate(-1),
      data: {
        salonId: params?.partnerId,
        serviceId: data?.service.id,
        serviceName: data?.service.label,
        employeeIds: [],
        listPricingTiers,
      },
    }
    dispatch(updateService(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...methods}>
      <BgContainer title='Add Service'>
        <div>
          <p
            style={{
              fontSize: 24,
              color: colors.grey2,
              textTransform: 'capitalize',
            }}
          >
            {state?.partnerName}
          </p>
          <div className='margin-t-m margin-b-s'>
            <Controller
              control={methods.control}
              name={'category'}
              render={() => (
                <FormControl sx={{ minWidth: 350, mb: 2, mr: 5 }}>
                  <FormLabel id='category' sx={{ fontWeight: 'bold' }}>
                    Category *
                  </FormLabel>
                  <Autocomplete
                    loading={loading}
                    options={categories ? categories : []}
                    onChange={(e, value) =>
                      methods.setValue('category', value ? value : {})
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        placeholder='Select category'
                      />
                    )}
                  />
                </FormControl>
              )}
            />

            <Controller
              control={methods.control}
              name={'service'}
              render={() => (
                <FormControl sx={{ minWidth: 350, mb: 2 }}>
                  <FormLabel id='service' sx={{ fontWeight: 'bold' }}>
                    Service *
                  </FormLabel>
                  <Autocomplete
                    loading={servicesLoading}
                    disabled={!selectedCategory?.id}
                    options={servicesList ? servicesList : []}
                    onChange={(e, value) =>
                      methods.setValue('service', value ? value : {})
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='standard'
                        placeholder='Select service'
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className='margin-t-m'>
            {selectedCategory?.id && selectedService?.id && <AddPricingTiers />}
          </div>
        </div>
        <div style={{ width: '100%' }} className='flex-row-rev margin-t-s'>
          <div className='flex-row'>
            <Button
              label='Cancel'
              variant='outlined'
              onClick={() => navigate(-1)}
            />
            <Button
              btnLoading={updateLoading}
              type='submit'
              disabled={
                selectedCategory?.id && selectedService?.id ? false : true
              }
              label='Add Service'
              onClick={methods.handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </BgContainer>
    </FormProvider>
  )
}

export default AddService
