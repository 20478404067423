import { Box, Typography } from '@mui/material'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Button from '../../../../components/UI/Button/Button'
import { DataGrid } from '@mui/x-data-grid'
import useWindowDimensions from '../../../../hooks/useWindow'
import './styles.css'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { columns, rows } from './datagrid'
import LocalSearch from '../../../../components/UI/LocalSearch/LocalSearch'
import { updateServicesCount } from '../../../../data/reducers/partners/info/info.reducer'
import MuiModal from '../../../../components/UI/Modal/MuiModal'
import {
  removeSalonService,
  toggleListing,
} from '../../../../data/reducers/services/partner/asyncAction'

const PartnerServices = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const classes = useStyles()
  const [searchResults, setSearchResults] = useState([])
  const { control, watch } = useForm()
  const searchTerm = watch('search')?.toLowerCase()
  const inputRef = useRef()
  const [modal, setModal] = useState(false)
  const [serviceToRemove, setServiceToRemove] = useState({})
  const { salonServicesList, loading, btnLoading } = useSelector(
    (state) => state.services_partner
  )
  const { singlePartner } = useSelector((state) => state.partners_info)
  const {
    profileDetails: { id: partnerId, name: partnerName },
  } = singlePartner ? singlePartner : { profileDetails: {} }

  useEffect(() => setSearchResults(salonServicesList), [salonServicesList])
  useEffect(() => {
    dispatch(
      updateServicesCount({
        partnerId,
        servicesCount: salonServicesList?.length,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salonServicesList.length, partnerId])

  const removeRow = useCallback((service) => {
    setServiceToRemove(service)
    setModal(true)
  }, [])

  const editRow = useCallback(
    (singleService) => {
      navigate(`/partner/${partnerId}/services/${singleService?.id}`, {
        state: { singleService },
      })
    },
    [partnerId]
  )

  const editListing = useCallback(({ id, value }) => {
    const url = `/SalonServices/updateServiceListing?salonServiceId=${id}&isFeatured=${value}`
    dispatch(toggleListing({ url }))
  }, [])

  const _cols = useMemo(
    () =>
      columns({ width, removeRow, editRow, editListing })?.map((data) => ({
        ...data,
        cellClassName: 'super-app-theme--cell',
        renderHeader: (cellValues) => {
          return (
            <div className='col-title'>{cellValues?.colDef?.headerName}</div>
          )
        },
      })),
    [width, removeRow, editRow, editListing]
  )

  return (
    <>
      <div className='flex-x-btw'>
        <Box xs={10.4}>
          <Typography variant='h5'>Services</Typography>
        </Box>
        <LocalSearch
          inputRef={inputRef}
          control={control}
          data={salonServicesList}
          filterTerm='serviceName'
          searchTerm={searchTerm}
          getSearchResults={(result) => setSearchResults(result)}
        />
        <div>
          <Button
            label='Add Service'
            onClick={() =>
              navigate(`/partner/${partnerId}/add-service`, {
                state: { partnerName },
              })
            }
          />
        </div>
      </div>
      <div
        style={{ height: 560, width: '100%' }}
        className={`${classes.root} margin-t-s`}
      >
        <DataGrid
          loading={loading}
          rows={rows(searchResults)}
          columns={_cols}
          hideFooterSelectedRowCount
          onRowClick={() => {}}
        />
      </div>
      <MuiModal
        title='Delete'
        isOpen={modal}
        close={() => setModal(false)}
        showButtons={true}
        rightBtnLoading={btnLoading}
        rightLabel='Delete'
        onLeftBtnClick={() => setModal(false)}
        message={
          <p>
            Are you sure you want to delete{' '}
            <b>{serviceToRemove?.serviceName}</b> service.It will remove all the
            tiers as well.
          </p>
        }
        onRightBtnClick={() => {
          dispatch(
            removeSalonService({
              id: serviceToRemove?.id,
              closeDialog: () => setModal(false),
            })
          )
        }}
      />
    </>
  )
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--cell': {
      outline: 'none !important',
      textTransform: 'capitalize',
      cursor: 'pointer',
    },
  },
})

export default memo(PartnerServices)
