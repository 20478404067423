import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from '../../../assets/themes/colors/colors'
import Schedule from '../../../components/PartnerPageComponents/Schedule/Schedule'
import BgContainer from '../../../components/UI/BgContainer/BgContainer'
import Button from '../../../components/UI/Button/Button'
import { getDropDownValues } from '../../../data/reducers/common/asyncAction'
import { createPartnerSchedule } from '../../../data/reducers/partners/schedule/asyncAction'
import { removeSeconds } from '../../../utils/helpers/time'

const CreateSchedule = () => {
  const navigate = useNavigate()
  const methods = useForm()
  const { daysRange } = useSelector((state) => state.common)
  const { btnLoading } = useSelector((state) => state.partners_schedule)
  const dispatch = useDispatch()
  const params = useParams()
  const [selectedDays, setSelectedDays] = useState([])
  const [check, setCheck] = useState(false)
  const [workSchedule, setWorkSchedule] = useState({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => !daysRange?.length && dispatch(getDropDownValues()), [])
  useEffect(() => setSelectedDays(daysRange?.map((day) => day.id)), [daysRange])

  const selectDaysHandler = useCallback(
    (dayId) => {
      const isSelected = selectedDays?.includes(dayId)
      setSelectedDays([...selectedDays, dayId])
      if (isSelected)
        setSelectedDays((prev) => prev.filter((id) => +id !== +dayId))
      else setSelectedDays([...selectedDays, dayId])
    },
    [selectedDays]
  )

  const _renderDays = useMemo(
    () =>
      daysRange?.map((day, index) => (
        <IconButton key={index} onClick={() => selectDaysHandler(day.id)}>
          <Avatar
            style={avatarStyles(selectedDays?.includes(day.id) ? true : false)}
          >
            {day?.name[0]}
          </Avatar>
        </IconButton>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [daysRange, selectedDays]
  )

  const onSubmit = useCallback(
    (data) => {
      const workStartTime = removeSeconds(
        workSchedule?.work?.fromTime?.toLocaleTimeString() // Converting Date Obj => HH:MM (Am/Pm)
      )
      const workEndTime = removeSeconds(
        workSchedule?.work?.toTime?.toLocaleTimeString()
      )
      const listBreakTimes = data?.break?.map((list) => ({
        breakStartTime: removeSeconds(list.fromTime?.toLocaleTimeString()),
        breakEndTime: removeSeconds(list.toTime?.toLocaleTimeString()),
      }))

      const workScheduleByDays = selectedDays?.map((dayId) => ({
        partnerId: +params?.partnerId,
        dayId,
        isWorkDay: true,
        listBreakTimes: [],
        workEndTime,
        workStartTime,
      }))
      const payload = {
        data: {
          partnerId: +params?.partnerId,
          workStartTime,
          workEndTime,
          listBreakTimes,
          workScheduleByDays,
        },
        navigate: () =>
          navigate(check ? `days` : `/partner/${params?.partnerId}/services`),
      }
      dispatch(createPartnerSchedule(payload))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      workSchedule?.work?.fromTime,
      workSchedule?.work?.toTime,
      selectedDays,
      params?.partnerId,
      check,
    ]
  )

  return (
    <FormProvider {...methods}>
      <BgContainer>
        <div className='flex-column'>
          <Box sx={{ justifyContent: 'center', alignSelf: 'center' }}>
            <p style={{ fontSize: '30px', fontWeight: 100 }}>Business Hours</p>
            <Schedule
              getWorkSchedule={(data) => setWorkSchedule(data)}
              closeCheckbox={false}
            />

            <div className='margin-t-m'>
              <h6 style={{ fontWeight: 'bold' }}>{'Days of week'}</h6>
              <div className='flex-x-evenly margin-t-s'>{_renderDays}</div>
              <div className='margin-t-s'>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ fontSize: 15 }}
                  onChange={(e) => setCheck(e.target?.checked)}
                  label={
                    <p style={{ fontSize: '15px' }}>
                      Schedules differ based on the day of the week
                    </p>
                  }
                />
              </div>
            </div>

            <div style={{ width: '100%' }} className='flex-row-rev margin-t-m'>
              <div className='flex-row'>
                <Button
                  label='previous'
                  variant='outlined'
                  onClick={() => navigate(-1)}
                />
                <Button
                  type='submit'
                  label='next'
                  btnLoading={btnLoading}
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </Box>
        </div>
      </BgContainer>
    </FormProvider>
  )
}

const avatarStyles = (active) => ({
  backgroundColor: active ? colors.pinkTint2 : colors.grey5,
  color: 'black',
  fontSize: 16,
})

export default CreateSchedule
