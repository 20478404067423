import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../../components/UI/Button/Button'
import MuiModal from '../../../../../components/UI/Modal/MuiModal'
import { Checkbox, FormControlLabel } from '@mui/material'
import { getDropDownValues } from '../../../../../data/reducers/common/asyncAction'
import Loading from '../../../../../components/UI/Loading/Loading'
import {
  addPartnerAmenities,
  removePartnerAmenities,
} from '../../../../../data/reducers/partners/amenities/asyncAction'
import { useParams } from 'react-router-dom'

const Amenities = ({ isOpen = false, close = () => {} }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { amenitiesList, valuesLoading } = useSelector((state) => state.common)
  const [selectedAmenities, setSelectedAmenities] = useState([])
  const { partnerAmenities, btnLoading } = useSelector(
    (state) => state.partners_amenities
  )

  useEffect(() => {
    !amenitiesList?.length && dispatch(getDropDownValues())
  }, [])

  useEffect(() => {
    if (partnerAmenities?.length) {
      const selectedAmenities = []
      amenitiesList?.forEach((data) => {
        const found = partnerAmenities?.find(
          (amenity) => amenity.id === data.id
        )
        found && selectedAmenities.push(data.id)
      })
      setSelectedAmenities(selectedAmenities)
    }
  }, [partnerAmenities, amenitiesList])

  const toggleAmenities = useCallback(
    (checked, id) => {
      if (!checked) {
        setSelectedAmenities((prev) =>
          prev.filter((amenityId) => amenityId !== id)
        )
        //  Remove Amenities if they are not selected
        dispatch(
          removePartnerAmenities({ partnerId: params?.partnerId, data: [id] })
        )
      } else setSelectedAmenities((prev) => [...prev, id])
    },
    [params?.partnerId]
  )

  const _amenitiesList = useMemo(
    () =>
      amenitiesList?.map((data, index) => {
        return (
          <div key={index}>
            <FormControlLabel
              label={data.amenity}
              control={
                <Checkbox
                  onChange={(e, checked) => toggleAmenities(checked, data.id)}
                  checked={selectedAmenities.includes(data.id) ? true : false}
                />
              }
            />
          </div>
        )
      }),
    [amenitiesList, selectedAmenities, toggleAmenities]
  )

  const selectAllAmenities = (e, checked) => {
    checked
      ? setSelectedAmenities(amenitiesList?.map((data) => data.id))
      : setSelectedAmenities([])
  }

  const onSubmit = () => {
    dispatch(
      addPartnerAmenities({
        partnerId: params?.partnerId,
        data: selectedAmenities,
        closeDialog: close,
      })
    )
  }
  return (
    <MuiModal isOpen={isOpen} close={close} containerStyle={{ width: 700 }}>
      {valuesLoading ? (
        <div className='flex-x-center'>
          <Loading size='loader-medium' />
        </div>
      ) : (
        <>
          <div>
            <div className='flex-x-btw margin-b-s'>
              <h5 style={{ fontWeight: 'bold' }}>
                Amenities{' '}
                {selectedAmenities.length
                  ? `(${selectedAmenities.length})`
                  : ''}
              </h5>
              <Button
                label='Save Changes'
                btnLoading={btnLoading}
                onClick={onSubmit}
              />
            </div>

            <FormControlLabel
              label='Select All'
              control={
                <Checkbox
                  checked={selectedAmenities.length === amenitiesList?.length}
                  onChange={selectAllAmenities}
                />
              }
            />
          </div>
          <div style={styles.grid} className='margin-t-s'>
            {_amenitiesList}
          </div>
        </>
      )}
    </MuiModal>
  )
}
const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: 5,
    paddingLeft: 10,
    overflowY: 'auto',
    maxHeight: 400,
  },
}
export default Amenities
