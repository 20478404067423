import './styles.css'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, ListItemButton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CategoryCard from '../../components/ServicesPageComponents/CategoryCard/CategoryCard'
import Button from '../../components/UI/Button/Button'
import { PageLoading } from '../../components/UI/Loading/Loading'
import { getServiceCategories } from '../../data/reducers/services/admin/asyncAction'
import AddService from './AddService/AddService'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { colors } from '../../assets/themes/colors/colors'
import AddCategory from './AddCategory/AddCategory'
import { useNavigate } from 'react-router-dom'

const ServiceCategories = () => {
  const navigate = useNavigate()
  const [showService, setShowService] = useState(false)
  const [showCategory, setShowCategory] = useState(false)
  const { categories, loading } = useSelector((state) => state.services_admin)
  const dispatch = useDispatch()

  useEffect(() => {
    !categories?.length && dispatch(getServiceCategories())
  }, [])

  const _renderCategories = useMemo(
    () =>
      categories?.map((category, index) => (
        <div key={index}>
          <CategoryCard
            {...category}
            onClick={() => navigate(`/categories/${category.id}`)}
          />
        </div>
      )),
    [categories]
  )

  const closeCategoryModal = useCallback(() => setShowCategory(false), [])
  const closeServiceModal = useCallback(() => setShowService(false), [])

  if (loading) return <PageLoading size='loader-medium' />

  return (
    <Box sx={{ pl: 10, pr: 5, my: 10 }}>
      <div className='flex-x-btw'>
        <h3 style={{ fontWeight: 'bold' }}>Categories</h3>
        <Button label='Add Service' onClick={() => setShowService(true)} />
      </div>
      <div className='margin-t-s categories-grid'>
        {_renderCategories}

        <ListItemButton
          onClick={() => setShowCategory(true)}
          disableRipple
          sx={{ justifyContent: 'center', alignSelf: 'center' }}
        >
          <AddCircleIcon htmlColor={colors.primary} sx={{ mr: 2 }} />
          <Typography variant='p'>Add category</Typography>
        </ListItemButton>
      </div>
      {showService && (
        <AddService showModal={showService} close={closeServiceModal} />
      )}
      {showCategory && (
        <AddCategory showModal={showCategory} close={closeCategoryModal} />
      )}
    </Box>
  )
}

export default ServiceCategories
