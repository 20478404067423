import { combineReducers } from 'redux'

// reducers
import auth_reducer from './auth/auth.reducer'
import otp from './auth/otp.reducer'
import common_reducer from './common/common.reducer'
import info_reducer from './partners/info/info.reducer'
import schedule_reducer from './partners/schedule/schedule.reducer'
import amenities_reducer from './partners/amenities/amenities.reducer'
import alert_reducer from './common/alert.reducer'
import partner_services_reducer from './services/partner/partner_services.reducer'
import admin_services_reducer from './services/admin/admin_services.reducer'

const rootReducer = combineReducers({
  auth: auth_reducer,
  otp,
  // General Reducer
  common: common_reducer,
  alert: alert_reducer,

  // Partner Reducers
  partners_info: info_reducer,
  partners_schedule: schedule_reducer,
  partners_amenities: amenities_reducer,

  // Services Reducers
  services_partner: partner_services_reducer,
  services_admin: admin_services_reducer,
})

export default rootReducer
