import { createAsyncThunk } from '@reduxjs/toolkit'
import { convertJsonToFormData } from '../../../../../utils/helpers/JsonToFormData'
import { request } from '../../../../services/services.common'
import { showAlert } from '../../../common/alert.reducer'
import { getAllPartnersList } from '../asyncAction'

/*------------------Create Partner-------------------*/

export const createPartner = createAsyncThunk(
  'partner_info/createPartner',
  async (payload, { rejectWithValue, dispatch }) => {
    const url = `/PartnerOnboarding/SubmitForm`
    const formData = convertJsonToFormData(payload?.data)
    const { response, status } = await request(url, 'POST', formData)

    /*----- Success Response----- */
    if (status === 'success') {
      const profileDetails = response.data?.data
      typeof payload.navigate === 'function' &&
        payload.navigate(
          `/partner/add-partner/${profileDetails?.id}/work-schedule`
        )
      dispatch(getAllPartnersList())
      dispatch(
        showAlert({
          message: 'Partner created successfully.',
          type: 'success',
        })
      )
      return {
        profileDetails,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      dispatch(
        showAlert({
          message:
            response?.message ||
            response?.title ||
            'Something went wrong. please try again',
          type: 'error',
        })
      )
      return rejectWithValue(response)
    }
  }
)
