import React, { useState } from 'react'
import Onboarding1 from './Onboarding1'
import Onboarding2 from './Onboarding2'
import Onboarding3 from './Onboarding3'
import Onboard from './Onboard'
import './styles.css'
import { Stepper, Step, StepLabel } from '@mui/material'
import Background from '../../../components/AuthCompoenets/Background/Background'

function Onboarding() {
  const [currentScreen, setCurrentScreen] = useState(0)
  const steps = ['Profile', 'Details', 'Documents', 'Agreement']

  const [data, setData] = useState({})

  const updateData = (newData) => {
    setData((oldData) => Object.assign(oldData, newData))
  }

  return (
    <Background title='Become Partner'>
      <div className='content '>
        <div className='none'>
          <Stepper
            style={{ backgroundColor: 'transparent' }}
            activeStep={currentScreen}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className='content-card'>
          <div id='onboarding'>
            <div className='onboarding_left'>
              <p
                className={`${
                  currentScreen === 0 && 'active'
                } d-flex align-items-center px-2 w-75`}
              >
                1. Profile
              </p>
              <p
                className={`${
                  currentScreen === 1 && 'active'
                } d-flex align-items-center px-2 w-75`}
              >
                2. Details
              </p>
              <p
                className={`${
                  currentScreen === 2 && 'active'
                } d-flex align-items-center px-2 w-75`}
              >
                3. Documents
              </p>
              <p
                className={`${
                  currentScreen === 3 && 'active'
                } d-flex align-items-center px-2 w-75`}
              >
                4. Agreement
              </p>
            </div>
            <div className='onboarding_right'>
              {currentScreen === 0 && (
                <Onboard
                  setCurrentScreen={setCurrentScreen}
                  data={data}
                  setData={updateData}
                />
              )}
              {currentScreen === 1 && (
                <Onboarding1
                  setCurrentScreen={setCurrentScreen}
                  data={data}
                  setData={updateData}
                />
              )}
              {currentScreen === 2 && (
                <Onboarding2
                  setData={updateData}
                  setCurrentScreen={setCurrentScreen}
                  data={data}
                />
              )}
              {currentScreen === 3 && (
                <Onboarding3 setCurrentScreen={setCurrentScreen} data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Background>
  )
}

export default Onboarding
