import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useMemo } from 'react'
import { colors } from '../../../assets/themes/colors/colors'
import { Link } from 'react-router-dom'
import { navLinks } from '../../../utils/navigation/navLinks'
import { Drawer, DrawerHeader, Styles, useStyles } from './styles'

export default function SideDrawer({
  drawer,
  selectedLink = {},
  setSelectedLink = () => {},
  setDrawer = () => {},
  shouldClose,
}) {
  const classes = useStyles()
  const _navList = useMemo(
    () =>
      navLinks().map((link) => {
        let active = false
        if (selectedLink?.id === link.id) active = true
        return (
          <div
            onMouseEnter={() => setDrawer(true)}
            onMouseLeave={shouldClose ? () => setDrawer(false) : () => {}}
            key={link.id}
            className={classes.link}
            role='presentation'
            onClick={() => {
              setSelectedLink(link)
              setDrawer(false)
            }}
          >
            <Link to={link.url}>
              <ListItem button sx={Styles.getSelectedLink(active)}>
                <ListItemIcon>
                  {link.icon(active ? colors.primary : colors.black)}
                </ListItemIcon>
                <ListItemText
                  primary={link.label}
                  sx={{ color: active ? colors.primary : 'black' }}
                />
              </ListItem>
            </Link>
          </div>
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.link, shouldClose, selectedLink]
  )

  return (
    <Drawer variant='permanent' open={drawer}>
      <DrawerHeader />
      <Divider />
      <List>{_navList}</List>
    </Drawer>
  )
}
