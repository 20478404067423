import { createSlice } from '@reduxjs/toolkit'
import { getDropDownValues } from './asyncAction'

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    selectedLink: { id: 0 },
    daysRange: [],
    genderList: [],
    businessType: [],
    amenitiesList: [],
    valuesLoading: false,
  },
  reducers: {
    setSelectedLink: (state, { payload }) => {
      state.selectedLink = payload
    },
  },

  extraReducers: {
    [getDropDownValues.pending]: (state) => void (state.valuesLoading = true),
    [getDropDownValues.fulfilled]: (state, { payload }) => {
      state.valuesLoading = false
      state.daysRange = payload?.daysRange ? payload.daysRange : []
      state.genderList = payload?.genderList ? payload.genderList : []
      state.businessType = payload?.businessType ? payload.businessType : []
      state.amenitiesList = payload?.amenitiesList ? payload.amenitiesList : []
    },
    [getDropDownValues.rejected]: (state) => void (state.valuesLoading = false),
  },
})

export const { setSelectedLink } = commonSlice.actions

export default commonSlice.reducer
