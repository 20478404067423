import React from 'react'
import InfoIcon from '@mui/icons-material/Info'

const ShowError = ({ message }) => (
  <div className='error flex-row' style={{ gap: 10 }}>
    <InfoIcon sx={{ alignSelf: 'center' }} />
    {message}
  </div>
)

export default ShowError
