import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography as Text,
  Checkbox,
  Box,
  FormHelperText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Verified, Search, MyLocation } from '@mui/icons-material'
import {
  checkOTP,
  resetOtpStates,
  sendOTP,
} from '../../../data/reducers/auth/otp.reducer'
import { useToggle } from '../../../hooks/useToggle'
import Button from '../../../components/UI/Button/Button'

const useStyles = makeStyles({
  input: {
    maxWidth: 412,
    width: '100%',
    marginBottom: '24px',
  },

  wrap: {
    maxWidth: 412,
    width: '100%',
    border: '1px solid black',
    display: 'flex',
    height: '48px',
    alignItems: 'center',
  },
})

function Onboarding1({ setCurrentScreen, data, setData }) {
  const dispatch = useDispatch()
  const {
    OTPSent,
    checkedOTP,
    wrongOtp,
    error: numberTaken,
    loading: OTPLoading,
  } = useSelector((state) => state.otp)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
  })
  const classes = useStyles()
  const [partnerType, setPartnerType] = useState(
    data.partnerType || 'establishment'
  )
  const [isSamePhoneNumber, toggle] = useToggle()
  const [OTP, setOTP] = useState('')

  const [location, setLocation] = useState()
  const [contactNumber] = useState('')

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }

  function showPosition(position) {
    setLocation({
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    })
  }

  useEffect(() => {
    OTP.length === 6 &&
      dispatch(
        checkOTP({ forPhone: true, phoneNumber: getValues('phone'), OTP })
      ) &&
      setOTP('')
  }, [OTP])

  useEffect(() => {
    !!data.nameOfOwner && setValue('nameOfOwner', data.nameOfOwner)
    !!data.phone && setValue('phone', data.phone)
    !!data.nameOfEstablishment &&
      setValue('nameOfEstablishment', data.nameOfEstablishment)
    !!data.postalAddress && setValue('postalAddress', data.postalAddress)
    !!data.state && setValue('state', data.state)
    !!data.city && setValue('city', data.city)
    !!data.pinCode && setValue('pinCode', data.pinCode)
  }, [])

  const onSubmit = (currentData) => {
    setData(
      Object.assign(currentData, {
        partnerType,
        lat: location?.lat,
        lon: location?.lon,
      })
    )
    setCurrentScreen(2)
  }

  return (
    <div className='basic'>
      <Text size='36' variant='h4'>
        Details
      </Text>
      <br />
      <Text variant='h4'>Profile</Text>
      <br />
      <form onSubmit={handleSubmit(onSubmit)} className='margin-t-s'>
        <Text>
          <b>Name of Owner</b>
        </Text>
        <TextField
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          error={errors.nameOfOwner ? true : false}
          helperText={errors.nameOfOwner?.message}
          variant='outlined'
          {...register('nameOfOwner', {
            required: {
              value: true,
              message: 'This field is required',
            },
          })}
          defaultValue={data.nameOfOwner && data.nameOfOwner}
          placeholder="Owner's Name"
        />
        <br />
        <Text>
          <b>Phone Number</b>
        </Text>
        <TextField
          {...register('phone', {
            required: {
              value: true,
              message: 'This field is required',
            },
            maxLength: {
              value: '10',
              message: '10-digit required',
            },
            pattern: { value: /^\d{10}$/g, message: '10-digit required' },
          })}
          sx={{
            mb: 2,
            mt: 1,
            pr: 0,
            '& .MuiInputBase-root': {
              pr: 0,
            },
          }}
          defaultValue={data.phone && data.phone}
          disabled={checkedOTP}
          helperText={
            errors.phone?.message
              ? errors.phone?.message
              : numberTaken
              ? numberTaken
              : null
          }
          error={errors.phone ? true : numberTaken ? true : false}
          className={classes.input}
          placeholder='1234567890'
          InputProps={{
            endAdornment: (
              <InputAdornment>
                {!checkedOTP ? (
                  <Button
                    label='Verify'
                    btnLoading={OTPLoading}
                    sx={{ height: 48 }}
                    disabled={
                      errors?.phone ? true : getValues('phone') ? false : true
                    }
                    onClick={() =>
                      dispatch(
                        sendOTP({
                          forPhone: true,
                          phoneNumber: getValues('phone'),
                        })
                      )
                    }
                  />
                ) : (
                  <Verified sx={{ mr: 1 }} color='success' />
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment>
                <Text sx={{ color: '#bdbdbd' }}>+91 | {` `}</Text>
              </InputAdornment>
            ),
          }}
        />
        {!errors?.phone && OTPSent && (
          <OtpInput
            value={OTP}
            onChange={(e) => setOTP(e)}
            numInputs={6}
            isInputNum={true}
            hasErrored={wrongOtp}
            shouldAutoFocus={true}
            containerStyle={{
              maxWidth: '412px',
              width: '100%',
              height: '48px',
              border: '1px solid #bdbdbd',
              justifyContent: 'center',
            }}
            inputStyle={{
              height: '80%',
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottom: '2px solid black !important',
              width: '48px',
              margin: '0 10px',
            }}
          />
        )}
        {wrongOtp && (
          <FormHelperText error={true} sx={{ mt: 1, fontSize: 14 }}>
            Invalid OTP
          </FormHelperText>
        )}
        <br />
        <Text>
          <b>Address</b>
        </Text>
        <Text variant='caption' gutterBottom>
          If you are operating from multiple locations, please enter the details
          of your main location.
        </Text>
        <br />
        <Text sx={{ mt: 1 }}>Postal Address</Text>
        <TextField
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          {...register('postalAddress', {
            required: {
              value: true,
              message: 'This field is required',
            },
          })}
          helperText={errors.postalAddress?.message}
          error={errors.postalAddress ? true : false}
          placeholder='Shop No., Block, Locality'
          defaultValue={data.postalAddress && data.postalAddress}
        />
        <br />
        <Text>State</Text>
        <TextField
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          {...register('state', {
            required: {
              value: true,
              message: 'This field is required',
            },
          })}
          helperText={errors.state?.message}
          error={errors.state ? true : false}
          defaultValue={data.state && data.state}
          placeholder='State'
        />
        <br />
        <Text>City</Text>
        <TextField
          sx={{ mb: 2, mt: 1 }}
          variant={'outlined'}
          className={classes.input}
          {...register('city', {
            required: {
              value: true,
              message: 'This field is required',
            },
          })}
          helperText={errors.city?.message}
          error={errors.city ? true : false}
          defaultValue={data.city && data.city}
          placeholder={'City'}
        />
        <br />
        <Text>PIN Code</Text>
        <TextField
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          {...register('pinCode', {
            required: {
              value: true,
              message: 'This field is required',
            },
          })}
          helperText={errors.pinCode?.message}
          error={errors.pinCode}
          defaultValue={data.pinCode && data.pinCode}
          placeholder='110023'
        />
        <br />
        <Text>Location</Text>
        <TextField
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          value={
            location?.lat ? `Lat: ${location?.lat} Lon: ${location?.lon}` : ''
          }
          onChange={(e) => setLocation(e.target.value)}
          helperText={errors.location?.message}
          error={errors.location ? true : false}
          defaultValue={data.location && data.location}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment>
                <MyLocation onClick={getLocation} />
              </InputAdornment>
            ),
          }}
        />
        <br />
        <RadioGroup
          defaultValue={data.partnerType || 'establishment'}
          name='radio-buttons-group'
          onChange={(e) => setPartnerType(e.target.value)}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value='establishment'
            {...register('partnerType')}
            control={<Radio />}
            label='Establishment'
          />
          <FormControlLabel
            value='individual'
            {...register('partnerType')}
            control={<Radio />}
            label='Individual'
          />
        </RadioGroup>{' '}
        <br />
        {partnerType === 'establishment' && (
          <>
            <Text>
              <b>Name of Establishment</b>
            </Text>
            <TextField
              sx={{ mb: 2, mt: 1 }}
              className={classes.input}
              variant='outlined'
              {...register('nameOfEstablishment', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
              })}
              defaultValue={
                data.nameOfEstablishment && data.nameOfEstablishment
              }
              helperText={errors.nameOfEstablishment?.message}
              placeholder='Establishment'
              error={errors.nameOfEstablishment ? true : false}
            />
            <br />

            {/* <Text>
              <b>Contact Number</b>
            </Text>
            <Text>
              <Checkbox value={isSamePhoneNumber} onChange={toggle} /> Same as
              Owner Phone Number
            </Text> */}
            {/* <TextField
              sx={{
                mb: 2,
                mt: 1,
                pr: 0,
                '& .MuiInputBase-root': {
                  pr: 0,
                },
              }}
              className={classes.input}
              variant='outlined'
              {...register('contactNumber')}
              helperText={errors.contactNumber?.message}
              error={errors.contactNumber ? true : false}
              value={isSamePhoneNumber ? getValues('phone') : contactNumber}
              placeholder={
                isSamePhoneNumber
                  ? getValues('phone')
                  : data.contactNumber
                  ? data.contactNumber
                  : ''
              }
              disabled={isSamePhoneNumber}
              InputProps={{
                endAdornment: !isSamePhoneNumber && (
                  <InputAdornment position='start'>
                    <Button
                      label='verify'
                      onClick={() =>
                        dispatch(sendOTP(getValues('contactNumber')))
                      }
                      sx={{
                        height: 48,
                      }}
                    />
                  </InputAdornment>
                ),

                startAdornment: (
                  <InputAdornment>
                    <Text style={{ color: '#bdbdbd' }}>+91 | </Text>
                  </InputAdornment>
                ),
              }}
            /> */}
          </>
        )}
        <br />
        <div style={{ textAlign: 'right' }}>
          <Box sx={{ justifyContent: 'flex-end' }} className='d-flex'>
            <Button
              label='previous'
              onClick={() => {
                dispatch(resetOtpStates())
                setCurrentScreen(0)
              }}
            />
            <Button
              label='next'
              type='submit'
              disabled={!checkedOTP}
              sx={{ ml: 2 }}
            />
          </Box>
        </div>
      </form>
    </div>
  )
}

export default Onboarding1
