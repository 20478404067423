import { colors } from '../../../../../assets/themes/colors/colors'

export const styles = {
  container: { width: '100%', gap: '50px' },
  labelStyle: {
    color: colors.primary,
    cursor: 'pointer',
    padding: 5,
    borderRadius: 2,
    backgroundColor: 'white',
  },
  containerStyle: {
    position: 'absolute',
    top: '75%',
    left: '70%',
  },

  img: {
    width: '350px',
    height: '250px',
    objectFit: 'cover',
  },
}
