import { api, post, update } from '../services.common'

const sendOTP = async (url) => {
  const sendOTPRes = await post(url)
  return sendOTPRes
}

const checkOTP = async (url) => {
  const checkOTPRes = await update(url)
  return checkOTPRes
}

const sendOtpOnEmail = async (email) => {
  const url = `${api}PartnerOnboarding/SendVerifyEmail?email=${email}`
  const resp = await post(url)
  return resp
}

const verifyOTPEmail = async (otp, email) => {
  const url = `${api}PartnerOnboarding/VerifyEmail?email=${email}&otp=${otp}`
}

export const OTPService = {
  sendOTP,
  checkOTP,
}
