import { createSlice } from '@reduxjs/toolkit'
import {
  createOrUpdateCategory,
  createNewService,
  getAllNewServices,
  getPriceTiersByCatId,
  getServiceCategories,
  getServicesList,
  removeCategoryById,
  createOrUpdateService,
  removeServiceById,
} from './asyncAction'

export const adminServicesSlice = createSlice({
  name: 'services_admin',
  initialState: {
    loading: false,
    servicesLoading: false,
    categories: [],
    allServices: [],
    servicesList: [],
    salonServices: [],
    assignedEmployees: [],
    newServicesRequest: [],
    btnLoading: false,
    deleteBtnLoading: false,
  },
  extraReducers: {
    /* ------------------Create or Update Service Categories----------------- */

    [createOrUpdateCategory.pending]: (state) => void (state.btnLoading = true),
    [createOrUpdateCategory.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) state.categories = payload.categories
    },
    [createOrUpdateCategory.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /* ------------------Get All Service Categories----------------- */

    [getServiceCategories.pending]: (state) => void (state.loading = true),

    [getServiceCategories.fulfilled]: (state, { payload }) => {
      state.loading = false
      if (payload) state.categories = payload.categories
    },
    [getServiceCategories.rejected]: (state, { payload }) => {
      state.loading = false
    },

    /* ------------------Remove Category by Id----------------- */

    [removeCategoryById.pending]: (state) =>
      void (state.deleteBtnLoading = true),
    [removeCategoryById.fulfilled]: (state, { payload }) => {
      state.deleteBtnLoading = false
      if (payload) {
        state.categories = state.categories?.filter(
          (data) => data.id !== payload.categoryId
        )
      }
    },
    [removeCategoryById.rejected]: (state, { payload }) => {
      state.deleteBtnLoading = false
    },

    /* ---------------------Create or Update Service Categories--------------------- */

    [createOrUpdateService.pending]: (state) => void (state.btnLoading = true),
    [createOrUpdateService.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) state.servicesList = payload.servicesList
    },
    [createOrUpdateService.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /* --------------Get Services List by Category id--------------- */

    [getServicesList.pending]: (state) => void (state.servicesLoading = true),
    [getServicesList.fulfilled]: (state, { payload }) => {
      state.servicesLoading = false
      if (payload) state.servicesList = payload.servicesList
    },
    [getServicesList.rejected]: (state, { payload }) => {
      state.servicesLoading = false
    },

    /* ------------------Remove Service by Id----------------- */

    [removeServiceById.pending]: (state) =>
      void (state.deleteBtnLoading = true),
    [removeServiceById.fulfilled]: (state, { payload }) => {
      state.deleteBtnLoading = false
      if (payload) {
        state.servicesList = state.servicesList?.filter(
          (data) => data.id !== payload.serviceId
        )
      }
    },
    [removeServiceById.rejected]: (state, { payload }) => {
      state.deleteBtnLoading = false
    },

    /* -------Get Salon Services(Pricing Tiers) List by Cat id------ */

    [getPriceTiersByCatId.pending]: (state) =>
      void (state.servicesLoading = true),
    [getPriceTiersByCatId.fulfilled]: (state, { payload }) => {
      state.servicesLoading = false
      if (payload) state.salonServices = payload.salonServices
    },
    [getPriceTiersByCatId.rejected]: (state, { payload }) => {
      state.servicesLoading = false
    },

    /* -------Request (Create) For New Service that is not yet available for Partner------- */

    [createNewService.pending]: (state) => void (state.btnLoading = true),
    [createNewService.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload)
        state.newServicesRequest = [
          payload.newService,
          ...state.newServicesRequest,
        ]
    },
    [createNewService.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /* -------Get All New Services that is not yet available for Partner------ */
    [getAllNewServices.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.newServicesRequest = payload.newServicesRequest
          ? payload.newServicesRequest
          : []
      }
    },
  },
})

export default adminServicesSlice.reducer
