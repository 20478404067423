import {
  Typography,
  TextField,
  InputAdornment,
  FormHelperText,
} from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkOTP,
  resetOtpStates,
  sendOTP,
} from '../../../data/reducers/auth/otp.reducer'
import { useEffect } from 'react'
import { checkUsername } from '../../../data/reducers/auth/asyncAction'
import OtpInput from 'react-otp-input'
import { Verified } from '@mui/icons-material'
import { EMAIL_PATTERN } from '../../../constants/pattern'
import Button from '../../../components/UI/Button/Button'

const useStyles = makeStyles({
  input: {
    maxWidth: 412,
    width: '100%',
    marginBottom: '24px',
  },
})

function Onboard({ setCurrentScreen, data, setData }) {
  const dispatch = useDispatch()
  const [OTP, setOTP] = useState('')
  const classes = useStyles()
  const { usernameTaken } = useSelector((state) => state.auth)
  const {
    OTPSent,
    checkedOTP,
    wrongOtp,
    error: emailTaken,
    loading: OTPLoading,
  } = useSelector((state) => state.otp)

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    shouldUnregister: false,
  })
  const username = watch('name')
  const email = watch('email')

  useEffect(() => {
    !!data.name && setValue('name', data.name)
    !!data.password && setValue('password', data.password)
    !!data.confirmPassword && setValue('confirmPassword', data.confirmPassword)
    !!data.email && setValue('email', data.email)
  }, [])

  useEffect(() => {
    OTP.length === 6 &&
      dispatch(checkOTP({ forPhone: false, email: getValues('email'), OTP })) &&
      setOTP('')
  }, [OTP])

  useEffect(() => {
    let timeout = null
    if (username?.length > 2) {
      timeout = setTimeout(() => {
        dispatch(checkUsername({ username }))
      }, 1000)
    }
    return () => clearTimeout(timeout)
  }, [username])

  useEffect(() => {
    if (email?.length === 0) {
      dispatch(resetOtpStates())
    }
  }, [email])

  const onSubmit = (currentData) => {
    setData(currentData)
    setCurrentScreen(1)
    dispatch(resetOtpStates())
  }

  return (
    <div className='basic'>
      <Typography variant='h4' sx={{ mb: 2 }}>
        Profile
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className='margin-t-s'>
        <Typography>Email ID</Typography>
        <TextField
          {...register('email', {
            required: {
              value: true,
              message: 'This field is required',
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: 'Not a valid email address',
            },
          })}
          type='email'
          sx={{
            mb: 2,
            mt: 1,
            pr: 0,
            '& .MuiInputBase-root': {
              pr: 0,
            },
          }}
          className={classes.input}
          placeholder='email@domain.com'
          helperText={
            errors.email?.message
              ? errors.email?.message
              : emailTaken
              ? emailTaken
              : null
          }
          error={errors.email ? true : emailTaken ? true : false}
          variant='outlined'
          defaultValue={data.email && data.email}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                {!checkedOTP ? (
                  <Button
                    sx={{ height: 48 }}
                    label='verify'
                    onClick={() =>
                      dispatch(
                        sendOTP({
                          forPhone: false,
                          email: getValues('email'),
                        })
                      )
                    }
                    disabled={
                      errors?.email ? true : getValues('email') ? false : true
                    }
                    btnLoading={OTPLoading}
                  />
                ) : (
                  <Verified sx={{ mr: 1 }} color='success' />
                )}
              </InputAdornment>
            ),
          }}
        />
        <br />

        {!errors.email && OTPSent && (
          <OtpInput
            value={OTP}
            onChange={(e) => setOTP(e)}
            numInputs={6}
            isInputNum={true}
            hasErrored={wrongOtp}
            shouldAutoFocus={true}
            containerStyle={{
              maxWidth: '412px',
              width: '100%',
              height: '48px',
              border: '1px solid #bdbdbd',
              justifyContent: 'center',
            }}
            inputStyle={{
              height: '80%',
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottom: '2px solid black !important',
              width: '48px',
              margin: '0 10px',
            }}
          />
        )}
        {wrongOtp && (
          <FormHelperText error={true} sx={{ mt: 1, fontSize: 14 }}>
            Invalid OTP
          </FormHelperText>
        )}
        <br />

        <Typography>Username</Typography>

        <TextField
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          error={errors.name ? true : usernameTaken ? true : false}
          helperText={
            errors.name?.message
              ? errors.name?.message
              : usernameTaken
              ? 'username already taken'
              : null
          }
          {...register('name', {
            required: {
              value: true,
              message: 'This field is required',
            },
          })}
          defaultValue={data.name && data.name}
          placeholder='Username'
        />

        <br />
        <Typography>Create Password</Typography>
        <TextField
          {...register('password', {
            required: {
              value: true,
              message: 'This field is required',
            },
            pattern: {
              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
              message:
                'Weak Password! At least one uppercase, one digit and one special character are required!',
            },
          })}
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          helperText={
            errors.password
              ? errors.password.message
              : 'Password must be at least 8 characters long. With at least 1 Capital letter, 1 special character and 1 digit.'
          }
          error={errors.password ? true : false}
          defaultValue={data.password && data.password}
          placeholder='Set a Password'
          type='password'
        />
        <br />
        <Typography>Confirm Password </Typography>
        <TextField
          {...register('confirmPassword', {
            validate: (value) =>
              value === getValues('password') || 'Passwords do not match',
          })}
          helperText={errors?.confirmPassword?.message}
          error={errors?.confirmPassword ? true : false}
          sx={{ mb: 2, mt: 1 }}
          className={classes.input}
          variant='outlined'
          defaultValue={data.confirmPassword && data.confirmPassword}
          placeholder='Retype Password'
          type='password'
        />
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button
            disabled={usernameTaken || !!emailTaken}
            type='submit'
            label='next'
          />
        </div>
      </form>
    </div>
  )
}

export default Onboard
