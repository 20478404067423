import React, { useEffect, useState } from 'react'
import './styles.css'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { icons } from '../../../assets/icons/icons'
import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedLink } from '../../../data/reducers/common/common.reducer'

const navLinks = () => [
  // { id: 1, to: '/features', label: 'Features' },
  // { id: 2, to: '/about', label: 'About us' },
  { id: 3, to: 'mailto:info@Make-Over.in', label: 'Contact us' },
  { id: 4, to: '/login', label: 'Login' },
]

function TopNavBar() {
  const navigate = useNavigate()
  const { selectedLink } = useSelector((state) => state.common)
  const dispatch = useDispatch()
  const location = useLocation()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const activeLink = navLinks().find((link) =>
      location.pathname?.includes(link.to)
    )
    activeLink !== undefined
      ? dispatch(setSelectedLink(activeLink))
      : dispatch(setSelectedLink({}))
  }, [location.pathname])

  const _links = useMemo(
    () =>
      navLinks().map((link) => (
        <li key={link.id} onClick={() => setChecked(false)}>
          {link?.label === 'Contact us' ? (
            <a href={link.to}>
              <span
                style={{
                  fontWeight: selectedLink?.id === link.id ? 'bold' : 'normal',
                }}
              >
                {link.label}
              </span>
            </a>
          ) : (
            <Link to={link.to}>
              <span
                style={{
                  fontWeight: selectedLink?.id === link.id ? 'bold' : 'normal',
                }}
              >
                {link.label}
              </span>
            </Link>
          )}
        </li>
      )),
    [selectedLink?.id]
  )

  return (
    <div className='menu-wrapper'>
      <header className='header'>
        <Link to='/'>
          <img
            src={icons().makeover_icon_small}
            alt='logo'
            className='logo-image'
            onClick={() => setChecked(false)}
          />
        </Link>
        <input onChange={() => setChecked(!checked)} checked={checked} className='menu-btn' type='checkbox' id='menu-btn' />
        <label className='menu-icon' htmlFor='menu-btn'>
          <span className='navicon'></span>
        </label>
        <ul className='menu'>
          {_links}
          <li style={{ paddingLeft: '10px' }}>
            <Button
              onClick={() => { navigate('/become-partner'); setChecked(false) }}
              className='rounded-pill'
              variant='contained'
              color='primary'
              id='navigationButton'
            >
              BECOME PARTNER
            </Button>
          </li>
        </ul>
      </header>
    </div>
  )
}

export default TopNavBar
