import { createSlice } from '@reduxjs/toolkit'

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    show: false,
    message: '',
    type: '',
    props: {},

    showOffline: false,
    offlineMessage: '',
    isConnected: true,
  },
  reducers: {
    showAlert: (state, { payload }) => {
      state.show = true
      state.message = payload.message
      state.type = payload.type
      state.props = payload.props || {}
    },
    closeAlert: (state) => ({ ...state, show: false }),

    showOfflineAlert: (state, { payload }) => {
      state.showOffline = true
      state.offlineMessage = payload.offlineMessage
      state.isConnected = payload.isConnected
    },

    closeOfflineAlert: (state) => ({ ...state, showOffline: false }),
  },
})
export const { closeAlert, showAlert, showOfflineAlert, closeOfflineAlert } =
  alertSlice.actions
export default alertSlice.reducer
