import { createAsyncThunk } from '@reduxjs/toolkit'
import { convertJsonToFormData } from '../../../../utils/helpers/JsonToFormData'
import { request } from '../../../services/services.common'
import { showAlert } from '../../common/alert.reducer'

/* ---------------------Create or Update Service Categories--------------------- */

export const createOrUpdateCategory = createAsyncThunk(
  'services_admin/createOrUpdateCategory',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { url, method, data, closeDialog } = payload
    const getUrl = `/Services/category/getall`

    const formData = convertJsonToFormData(data)
    const { response: res } = await request(url, method, formData)
    const { response, status } = await request(getUrl, 'GET')

    /*----- Success Response----- */
    if (status === 'success') {
      let categories = response.data?.data
      categories = categories?.map((data) => ({
        ...data,
        label: data.name,
      }))

      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(
        showAlert({
          type: 'success',
          message: `Successfully ${method === 'POST' ? 'added' : 'updated'} ${res.data?.data?.name
            } in the list.`,
        })
      )
      return { categories }
    }

    /*----- Failure Response----- */
    if (status === 'failure') {
      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(
        showAlert({
          type: 'error',
          message: `Sorry couldn't ${method === 'POST' ? 'add' : 'update'
            } the category`,
        })
      )
      rejectWithValue(response)
    }
  }
)

/* ---------------------Get All Service Categories--------------------- */

export const getServiceCategories = createAsyncThunk(
  'services_admin/getServiceCategories',
  async (_, { rejectWithValue }) => {
    const url = '/Services/category/getall'
    const { response, status } = await request(url, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      let categories = response.data?.data
      categories = categories?.map((data) => ({
        ...data,
        label: data.name,
      }))
      return {
        categories,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/* ---------------------Remove category by id--------------------- */

export const removeCategoryById = createAsyncThunk(
  'services_admin/removeCategoryById',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { categoryId, closeDialog } = payload
    const url = `/Services/category/delete/${categoryId}`
    const { response, status } = await request(url, 'DELETE')

    /*----- Success Response----- */

    if (status === 'success') {
      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(
        showAlert({
          type: 'success',
          message: `Category removed from the list.`,
        })
      )
      return { categoryId }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(
        showAlert({
          type: 'error',
          message:
            response?.statusMessage ||
            `Sorry couldn't remove the category. Please try again.`,
        })
      )
      rejectWithValue(response)
    }
  }
)

/* ---------------------Create or Update Service Categories--------------------- */

export const createOrUpdateService = createAsyncThunk(
  'services_admin/createOrUpdateService',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { url, method, data, closeDialog } = payload
    const getUrl = `/Services/getall/${data.serviceCategoryId}`
    const formData = convertJsonToFormData(data)

    const { response: res } = await request(url, method, formData)
    const { response, status } = await request(getUrl, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      let service = res.data?.data
      let servicesList = response.data?.data
      servicesList = servicesList?.map((data) => ({
        label: data?.name,
        ...data,
      }))

      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(getServiceCategories())
      dispatch(
        showAlert({
          type: 'success',
          message: `Successfully ${method === 'POST' ? 'added' : 'updated'} ${service?.name || 'service'
            } in the list.`,
        })
      )
      return {
        servicesList,
      }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(
        showAlert({
          type: 'error',
          message: `Sorry couldn't ${method === 'POST' ? 'add' : 'update'
            } the service`,
        })
      )
      rejectWithValue(response)
    }
  }
)

/* -----------------Get All Services of All Categories -------------------- */

export const getAllServices = createAsyncThunk(
  'services_admin/getAllServices',
  async (_, { rejectWithValue }) => {
    const url = ''
    const { response, status } = await request(url, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      let allServices = response.data?.data

      return {
        allServices,
      }
    }
    /*----- Failure Response----- */
    if (status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/* -----------------Get Services List by Category id-------------------- */

export const getServicesList = createAsyncThunk(
  'services_admin/getServicesList',
  async (payload, { rejectWithValue }) => {
    const url = `/Services/getall/${payload.id}`
    const { response, status } = await request(url, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      let servicesList = response.data?.data
      servicesList = servicesList?.map((data) => ({
        label: data?.name,
        ...data,
      }))
      return {
        servicesList,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/* ---------------------Remove Service by id--------------------- */

export const removeServiceById = createAsyncThunk(
  'services_admin/removeServiceById',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { serviceId, closeDialog } = payload
    const url = `/Services/delete/${serviceId}`
    const { response, status } = await request(url, 'DELETE')

    /*----- Success Response----- */

    if (status === 'success') {
      const deletedService = response.data?.data

      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(getServiceCategories())
      dispatch(
        showAlert({
          type: 'success',
          message: `${deletedService?.name} removed from the category.`,
        })
      )
      return { serviceId }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      typeof closeDialog === 'function' && closeDialog() // for closing modal
      dispatch(
        showAlert({
          type: 'error',
          message:
            response?.statusMessage ||
            `Sorry couldn't remove the service. Please try again.`,
        })
      )
      rejectWithValue(response)
    }
  }
)

/* -------Get Salon Services(Pricing Tiers) List by Cat id------ */

export const getPriceTiersByCatId = createAsyncThunk(
  'services_admin/getPriceTiersByCatId',
  async (payload = {}, { rejectWithValue }) => {
    const url = `/Values/getpartnerservices`

    const data = {
      // partnerId: SALON_ID,
      genderIds: [1, 2, 3],
      catId: payload.catId,
    }
    const { response, status } = await request(url, 'POST', data)

    /*----- Success Response----- */

    if (status === 'success') {
      let services = response.data?.data
      const salonServices = services?.map((data) => ({
        id: data.id,
        label: data.name,
        pricingTiers: data.pricingTiers,
      }))
      return { salonServices }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/*----------Request (Create) For New Service that is not yet available for partner-----------*/

export const createNewService = createAsyncThunk(
  'services_admin/createNewService',

  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/AddServiceRequest/Create`
    const { response, status } = await request(url, 'POST', payload.data)

    /*----- Success Response----- */

    if (status === 'success') {
      typeof payload.goBack === 'function' && payload.goBack()
      const newService = response.data?.data
      return { newService }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/* -------Get All New Services that is not yet available for Partner------ */

export const getAllNewServices = createAsyncThunk(
  'services_admin/getNewServices',

  async (_, { rejectWithValue }) => {
    const url = `/AddServiceRequest/get/1?partnerId=${''}`
    const { response, status } = await request(url, 'GET')

    /*----- Success Response----- */

    if (status === 'success') {
      let newServicesRequest = response.data?.data

      return { newServicesRequest }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      rejectWithValue(response)
    }
  }
)

/*----------Request (Create) For New Service that is not yet available-----------*/

export const updateNewService = createAsyncThunk(
  'services_admin/updateNewService',

  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/AddServiceRequest/Update`
    const { response, status } = await request(url, 'PUT', payload.data)

    /*----- Success Response----- */

    if (status === 'success') {
      typeof payload.goBack === 'function' && payload.goBack()
      const newService = response.data?.data
      return { newService }
    }

    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)
