import { createSlice } from '@reduxjs/toolkit'
import {
  fetchSalonServices,
  fetchSingleServiceById,
  handleEmployees,
  removeSalonService,
  updateService,
} from './asyncAction'

/*-------------Initial State-------------- */

const initialState = {
  //States Multiple Services
  loading: false,
  serviceLoading: false,
  salonServicesList: [],

  // States for Single Services
  updateLoading: false,
  listingLoading: false,
  employeesList: [],
  assignedEmployees: [],
  singleService: {},
  btnLoading: false,
}

export const partnerServicesSlice = createSlice({
  name: 'services_partner',
  initialState,
  reducers: {
    setAssignedEmployees: (state, { payload }) => {
      state.assignedEmployees = [...payload]
    },
  },

  extraReducers: {
    /* -------------------Fetch All Salon Services------------------ */

    [fetchSalonServices.pending]: (state) => void (state.loading = true),
    [fetchSalonServices.fulfilled]: (state, { payload }) => {
      state.loading = false
      if (payload) {
        state.salonServicesList = payload?.salonServicesList
          ? payload.salonServicesList
          : []
      }
    },
    [fetchSalonServices.rejected]: (state, { payload }) => {
      state.loading = false
    },

    /* ---------------Fetch Single Salon Service By Id--------------- */

    [fetchSingleServiceById.pending]: (state) =>
      void (state.serviceLoading = true),

    [fetchSingleServiceById.fulfilled]: (state, { payload }) => {
      state.serviceLoading = false
      if (payload) {
        state.singleService = payload.singleService
        state.employeesList = payload.employeesList
      }
    },

    [fetchSingleServiceById.rejected]: (state, { payload }) => {
      state.serviceLoading = false
    },

    /* ---------------Handle the Assigned Employees---------------- */
    [handleEmployees.pending]: (state, { payload }) => {
      state.btnLoading = true
    },

    [handleEmployees.fulfilled]: (state, { payload }) => {
      state.btnLoading = false

      if (payload) {
        state.singleService = payload.singleService
        state.employeesList = payload.employeesList

        const index1 = state.salonServicesList.findIndex(
          (data) => data.serviceId === payload.serviceId
        )
        if (index1 >= 0) {
          let copy = [...state.salonServicesList]
          copy.splice(index1, 1, payload.singleService)
          state.salonServicesList = [...copy]
        }
      }
    },

    [handleEmployees.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /* -----------------Update OR Add Service---------------- */

    [updateService.pending]: (state) => void (state.updateLoading = true),

    [updateService.fulfilled]: (state, { payload }) => {
      state.updateLoading = false
      if (payload) {
        state.singleService = payload.singleService

        // Update the existing Service
        if (!payload.addNewService) {
          const index2 = state.salonServicesList.findIndex(
            (data) => data.serviceId === payload.serviceId
          )
          if (index2 >= 0) {
            let copy = [...state.salonServicesList]
            copy.splice(index2, 1, payload.singleService)
            state.salonServicesList = [...copy]
          }
        }

        // Add new Service to the list
        if (payload.addNewService) {
          let copy = [...state.salonServicesList]
          state.salonServicesList = [...copy, payload.singleService]
        }
      }
    },

    [updateService.rejected]: (state, { payload }) => {
      state.updateLoading = false
    },

    /* ---------------Remove Single Salon Service By Id--------------- */

    [removeSalonService.pending]: (state) => void (state.btnLoading = true),

    [removeSalonService.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload)
        state.salonServicesList = state.salonServicesList?.filter(
          (service) => service.id !== payload.id
        )
    },

    [removeSalonService.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },
  },
})

export const { setAssignedEmployees, updatePricingTiers } =
  partnerServicesSlice.actions

export default partnerServicesSlice.reducer
