import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Footer from '../../components/CommonComponents/Footer/Footer'
import TopNavBar from '../../components/CommonComponents/TopNavbar/TopNavBar'
import Alert from '../../components/UI/Alert/Alert'
import AboutPage from '../../pages/AuthPages/AboutPage/AboutPage'
import OnBoarding from '../../pages/AuthPages/BecomePartnerPage/Onboarding'
import ContactPage from '../../pages/AuthPages/ContactPage/ContactPage'
import FeaturesPage from '../../pages/AuthPages/FeaturesPage/FeaturesPage'
import Home from '../../pages/AuthPages/Home'
import LoginPage from '../../pages/AuthPages/LoginPage/LoginPage'
import PolicyPage from '../../pages/AuthPages/PolicyPage/PolicyPage'

const AuthRoutes = () => {
  const { loggedIn } = useSelector((state) => state.auth)

  return (
    <main className='flex-column' style={{ height: '100vh', gap: 0 }} y>
      <TopNavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/policy' element={<PolicyPage />} />
        <Route path='/features' element={<FeaturesPage />} />
        <Route path='/become-partner' element={<OnBoarding />} />
        <Route path='/contact' element={<ContactPage />} />
        {!loggedIn && (
          <Route path='/*' element={<Navigate to='/login' />}></Route>
        )}
      </Routes>
      <Footer />
      <div className='flex-row'>
        <Alert />
      </div>
    </main>
  )
}

export default AuthRoutes
