import React, { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { colors } from '../../../../../assets/themes/colors/colors'
import AddPricingTiers from '../../../../../components/PartnerPageComponents/AddPricingTiers/AddPricingTiers'
import BgContainer from '../../../../../components/UI/BgContainer/BgContainer'
import Button from '../../../../../components/UI/Button/Button'
import Loading from '../../../../../components/UI/Loading/Loading'
import { getDropDownValues } from '../../../../../data/reducers/common/asyncAction'
import {
  fetchSingleServiceById,
  updateService,
} from '../../../../../data/reducers/services/partner/asyncAction'

const generateDefaultTiers = (pricingTiers = []) =>
  pricingTiers?.map((tier) => ({
    name: tier.name,
    price: tier.price,
    genderId: tier.genderId,
  }))

const EditService = () => {
  const params = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { singleService, serviceLoading, updateLoading } = useSelector(
    (state) => state.services_partner
  )
  const methods = useForm()
  const tiers = methods.watch('tiers')

  useEffect(() => dispatch(getDropDownValues()), [])
  useEffect(() => {
    methods.setValue(
      'tiers',
      generateDefaultTiers(singleService?.listPricingTiers)
    )
  }, [methods, singleService?.listPricingTiers])

  useEffect(
    () => dispatch(fetchSingleServiceById({ id: params?.serviceId })),
    [params?.serviceId]
  )

  const onSubmit = useCallback(
    (data) => {
      const url = `/SalonServices/update/${singleService?.id}`
      const listPricingTiers = data.tiers?.map((tier) => ({
        genderId: tier.genderId,
        name: tier.name,
        price: tier.price,
        isFeatured: true,
      }))
      const payload = {
        url,
        goBack: () => navigate(-1),
        data: {
          ...singleService,
          listPricingTiers,
        },
      }

      dispatch(updateService(payload))
    },
    [singleService]
  )

  if (serviceLoading)
    return (
      <div className='app-loading'>
        <Loading size='loader-medium' />
      </div>
    )

  return (
    <FormProvider {...methods}>
      <BgContainer title={state?.singleService?.serviceName}>
        <div>
          <p
            style={{
              fontSize: 24,
              color: colors.grey2,
              textTransform: 'capitalize',
            }}
          >
            {state?.partnerName}
          </p>

          <div className='margin-t-m'>
            <AddPricingTiers />
          </div>
        </div>
        {tiers?.length ? (
          <div style={{ width: '100%' }} className='flex-row-rev margin-t-s'>
            <div className='flex-row'>
              <Button
                label='Cancel'
                variant='outlined'
                onClick={() => navigate(-1)}
              />
              <Button
                btnLoading={updateLoading}
                type='submit'
                label='Save'
                onClick={methods.handleSubmit(onSubmit)}
              />
            </div>
          </div>
        ) : null}
      </BgContainer>
    </FormProvider>
  )
}

export default EditService
