// import { partnerType } from "../../../utils/helpers/getdropdownvalues";
import { post, api } from '../services.common'

const submitForm = async (data) => {
  let form = new FormData()
  form.append('Username', data.name)
  form.append('Password', data.password)
  // form.append("PartnerType", partnerType(data.partnerType));
  form.append('NameOfOwner', data.nameOfOwner)
  form.append('PhoneNumber', data.phone)
  form.append('isNumberVerified', true)
  form.append('isEmailVerified', true)
  form.append('Email', data.email)
  form.append('NameOfEstablishment', data.nameOfEstalishment)
  form.append('Address', data.postalAddress)
  form.append('PinCode', data.pinCode)
  form.append('City', data.city)
  form.append('State', data.state)
  if (data.lat) form.append('lat', data.lat)
  if (data.lon) form.append('lon', data.lon)
  form.append('Landline', data.contactNumber)
  form.append('Documents[0].File', data['Documents[0].File'])
  form.append('Documents[1].File', data['Documents[1].File'])
  form.append('Documents[2].File', data['Documents[2].File'])
  form.append('Documents[3].File', data['Documents[3].File'])
  form.append('Documents[0].DocumentType', data['Documents[0].DocumentType'])
  form.append('Documents[1].DocumentType', data['Documents[1].DocumentType'])
  form.append('Documents[2].DocumentType', data['Documents[2].DocumentType'])
  form.append('Documents[3].DocumentType', data['Documents[3].DocumentType'])

  for (var value of form.values()) {
    console.log(value)
  }

  const url = `${api}PartnerOnboarding/SubmitForm`
  const headers = {
    'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
  }
  const res = await post(url, form, headers)
  console.log({ form })
  return res
}

export const onboard = {
  submitForm,
}
