import React from 'react'
import { Route } from 'react-router-dom'
import CreatePartner from '../../../pages/PartnerPages/CreatePartner/CreatePartner'
import CreateSchedule from '../../../pages/PartnerPages/CreatePartner/CreateSchedule'
import CreateScheduleByDays from '../../../pages/PartnerPages/CreatePartner/CreateScheduleByDays'
import Partners from '../../../pages/PartnerPages/Partners'
import Account from '../../../pages/PartnerPages/SinglePartner/PartnerProfile/Account/Account'
import Amenities from '../../../pages/PartnerPages/SinglePartner/PartnerProfile/Amenities/Amenities'
import Gallery from '../../../pages/PartnerPages/SinglePartner/PartnerProfile/Gallery/Gallery'
import Schedule from '../../../pages/PartnerPages/SinglePartner/PartnerProfile/Schedule/Schedule'
import ScheduleByDays from '../../../pages/PartnerPages/SinglePartner/PartnerProfile/Schedule/ScheduleByDays'
import AddService from '../../../pages/PartnerPages/SinglePartner/PartnerServices/AddService/AddService'
import EditService from '../../../pages/PartnerPages/SinglePartner/PartnerServices/EditService/EditService'
import SinglePartner from '../../../pages/PartnerPages/SinglePartner/SinglePartner'

const PartnerPageRoutes = () => {
  return (
    <>
      <Route path='/partner' element={<Partners />} />
      <Route path='/partner/:partnerId' element={<SinglePartner />} />
      <Route path='/partner/:partnerId/:tab' element={<SinglePartner />} />
      <Route path='/partner/:partnerId/profile/account' element={<Account />} />
      <Route
        path='/partner/:partnerId/profile/schedule'
        element={<Schedule />}
      />
      <Route
        path='/partner/:partnerId/profile/schedule/days'
        element={<ScheduleByDays />}
      />
      <Route
        path='/partner/:partnerId/profile/amenities'
        element={<Amenities />}
      />
      <Route path='/partner/:partnerId/profile/gallery' element={<Gallery />} />

      <Route
        path='/partner/:partnerId/services/:serviceId'
        element={<EditService />}
      />

      <Route path='/partner/:partnerId/add-service' element={<AddService />} />

      {/* ----------------------------Add New Partner Routes------------------------- */}

      <Route path='/partner/add-partner' element={<CreatePartner />} />
      <Route
        path='/partner/add-partner/:partnerId/work-schedule'
        element={<CreateSchedule />}
      />
      <Route
        path='/partner/add-partner/:partnerId/work-schedule/days'
        element={<CreateScheduleByDays />}
      />
    </>
  )
}

export default PartnerPageRoutes
