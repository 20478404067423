import { ChevronLeft, ChevronRightSharp } from '@mui/icons-material'
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from '../../../assets/themes/colors/colors'
import { getServiceCategories } from '../../../data/reducers/services/admin/asyncAction'
import './styles.css'

const CategoryDrawer = () => {
  const { categories } = useSelector((state) => state.services_admin)
  const navigate = useNavigate()
  const params = useParams()
  const [selectedCategory, setSelectedCategory] = useState(params?.categoryId)
  const dispatch = useDispatch()

  useEffect(() => {
    !categories?.length && dispatch(getServiceCategories())
  }, [])

  useEffect(() => {
    setSelectedCategory({ id: +params?.categoryId })
  }, [params?.categoryId])

  const selectCategory = useCallback((data) => {
    setSelectedCategory((prev) => {
      prev.id !== data.id && navigate(`/categories/${data.id}`)
      return data
    })
  }, [])

  const _renderCategories = useMemo(
    () =>
      categories?.map((category, index) => {
        const active = selectedCategory?.id === category.id
        return (
          <ListItem sx={{ mb: -1.2 }} key={index}>
            <ListItemButton
              className='box-shadow'
              sx={Styles.getSelectedStyle(active)}
              key={index}
              onClick={() => selectCategory(category)}
            >
              <ListItemText
                primary={category.label}
                sx={{ minWidth: 12, color: active ? colors.primary : 'black' }}
              />
              <ListItemIcon sx={{ minWidth: 4 }}>
                <ChevronRightSharp />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        )
      }),
    [categories, selectedCategory]
  )

  return (
    <nav aria-label='navbar' className='category-navbar'>
      <div className='fixed-back-btn'>
        <ListItemButton onClick={() => navigate(-1)} sx={{ mx: 2 }}>
          <ChevronLeft htmlColor='black' />
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            Back
          </Typography>
        </ListItemButton>
      </div>
      <List sx={{ mt: -1.5 }}>{_renderCategories}</List>
    </nav>
  )
}

class Styles {
  static getSelectedStyle = (active) => ({
    borderRadius: 1,
    borderLeft: active
      ? `solid 3.5px ${colors.primary}`
      : 'solid 3.5px transparent',
    bgcolor: active ? colors.pinkTint2 : 'white',
  })
}

export default CategoryDrawer
