import { Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BgContainer from '../../../components/UI/BgContainer/BgContainer'
import Schedule from '../../../components/PartnerPageComponents/Schedule/Schedule'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/UI/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getPartnerSchedule,
  updatePartnerSchedule,
} from '../../../data/reducers/partners/schedule/asyncAction'
import { getDropDownValues } from '../../../data/reducers/common/asyncAction'
import { removeSeconds } from '../../../utils/helpers/time'

const CreateScheduleByDays = () => {
  const methods = useForm()
  const [workSchedule, setWorkSchedule] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)
  const { daysRange } = useSelector((state) => state.common)
  const { partnerSchedule, partnerScheduleByDays, btnLoading } = useSelector(
    (state) => state.partners_schedule
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const classes = useStyles()

  useEffect(() => {
    !daysRange?.length && dispatch(getDropDownValues())
  }, [])

  useEffect(() => {
    dispatch(getPartnerSchedule(params?.partnerId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.partnerId])

  const _renderTabs = useMemo(
    () => daysRange?.map((day, index) => <Tab label={day.name} key={index} />),
    [daysRange]
  )

  const _getWorkSchedule = useCallback(
    (data, day, index) => {
      workSchedule[index] = { ...data, ...day }
      const copy = workSchedule?.slice()
      setWorkSchedule([...copy])
    },
    [workSchedule]
  )

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const _renderSchedule = useMemo(
    () =>
      daysRange?.map((day, index) => {
        const activeDay = partnerScheduleByDays?.find(
          (data) => data.dayId === day.id
        )
        return (
          <div
            key={index}
            style={{ display: selectedTab === index ? 'block' : 'none' }}
          >
            <Schedule
              day={day?.name}
              getWorkSchedule={(data) => _getWorkSchedule(data, day, index)}
              holiday={activeDay === undefined}
            />
          </div>
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [daysRange, selectedTab, partnerSchedule]
  )

  const _onSubmit = useCallback(
    (data) => {
      // data is like-> {Monday:[{work:{}}],Tuesday:[{}]....}
      const workScheduleByDays = workSchedule?.map((schedule) => {
        const workStartTime = schedule?.work
          ? removeSeconds(schedule.work?.fromTime?.toLocaleTimeString())
          : ''
        const workEndTime = schedule?.work
          ? removeSeconds(schedule.work?.toTime?.toLocaleTimeString())
          : ''
        let listBreakTimes = data[schedule?.name]?.map((list) => ({
          breakStartTime: removeSeconds(list.fromTime?.toLocaleTimeString()),
          breakEndTime: removeSeconds(list.toTime?.toLocaleTimeString()),
        }))
        return {
          dayId: schedule?.id,
          day: schedule?.name,
          isWorkDay: schedule?.isWorkDay,
          workStartTime,
          workEndTime,
          listBreakTimes,
        }
      })
      const payload = {
        data: { ...partnerSchedule, workScheduleByDays },
        navigate: () => navigate(`/partner/${params?.partnerId}/services`),
      }
      dispatch(updatePartnerSchedule(payload))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workSchedule, partnerSchedule]
  )

  return (
    <FormProvider {...methods}>
      <BgContainer>
        <div className='flex-column'>
          <div style={{ justifyContent: 'center', alignSelf: 'center' }}>
            <p style={{ fontSize: '30px', fontWeight: 100 }}>Business Hours</p>

            <div className='margin-t-s'>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                className={classes.tabs}
              >
                {_renderTabs}
              </Tabs>
              <div>{_renderSchedule}</div>
            </div>

            <div style={{ width: '100%' }} className='flex-row-rev margin-t-s'>
              <div className='flex-row'>
                <Button
                  label='previous'
                  variant='outlined'
                  onClick={() => navigate(-1)}
                />
                <Button
                  type='submit'
                  label='next'
                  btnLoading={btnLoading}
                  onClick={methods.handleSubmit(_onSubmit)}
                />
              </div>
            </div>
          </div>
        </div>
      </BgContainer>
    </FormProvider>
  )
}

const useStyles = makeStyles({
  tabs: {
    '& .MuiButtonBase-root.MuiTab-root': {
      fontSize: 16,
      paddingBottom: 2,
      textTransform: 'capitalize',
    },
  },
})

export default CreateScheduleByDays
