export const keys = {
  KEY_USER_LOCATION: 'USER_LOCATION',
  KEY_ONBOARDING_FORM: 'FORM_DATA',
  KEY_USER_DATA: 'USER_DATA',
  KEY_AUTH_TOKEN: 'AUTH_TOKEN',
  KEY_USER_ROLE: 'USER_ROLE',
  KEY_USER_NAME: 'USER_NAME',
  KEY_USER_EMAIL: 'USER_EMAIL',
  KEY_PARTNER_ID: 'PARTNER_ID',
  KEY_PARTNER_DATA: 'PARTNER_DATA',
  KEY_PARTNER_LISTING: 'PARTNER_LISTING',
}
