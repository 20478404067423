import React from 'react'
import FormInput from '../../UI/FormInput/FormInput'
import SelectInput from '../../UI/SelectInput/SelectInput'
import './styles.css'

const DataInput = ({
  label = '',
  editMode = false,
  defaultValue = '',
  inputProps = {},
  salonTypeInput = false,
}) => {
  return (
    <div className={`flex-row data-input margin-t-m`}>
      <div className='label'>{label}</div>

      <>
        {editMode ? (
          !salonTypeInput ? (
            <FormInput
              {...inputProps}
              defaultValue={defaultValue}
              containerStyle={{
                marginBottom: 0,
                ...inputProps?.containerStyle,
              }}
            />
          ) : (
            <SelectInput
              name='partnerTypeId'
              defaultValue={defaultValue}
              textFieldProps={{ placeholder: 'Select Partner Type' }}
              {...inputProps}
            />
          )
        ) : (
          <div className='value'>
            {defaultValue ? (
              salonTypeInput ? (
                inputProps?.data?.find((type) => type.id === defaultValue)
                  ?.label
              ) : (
                defaultValue
              )
            ) : (
              <div className='span'></div>
            )}
          </div>
        )}
      </>
    </div>
  )
}

export default DataInput
