import {
  VerifiedUser,
  Dashboard,
  Store,
  Headset,
  Group,
  BarChart,
  List as ListIcon,
} from '@mui/icons-material'
import { keys } from '../../constants/keys'
import { getDataFromLocalStorage } from '../helpers/localStorage'

export const navLinks = () => {
  const role = getDataFromLocalStorage(keys.KEY_USER_ROLE)
  if (role === 'Partner') {
    return [
      {
        id: 2,
        label: 'Dashboard',
        url: '/partner',
        icon: (color) => <Dashboard htmlColor={color} />,
      },
    ]
  } else {
    return [
      // {
      //   id: 1,
      //   label: 'Dashboard',
      //   url: '/dashboard',
      //   icon: (color) => <Dashboard htmlColor={color} />,
      // },
      {
        id: 2,
        label: 'Partners',
        url: '/partner',
        icon: (color) => <Store htmlColor={color} />,
      },
      // {
      //   id: 3,
      //   label: 'Customers',
      //   url: '/customer',
      //   icon: (color) => <Group htmlColor={color} />,
      // },
      // {
      //   id: 4,
      //   label: 'Verifications',
      //   url: '/verification',
      //   icon: (color) => <VerifiedUser htmlColor={color} />,
      // },
      {
        id: 5,
        label: 'Services',
        url: '/categories',
        icon: (color) => <ListIcon htmlColor={color} />,
      },
      // {
      //   id: 6,
      //   label: 'Insights',
      //   url: '/insights',
      //   icon: (color) => <BarChart htmlColor={color} />,
      // },
      // {
      //   id: 7,
      //   label: 'Support',
      //   url: '/support',
      //   icon: (color) => <Headset htmlColor={color} />,
      // },
    ]
  }
}
