import React from 'react'
import { Route } from 'react-router-dom'
import ServiceCategories from '../../../pages/ServicePages/ServiceCategories'
import SingleCategory from '../../../pages/ServicePages/SingleCategory/SingleCategory'

const ServicePageRoutes = () => {
  return (
    <>
      <Route path='/categories/*' element={<ServiceCategories />}></Route>
      <Route
        path='/categories/:categoryId/*'
        element={<SingleCategory />}
      ></Route>
    </>
  )
}

export default ServicePageRoutes
