import { createSlice } from '@reduxjs/toolkit'
import {
  createUnavailableTimeline,
  createPartnerSchedule,
  getUnavailableTimeline,
  getPartnerSchedule,
  updateUnavailableTimeline,
  updatePartnerSchedule,
} from './asyncAction'

export const partnerSchedule = createSlice({
  name: 'partner_schedule',
  initialState: {
    scheduleLoading: false,
    partnerSchedule: {},
    partnerScheduleByDays: [],
    btnLoading: false,
    daysLoading: false,
    fromDate: '',
    fromTime: '',
    toDate: '',
    toTime: '',
    unavailable: { from: '', to: '', id: 0 },
    workSchedule: [],
  },

  reducers: {
    setFromDate: (state, { payload }) => void (state.fromDate = payload),
    setToDate: (state, { payload }) => void (state.toDate = payload),
    setFromTime: (state, { payload }) => void (state.fromTime = payload),
    setToTime: (state, { payload }) => void (state.toTime = payload),
    setWorkSchedule: (state, { payload }) => {
      state.workSchedule = payload
    },
  },
  extraReducers: {
    /*----------------Get Work Schedule For Partner --------------*/

    [getPartnerSchedule.pending]: (state) =>
      void (state.scheduleLoading = true),
    [getPartnerSchedule.fulfilled]: (state, { payload }) => {
      state.scheduleLoading = false
      if (payload) {
        state.partnerSchedule = payload.partnerSchedule || {}
        state.partnerScheduleByDays = payload.partnerScheduleByDays || []
      }
    },
    [getPartnerSchedule.rejected]: (state) =>
      void (state.scheduleLoading = false),

    /*----------------Create Work Schedule For Partner --------------*/

    [createPartnerSchedule.pending]: (state) => void (state.btnLoading = true),
    [createPartnerSchedule.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.partnerSchedule = payload.partnerSchedule || {}
        state.partnerScheduleByDays = payload.partnerScheduleByDays || []
      }
    },
    [createPartnerSchedule.rejected]: (state) =>
      void (state.btnLoading = false),

    /*----------------Update Work Schedule For Partner --------------*/

    [updatePartnerSchedule.pending]: (state) => void (state.btnLoading = true),
    [updatePartnerSchedule.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.partnerSchedule = payload.partnerSchedule || {}
        state.partnerScheduleByDays = payload.partnerScheduleByDays || []
      }
    },
    [updatePartnerSchedule.rejected]: (state) =>
      void (state.btnLoading = false),

    /*----------------Get Unavailability timeline of salon--------------*/

    [getUnavailableTimeline.pending]: (state) => void (state.btnLoading = true),
    [getUnavailableTimeline.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.unavailable = payload.unavailable ? payload.unavailable : {}
      }
    },
    [getUnavailableTimeline.rejected]: (state) =>
      void (state.btnLoading = false),

    /*----------------Create Unavailability timeline of salon--------------*/

    [createUnavailableTimeline.pending]: (state) =>
      void (state.btnLoading = true),
    [createUnavailableTimeline.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.unavailable = payload.unavailable ? payload.unavailable : {}
      }
    },
    [createUnavailableTimeline.rejected]: (state) =>
      void (state.btnLoading = false),

    /*----------------Update Unavailability timeline of salon--------------*/

    [updateUnavailableTimeline.pending]: (state) =>
      void (state.btnLoading = true),
    [updateUnavailableTimeline.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.unavailable = payload.unavailable ? payload.unavailable : {}
      }
    },
    [updateUnavailableTimeline.rejected]: (state) =>
      void (state.btnLoading = false),
  },
})

export const {
  setFromDate,
  setToDate,
  setFromTime,
  setToTime,
  setWorkSchedule,
} = partnerSchedule.actions
export default partnerSchedule.reducer
