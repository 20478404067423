import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { styles, useStyles } from './styles'
import { memo, useCallback, useMemo, useState } from 'react'

const countryList = require('../../../utils/data/countryCode.json')
const defaultUrl =
  'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg'

export const CountryCodeIcon = () => {
  const [countryCode, setCountryCode] = useState({ dial_code: '+91' })
  const classes = useStyles()
  const _renderList = useMemo(
    () =>
      countryCodeArray.map((data, index) => (
        <MenuItem value={data.dial_code} key={index}>
          <ListItem data={data} showName />
        </MenuItem>
      )),
    []
  )
  const selectHandler = useCallback((event) => {
    const value = event?.target?.value
    const item = countryCodeArray.find((data) => data.dial_code === value)
    if (item) {
      setCountryCode(item)
    }
  }, [])
  return (
    <div style={{ marginTop: '7px' }}>
      <FormControl className={classes.formControl}>
        <InputLabel id='select-id-label'>
          <ListItem data={countryCode} />
        </InputLabel>
        <Select
          // disabled
          variant='standard'
          disableUnderline
          value={countryCode?.dial_code}
          onChange={(e) => selectHandler(e)}
          style={{ width: '85px' }}
          defaultValue={'+91'}
          renderValue={() => null}
          labelId='label-id'
          id='select-id'
          classes={{
            icon: classes.icon,
            iconOpen: classes.iconOpen,
          }}
        >
          {_renderList}
        </Select>
      </FormControl>
    </div>
  )
}

const countryCodeArray = countryList?.map((list) => ({
  ...list,
  name: list?.name.split(',')[0],
  flagImage: `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${list.code}.svg`,
}))

const ListItem = memo(({ data = {}, showName = false }) => (
  <div style={styles.listPadding}>
    <div className={showName ? 'm-right-m' : ''}>
      <img
        alt='flag'
        src={data?.flagImage || defaultUrl}
        width={24}
        height={24}
        style={styles.flagImage}
      />
    </div>
    <div style={styles.dial_code} className={showName ? 'm-right-s' : ''}>
      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
        {data.dial_code || `+91`}
      </Typography>
    </div>
    {showName ? (
      <div>
        <Typography variant='body1'>{data.name || 'India'}</Typography>
      </div>
    ) : (
      <div style={styles.line}></div>
    )}
  </div>
))
