import { ChevronLeft } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React from 'react'
import './styles.css'
import { useNavigate } from 'react-router-dom'

const BgContainer = ({
  children,
  title = 'Add new partner',
  rightComponent = null,
  titleStyle = {},
  contentStyle = {},
  component = null,
  noBack,
}) => {
  const navigate = useNavigate()
  return (
    <main className='bgContainer'>
      <div className='container-header'>
        <div>
          {!noBack && <IconButton onClick={() => navigate(-1)}>
            <ChevronLeft htmlColor='black' />
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              Back
            </Typography>
          </IconButton>}
        </div>
        <div className='flex-x-btw'>
          <Typography variant='h4' sx={{ ...titleStyle }}>
            {title}
          </Typography>
          {rightComponent && (
            <div
              style={{ justifyContent: 'center', alignSelf: 'center' }}
              className='m-left-s'
            >
              {rightComponent}
            </div>
          )}
        </div>
        {component && component}
      </div>
      <div className='container-content'>
        <div style={{ ...contentStyle }}>{children}</div>
      </div>
    </main>
  )
}

export default BgContainer
