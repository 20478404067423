import React from 'react'
import DropZone from 'react-dropzone'
import { Controller, useWatch } from 'react-hook-form'
import ImagePreview from './ImagePreview'
import Placeholder from './Placeholder'
import ShowError from './ShowError'
import './styles.css'

const DropZoneField = ({
  label,
  labelStyle = {},
  control,
  name = '',
  errors,
  rules,
  defaultValue = '',
}) => {
  const watch = useWatch({
    control,
  })
  const imageFile = watch?.[name] ? watch[name] : []

  return (
    <div className='preview-container'>
      {label && <p style={{ ...labelStyle }}>{label}</p>}
      <div className='margin-t-xs'>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange } }) => (
            <DropZone
              multiple={false}
              accept='image/jpeg, image/png'
              className='upload-container'
              onDrop={onChange}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {imageFile?.length > 0 || defaultValue ? (
                      <ImagePreview
                        files={imageFile}
                        defaultUrl={defaultValue}
                      />
                    ) : (
                      <Placeholder />
                    )}
                  </div>
                </section>
              )}
            </DropZone>
          )}
        />
      </div>

      {errors && errors[name] && <ShowError message={errors[name]?.message} />}
    </div>
  )
}
export default DropZoneField
