import React from 'react'
import {
  Typography,
  Grid,
  ListItemIcon,
  ListItemText,
  Box,
  Avatar,
  Paper,
  MenuList,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material'

import {
  SettingsOutlined,
  HelpOutlineOutlined,
  InfoOutlined,
  FeedbackOutlined,
  LogoutOutlined,
} from '@mui/icons-material'
import { logoutUser } from '../../../data/reducers/auth/auth.reducer'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { keys } from '../../../constants/keys'

const menuItems = () => [
  {
    id: 1,
    label: 'settings',
    icon: <SettingsOutlined fontSize='small' htmlColor='black' />,
  },
  {
    id: 2,
    label: 'help',
    icon: <HelpOutlineOutlined fontSize='small' htmlColor='black' />,
  },
  {
    id: 3,
    label: 'about',
    icon: <InfoOutlined fontSize='small' htmlColor='black' />,
  },
  {
    id: 4,
    label: 'feedback',
    icon: <FeedbackOutlined fontSize='small' htmlColor='black' />,
  },
  {
    id: 5,
    label: 'logout',
    icon: <LogoutOutlined fontSize='small' htmlColor='black' />,
  },
]

const ProfileMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { role } = useSelector((state) => state.auth)
  const [user] = useLocalStorage(keys.KEY_USER_DATA)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const _menuItems = useMemo(
    () =>
      menuItems().map((item) => (
        <MenuItem
          key={item.id}
          sx={{ padding: 1.5 }}
          onClick={
            item.id === 5
              ? () =>
                  dispatch(logoutUser({ redirect: () => navigate('/login') }))
              : () => {}
          }
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText style={{ textTransform: 'capitalize', color: 'black' }}>
            {item.label}
          </ListItemText>
        </MenuItem>
      )),
    []
  )
  return (
    <Grid container justifyContent='flex-end' alignItems='center' item xs={4}>
      <div>
        <Box
          id='basic-button'
          aria-controls='basic-menu'
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          className='d-flex align-items-center'
        >
          <Box sx={{ textAlign: 'end' }}>
            <Typography variant='body1' style={{ textTransform: 'capitalize' }}>
              {user?.firstName}
            </Typography>
            <Typography variant='caption'>{role}</Typography>
          </Box>
          <Avatar
            onClick={handleClick}
            sx={{ ml: 2 }}
            style={{ textTransform: 'capitalize', cursor: 'pointer' }}
          >
            {user?.firstName?.[0] || 'user'}
          </Avatar>
        </Box>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Paper elevation={0} sx={{ width: 220, maxWidth: '100%' }}>
            <MenuList>
              <MenuItem>
                <Box
                  id='basic-button'
                  aria-controls='basic-menu'
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  className='d-flex align-items-center'
                >
                  <Avatar
                    onClick={handleClick}
                    sx={{ mr: 1.5, width: 30, height: 30 }}
                    style={{ textTransform: 'capitalize', cursor: 'pointer' }}
                  >
                    {user?.firstName?.[0]}
                  </Avatar>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography
                      variant='body1'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {user?.firstName}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <Divider />
              {_menuItems}
            </MenuList>
          </Paper>
        </Menu>
      </div>
    </Grid>
  )
}

export default ProfileMenu
