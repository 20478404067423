import { createAsyncThunk } from '@reduxjs/toolkit'
import { request } from '../../../services/services.common'
import { showAlert } from '../../common/alert.reducer'

/* ---------------------Fetch All Salon Services--------------------- */

export const fetchSalonServices = createAsyncThunk(
  'services_partner/fetchSalonServices',

  async (partnerId, { rejectWithValue, dispatch }) => {
    const url = `/SalonServices/getAll/${partnerId}?getDetails=true`

    const { response, status } = await request(url, 'GET')
    if (status === 'success') {
      const salonServicesList = response.data?.data
      return {
        salonServicesList,
      }
    }
    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/* ---------------Fetch Single Salon Service By Id--------------- */

// helper Function
const filterEmployees = (allEmployees, assignedEmployeesId) => {
  let filtered = []

  allEmployees?.forEach((list) => {
    if (!assignedEmployeesId?.some((id) => list.id === id)) filtered.push(list)
  })

  filtered = filtered.map((data) => ({
    name: `${data.firstName} ${data.lastName ? data.lastName : ''}`,
    id: data.id,
    avatar: data.profileImageUrl,
  }))

  return filtered
}

export const fetchSingleServiceById = createAsyncThunk(
  'services_partner/fetchSingleServiceById',

  async (payload, { rejectWithValue, dispatch }) => {
    const url = `/SalonServices/get/${payload.id}`
    const getAllEmployeesUrl = '/SalonEmployee/getall'

    const { response, status } = await request(url, 'GET')
    const employeesResponse = await request(getAllEmployeesUrl, 'GET')

    if (status === 'success') {
      const singleService = response.data?.data
      const allEmployees = employeesResponse.response.data?.data

      const employeesList = filterEmployees(
        allEmployees,
        singleService?.employeesId
      )
      return {
        singleService,
        employeesList,
      }
    }
    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/* -----------------Handle Employees of a service---------------- */

export const handleEmployees = createAsyncThunk(
  'services_partner/handleEmployees',

  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/SalonServices/update/${payload.id}`
    const getAllEmployeesUrl = '/SalonEmployee/getall'
    const { response, status } = await request(url, 'PUT', payload.data)
    const employeesResponse = await request(getAllEmployeesUrl, 'GET')

    /*----- Success Response----- */

    if (status === 'success' && employeesResponse.status === 'success') {
      const singleService = response.data?.data
      const allEmployees = employeesResponse.response.data?.data

      const employeesList = filterEmployees(
        allEmployees,
        singleService?.employeesId
      )

      return {
        singleService,
        employeesList,
        serviceId: payload.id,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      return rejectWithValue(response)
    }
  }
)

/* -----------------Update Service---------------- */

export const updateService = createAsyncThunk(
  'services_partner/updateService',

  async (payload, { rejectWithValue, dispatch }) => {
    const url = payload.url
    const method = url?.includes('create') ? 'POST' : 'PUT'

    const { response, status } = await request(url, method, payload.data)

    /*----- Success Response----- */

    if (status === 'success') {
      const singleService = response.data?.data
      typeof payload?.goBack === 'function' && payload.goBack()
      dispatch(
        showAlert({
          message: `Service ${
            url?.includes('create') ? 'created' : 'updated'
          } successfully.`,
          type: 'success',
        })
      )
      return {
        singleService,
        serviceId: payload?.data.serviceId,
        addNewService: url?.includes('create') ? true : false,
      }
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      dispatch(
        showAlert({
          message: `Couldn't ${
            url?.includes('create') ? 'create' : 'update'
          } the service. Please try again.`,
          type: 'error',
        })
      )
      return rejectWithValue(response)
    }
  }
)

/* --------------Turn off or On Listing for a service-------------- */

export const toggleListing = createAsyncThunk(
  'services_partner/toggleListing',

  async (payload, { rejectWithValue, dispatch }) => {
    const url = payload.url
    const { response, status } = await request(url, 'PUT')

    /*----- Success Response----- */
    if (status === 'success') {
      const listing = url?.includes('true')
      dispatch(
        showAlert({
          message: `${
            listing
              ? 'Service is online, customers will be able to see them'
              : "Services are offline, customers won't be able to see them"
          }`,
          type: `${listing ? 'success' : 'info'}`,
        })
      )
    }
    /*----- Failure Response----- */

    if (status === 'failure') {
      dispatch(
        showAlert({
          message: `Couldn't update the listing of the service. Please try again`,
          type: `error`,
        })
      )
      return rejectWithValue(response)
    }
  }
)

/* --------------Remove Salon Service by id------------- */

export const removeSalonService = createAsyncThunk(
  'services_partner/removeSalonService',

  async (payload = {}, { rejectWithValue, dispatch }) => {
    const url = `/SalonServices/delete/${payload.id}`
    const { response, status } = await request(url, 'DELETE')

    /*----- Success Response----- */
    if (status === 'success') {
      typeof payload.closeDialog === 'function' && payload.closeDialog()
      dispatch(
        showAlert({
          message: `Service removed from the list.`,
          type: 'success',
        })
      )
      return { id: payload.id }
    }

    /*----- Failure Response----- */
    if (status === 'failure') {
      typeof payload.closeDialog === 'function' && payload.closeDialog()
      dispatch(
        showAlert({
          message: `couldn't remove the salon service. please try again!`,
          type: 'error',
        })
      )
      return rejectWithValue(response)
    }
  }
)
