import React, { useState } from 'react'
import { icons } from '../../assets/icons/icons'
import './styles.css'
import FormInput from '../../components/UI/FormInput/FormInput'
import { useForm } from 'react-hook-form'
import Button from '../../components/UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { FormHelperText } from '@mui/material'
import { EMAIL_PATTERN } from '../../constants/pattern'
import { followUpdates } from '../../data/reducers/auth/asyncAction'
import MuiModal from '../../components/UI/Modal/MuiModal'
import { unwrapResult } from '@reduxjs/toolkit'
import successPlaceholder from '../../assets/images/followUpdate.svg'
import { Link } from 'react-router-dom'
import { colors } from '../../assets/themes/colors/colors'
import mobileScreen1 from '../../assets/images/landing-page-img1.svg'
import mobileScreen2 from '../../assets/images/landing-page-img2.svg'
import googlePlay from '../../assets/images/google-play.svg'

const Home = () => {
  const dispatch = useDispatch()
  const [Modal, setModal] = useState(false)
  const { btnLoading } = useSelector((state) => state.auth)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  })

  const onSubmit = async (data) => {
    const result = unwrapResult(
      await dispatch(followUpdates({ email: data.email }))
    )
    setModal(result?.followUpdates)
    reset({ email: '' })
  }

  const renderFollowUpdatesContent = () => (
    <div>
      <h3
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 18,
        }}
      >E-mail</h3>
      <div className='input-container'>
        <FormInput
          control={control}
          name='email'
          placeholder='Add your email for new updates'
          containerStyle={{ margin: '0' }}
          rules={{
            required: {
              value: true,
              message: 'Please add your email',
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: 'Not a valid email address',
            },
          }}
          type='email'
          inputStyle={{
            height: '48px',
            paddingLeft: '20px',
          }}
        />
        <Button
          btnLoading={btnLoading}
          label='follow updates'
          id='btn'
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      {
        errors['email'] && (
          <FormHelperText
            error
            sx={{
              maxWidth: '70vw',
              margin: 'auto',
              fontSize: 13,
            }}
          >
            {errors['email']?.message}
          </FormHelperText>
        )
      }
    </div>
  )

  const renderSuccessModal = () => (
    <MuiModal
      isOpen={Modal}
      close={() => setModal(false)}
      title='Thank you for showing interest in us!'
    >
      <div className='flex-y-center'>
        <img
          src={successPlaceholder}
          alt='Success On Following'
          style={{ width: 300, height: 250, selfAlign: 'center' }}
        />
        <p className='margin-t-xs'>
          You will receive updates to your email whenever we have something
          interesting to share
        </p>
        <p className='margin-t-xs' style={{ textAlign: 'center' }}>
          Please read our{' '}
          <Link to='/policy'>
            <span
              style={{ color: colors.primary, textDecoration: 'underline' }}
            >
              Privacy policy
            </span>
          </Link>
        </p>
        <Button
          label='ok'
          onClick={() => setModal(false)}
          sx={{
            mt: 2,
            width: '100%',
          }}
        />
      </div>
    </MuiModal>
  )

  return (
    <section
      className='flex-column'
      style={{ gap: 0, flex: 1, justifyContent: 'space-between' }}
    >
      <div className='landingPageContainer'>
        <div className='layer1'>
          <div className='section1'>
            <h1>Make-Over</h1>
            <p>Find out who is “THE BEST” at making you look “YOUR BEST”. Whether you need a quick make-up and hair for an unplanned party, or a relaxing pamper session that requires facials, hair spas or your regular mani-pedi, Make-Over is here to the rescue! Check and compare from the wide range of Salons, Spas and MUAs based on location, budget, and experiences. And guess what, you can cut the que and book an appointment beforehand too!</p>
            <a href='https://play.google.com/store/apps/details?id=in.makeover.customer' target='_blank'>
              <img src={googlePlay} alt='google_play_button' />
            </a>
            <span style={{display: 'block', fontSize: 13}}>Available only for beta testers</span>
          </div>
          <div className='section2'>
            <div className='flex-x-btw-start'>
              <div className='leftImageContainer'>
                <img src={mobileScreen1} alt='mobile_screen_01' />
              </div>
              <div className='rightImageContainer'>
                <img src={mobileScreen2} alt='mobile_screen_02' />
              </div>
            </div>
          </div>
        </div>
        <div className='layer2'>
          <div className='section3'>
            <div className='content'>
              <div className='imgContainer'>
                <img src={mobileScreen2} alt='mobile_screen_2' />
              </div>
            </div>
          </div>
          <div className='section4'>
            <div className='content'>
              <h3>How it Works</h3>
              <p>Locate the best salons and also salon service providers (With our listed @home salon service experts). Found a salon people in your city have been talking about? Now check their service list! Know what you’re going to spend before-hand, get the estimates and save yourself from that awkward conversation.</p>
              <p>Book appointments with Salons, Spas, MUAs, Hairstylists and even @home service experts seamlessly from the comfort of your home. Request a slot, get in touch and cut the waiting lines. Get assigned to the service provider of your choice. Book in advance and get discounts as well as exciting campaigns being run by your favourite Salons. Find amazing packages that suit you and your desire for being pampered.</p>
            </div>
          </div>
        </div>
        <div className='layer3'>
          <div className='section5'></div>
          <div className='section6'>
            <div className='container'>
              <div className='content'>
                <h3>Stay in Touch</h3>
                <p>We at Make-Over want you to feel the power. Share your experience so that your peers get to make an educated decision and experience the pampering they have been craving for! Don’t panic about fake reviews as we are going to personally verify every experience review. Stay in Touch</p>
                {renderFollowUpdatesContent()}
              </div>
            </div>
          </div>
        </div>
        {renderSuccessModal()}
      </div>
      {/* <div id='hero' className='flex-column'>
        <div style={{ flex: 1 }}>
          <div className='hero-content '>
            <div className='left-content'>
              <img
                src={icons().makeover_icon}
                alt='logo'
                className='hero-image'
              />
            </div>
            <div className='right-content'>
              <div>
                <div className='input-container'>
                  <FormInput
                    control={control}
                    name='email'
                    placeholder='Add your email for new updates'
                    containerStyle={{ margin: '0' }}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please add your email',
                      },
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: 'Not a valid email address',
                      },
                    }}
                    type='email'
                    inputStyle={{
                      height: '48px',
                      borderBottomLeftRadius: '50px',
                      borderTopLeftRadius: '50px',
                      paddingLeft: '20px',
                    }}
                  />
                  <Button
                    btnLoading={btnLoading}
                    label='follow updates'
                    id='btn'
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
                {errors['email'] && (
                  <FormHelperText
                    error
                    sx={{
                      maxWidth: '70vw',
                      margin: 'auto',
                      fontSize: 13,
                    }}
                  >
                    {errors['email']?.message}
                  </FormHelperText>
                )}
              </div>

              <div>
                <img
                  className='right-image'
                  src={icons().illustration_1}
                  alt='Illustration'
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <MuiModal
        isOpen={Modal}
        close={() => setModal(false)}
        title='Thank you for showing interest in us!'
      >
        <div className='flex-y-center'>
          <img
            src={successPlaceholder}
            alt='Success On Following'
            style={{ width: 300, height: 250, selfAlign: 'center' }}
          />

          <p className='margin-t-xs'>
            You will receive updates to your email whenever we have something
            interesting to share
          </p>
          <p className='margin-t-xs' style={{ textAlign: 'center' }}>
            Please read our{' '}
            <Link to='/policy'>
              <span
                style={{ color: colors.primary, textDecoration: 'underline' }}
              >
                Privacy policy
              </span>
            </Link>
          </p>

          <Button
            label='ok'
            onClick={() => setModal(false)}
            sx={{
              mt: 2,
              width: '100%',
            }}
          />
        </div>
      </MuiModal> */}
    </section>
  )
}

export default Home
