import { createSlice } from '@reduxjs/toolkit'
import { keys } from '../../../../constants/keys'
import { getDataFromLocalStorage } from '../../../../utils/helpers/localStorage'
import {
  deleteImage,
  getSinglePartnerInfo,
  getPartnerSalonImages,
  postCoverImage,
  postImages,
  updateCoverImage,
  updatePartnerListing,
  getAllPartnersList,
} from './asyncAction'
import { createPartner } from './create/asyncAction'
import { editAccount } from './update/asyncAction'

export const infoSlice = createSlice({
  name: 'partner_info',
  initialState: {
    allPartners: [],
    loading: false,
    imagesLoading: false,
    coverImgLoading: false,
    btnLoading: false,
    singlePartner: getDataFromLocalStorage(keys.KEY_PARTNER_DATA),
    profileImageUrl: '',
    userImages: [],
    coverImage: {},
    error: '',
  },

  reducers: {
    setCoverImage: (state, { payload }) => {
      state.coverImage = payload
    },
    updateServicesCount: (state, { payload }) => {
      const newServicesCount = payload?.servicesCount
      const partnerId = payload?.partnerId
      const index = state.allPartners?.findIndex((partner) => {
        return partner?.partnerDetails?.id === +partnerId
      })

      if (index !== -1) {
        state.allPartners[index].servicesCount = newServicesCount
        const copy = state.allPartners?.slice()
        state.allPartners = copy
      }
    },
  },
  extraReducers: {
    /*-----------------Get All Partners List-------------------*/

    [getAllPartnersList.pending]: (state) => void (state.loading = true),
    [getAllPartnersList.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.allPartners = payload?.allPartners ? payload.allPartners : []
    },
    [getAllPartnersList.rejected]: (state) => {
      state.loading = false
    },

    /*-----------------Get Single Partner Details-------------------*/

    [getSinglePartnerInfo.pending]: (state) => void (state.loading = true),
    [getSinglePartnerInfo.fulfilled]: (state, { payload }) => {
      state.loading = false
      if (payload) {
        state.singlePartner = payload.singlePartner
        state.profileImageUrl = payload.profileImageUrl
      }
      state.singlePartner = payload.singlePartner
      state.profileImageUrl = payload.profileImageUrl
    },
    [getSinglePartnerInfo.rejected]: (state, { payload }) => {
      state.loading = false
    },

    /*------------------Get Partner Salon Images and CoverImage-------------------*/

    [getPartnerSalonImages.pending]: (state) =>
      void (state.imagesLoading = true),
    [getPartnerSalonImages.fulfilled]: (state, { payload }) => {
      state.imagesLoading = false
      if (payload) {
        state.userImages = payload.userImages
        state.coverImage = payload.coverImage
      }
    },
    [getPartnerSalonImages.rejected]: (state, { payload }) => {
      state.imagesLoading = false
    },

    /*----------Edit User Account-------------*/

    [editAccount.pending]: (state) => void (state.btnLoading = true),
    [editAccount.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) state.singlePartner = payload?.singlePartner
    },
    [editAccount.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /*------------------Create Partner-------------------*/
    [createPartner.pending]: (state) => void (state.btnLoading = true),
    [createPartner.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      state.singlePartner.profileDetails = payload?.profileDetails
        ? payload.profileDetails
        : {}
    },
    [createPartner.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /*----------Upload Partner Listing-------------*/

    [updatePartnerListing.fulfilled]: (state, { payload }) => {
      if (payload) state.singlePartner.isFeatured = payload?.isFeatured
    },

    /*----------Post Cover Image-------------*/

    [postCoverImage.pending]: (state) => void (state.coverImgLoading = true),
    [postCoverImage.fulfilled]: (state, { payload }) => {
      state.coverImgLoading = false
      if (payload) state.coverImage = payload.coverImage
    },
    [postCoverImage.rejected]: (state, { payload }) => {
      state.coverImgLoading = false
    },

    /*----------Update Cover Image-------------*/

    [updateCoverImage.pending]: (state) => void (state.coverImgLoading = true),
    [updateCoverImage.fulfilled]: (state, { payload }) => {
      state.coverImgLoading = false
      if (payload) state.coverImage = payload.coverImage
    },
    [updateCoverImage.rejected]: (state, { payload }) => {
      state.coverImgLoading = false
    },

    /*----------Upload Salon Images-------------*/
    [postImages.pending]: (state, { payload }) => {
      state.btnLoading = true
    },

    [postImages.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) state.userImages = payload.userImages
    },
    [postImages.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },

    /*----------Delete Salon Images-------------*/

    [deleteImage.pending]: (state, { payload }) => {
      state.btnLoading = true
    },

    [deleteImage.fulfilled]: (state, { payload }) => {
      state.btnLoading = false
      if (payload) {
        state.userImages = state.userImages?.filter(
          (data) => data.id !== payload.id
        )
      }
    },
    [deleteImage.rejected]: (state, { payload }) => {
      state.btnLoading = false
    },
  },
})

export const { setCoverImage, updateServicesCount } = infoSlice.actions
export default infoSlice.reducer
