import React, { memo } from 'react'
import MuiModal from '../../../components/UI/Modal/MuiModal'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/UI/FormInput/FormInput'
import { useDispatch, useSelector } from 'react-redux'
import DropZoneField from '../../../components/UI/DropZone/DropZoneField'
import {
  createOrUpdateCategory,
  removeCategoryById,
} from '../../../data/reducers/services/admin/asyncAction'
import { showAlert } from '../../../data/reducers/common/alert.reducer'

const AddCategory = ({
  editMode = false,
  showModal = false,
  close = () => { },
  ...categoryData
}) => {
  const dispatch = useDispatch()
  const { btnLoading, deleteBtnLoading } = useSelector(
    (state) => state.services_admin
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    if (data.name === 'null') {
      dispatch(
        showAlert({
          type: 'warning',
          message: `Enter a valid category name`,
        })
      )
    } else {
      const url = editMode
        ? `/Services/category/update/${categoryData?.id}`
        : '/Services/category/create'
      const payload = {
        url,
        method: editMode ? 'PUT' : 'POST',
        data: { ...categoryData, ...data, imageFile: data.imageFile?.[0] },
        closeDialog: close,
      }
      if (editMode && !data.imageFile?.length) delete payload.data?.imageFile
      dispatch(createOrUpdateCategory(payload))
    }
  }

  const removeCategory = () =>
    dispatch(
      removeCategoryById({ categoryId: categoryData?.id, closeDialog: close })
    )

  return (
    <>
      <MuiModal
        containerStyle={{ width: '620px' }}
        isOpen={showModal}
        close={close}
        title={editMode ? categoryData?.name : 'New Category'}
        showButtons
        rightLabel={editMode ? 'Update' : 'Add'}
        leftLabel={editMode ? 'Remove' : 'Cancel'}
        rightBtnLoading={btnLoading}
        leftBtnLoading={deleteBtnLoading}
        onLeftBtnClick={editMode ? removeCategory : close}
        onRightBtnClick={handleSubmit(onSubmit)}
      >
        <div
          className='margin-t-s'
          style={{ overflowY: 'auto', paddingRight: 5, height: 400 }}
        >
          <FormInput
            readOnly={editMode}
            control={control}
            name='name'
            label='Category Name *'
            placeholder='Category Name'
            error={errors}
            defaultValue={categoryData?.name || ''}
            labelStyle={{ fontWeight: 'bold' }}
            rules={{
              required: { value: true, message: 'Please enter a category name' },
            }}
          />
          <div className='margin-t-s'>
            <FormInput
              control={control}
              name='description'
              defaultValue={categoryData?.description || ''}
              label='Description'
              labelStyle={{ fontWeight: 'bold' }}
              Component='textarea'
              row={20}
              column={25}
            />
          </div>
          <div>
            <DropZoneField
              label='Image *'
              control={control}
              name='imageFile'
              errors={errors}
              labelStyle={{ fontWeight: 'bold' }}
              defaultValue={categoryData?.imageUrl}
              rules={{
                required: {
                  value: categoryData?.imageUrl ? false : true,
                  message: 'Please upload the image',
                },
              }}
            />
          </div>
        </div>
      </MuiModal>
    </>
  )
}

export default memo(AddCategory)
