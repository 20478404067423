import { ArrowForward, Star } from '@mui/icons-material'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Accordion from '../../../../components/PartnerPageComponents/Accordion/Accordion'
import { getPartnerAmenities } from '../../../../data/reducers/partners/amenities/asyncAction'
import { getPartnerSchedule } from '../../../../data/reducers/partners/schedule/asyncAction'
import Amenities from './Amenities/Amenities'

const PartnerProfile = () => {
  const { singlePartner } = useSelector((state) => state.partners_info)
  const { partnerSchedule } = useSelector((state) => state.partners_schedule)
  const { partnerAmenities } = useSelector((state) => state.partners_amenities)
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const accountData = singlePartner?.profileDetails
  const amenities = partnerAmenities?.map((data) => [data.amenity])
  const amenitiesSlice = amenities ? amenities?.slice(0, 3) : []
  const amenitiesLeft = amenities?.length - amenitiesSlice.length
  const coverPic = singlePartner?.imageUrls?.find(
    (image) => image?.isCoverPic === true
  )

  useEffect(
    () =>
      params?.partnerId &&
      dispatch(getPartnerAmenities({ partnerId: params?.partnerId })),
    [params?.partnerId]
  )
  useEffect(() => {
    params?.partnerId && dispatch(getPartnerSchedule(params?.partnerId))
  }, [params?.partnerId])

  return (
    <div className='flex-x-center margin-t-s'>
      <div className='flex-column' style={{ width: '80%', gap: '15px' }}>
        <Accordion title='Account' page='account'>
          {accountData?.name && (
            <div className='flex-row'>
              <p style={{ fontSize: 15 }}>{accountData?.name}</p>
              <div className='line'></div>
            </div>
          )}
          {accountData?.phone && (
            <div className='flex-row'>
              <p style={{ fontSize: 15.5 }}>{accountData?.phone}</p>
              <div className='line'></div>
            </div>
          )}
          {accountData?.email && (
            <div className='flex-row'>
              <p style={{ fontSize: 15 }}>{accountData?.email}</p>
              <div className='line'></div>
            </div>
          )}
        </Accordion>

        <Accordion title='Business Hours' page='schedule'>
          {partnerSchedule?.workStartTime ? (
            <div className='flex-row'>
              <p style={{ fontSize: 15 }}>{partnerSchedule?.workStartTime}</p>
              <ArrowForward fontSize={'22'} style={{ alignSelf: 'center' }} />
              <p style={{ fontSize: 15 }}>{partnerSchedule?.workEndTime}</p>
            </div>
          ) : (
            <p>Add work schedule for your salon</p>
          )}
        </Accordion>
        <Accordion
          title='Amenities'
          disableRipple
          onClick={() => setShowModal(true)}
        >
          {amenitiesSlice?.length ? (
            amenitiesSlice?.map(
              (amenity, index) =>
                amenity && (
                  <div className='flex-row' key={index}>
                    <p style={{ fontSize: 15 }}>{amenity}</p>
                    <div className='line'></div>
                  </div>
                )
            )
          ) : (
            <p>Add Amenities for your salon</p>
          )}
          {amenitiesLeft ? <p>{`+${amenitiesLeft} more`}</p> : null}
        </Accordion>
        <Amenities isOpen={showModal} close={() => setShowModal(false)} />

        <Accordion title='Experiences' disableRipple>
          {singlePartner?.rating ? (
            <div className='flex-row'>
              <p style={{ fontSize: 15, fontWeight: 'bold' }}>
                {singlePartner?.rating}
                {typeof parseInt(singlePartner?.rating) === 'number' ? (
                  <Star fontSize='20' sx={{ mb: 0.5, ml: 0.7 }} />
                ) : null}
              </p>
              <div className='line'></div>
            </div>
          ) : null}

          {singlePartner?.reviews ? (
            <div className='flex-row'>
              <p style={{ fontSize: 15 }}>
                {`${singlePartner?.reviews} experiences`}
              </p>
              <div className='line'></div>
            </div>
          ) : null}
        </Accordion>
        {coverPic?.fileUrl ? (
          <Accordion
            title='Gallery'
            contentContainerStyle={{ display: 'block' }}
            disableRipple
          >
            <img
              src={coverPic?.fileUrl}
              alt='CoverImage'
              style={{ width: '350px', height: '250px', objectFit: 'cover' }}
            />
          </Accordion>
        ) : null}
      </div>
    </div>
  )
}

export default memo(PartnerProfile)
