export const colors = {
  primary: '#8e278f',
  purpleBackground: '#e0dce9',
  deepPurple: '#413962',
  pinkTint2: '#f2d9e8',
  lightGreen: '#d1e4a8',
  green: '#28a745',
  red: '#dc3545',
  grey5: '#e0e0e0',
  grey4: '#bdbdbd',
  grey3: '#828282',
  grey2: '#4f4f4f',
  grey1: '#333333',
  purpleTint1: '#CD7FCD',
  yellow: '#fbdb7d',
  orange: '#ff8871',
  darkGreen: '#4a670d',
  black: '#000',
  background: '#E0DCE9',
}
