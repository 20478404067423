import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import BgContainer from '../../../components/UI/BgContainer/BgContainer'
import FormInput from '../../../components/UI/FormInput/FormInput'
import { useForm } from 'react-hook-form'
import { CountryCodeIcon } from '../../../components/PartnerPageComponents/CountryCodeList/CountryCodeList'
import { MyLocationSharp } from '@mui/icons-material'
import Button from '../../../components/UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../../assets/themes/colors/colors'
import { useDispatch, useSelector } from 'react-redux'
import { getDropDownValues } from '../../../data/reducers/common/asyncAction'
import { createPartner } from '../../../data/reducers/partners/info/create/asyncAction'
import { useNavLocation } from '../../../hooks/useNavLocation'

const CreatePartner = () => {
  const navigate = useNavigate()
  const { businessType } = useSelector((state) => state.common)
  const { foundLocation, getLocation } = useNavLocation()
  const dispatch = useDispatch()
  const { btnLoading } = useSelector((state) => state.partners_info)
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm()

  useEffect(() => {
    !businessType?.length && dispatch(getDropDownValues())
  }, [])

  useEffect(() => {
    foundLocation?.lat &&
      foundLocation?.lon &&
      setValue(
        'location',
        `lat-${foundLocation?.lat} , lon-${foundLocation?.lon} `
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLocation])

  const _businessType = useMemo(
    () =>
      businessType?.map((data, index) => (
        <FormControlLabel
          key={index}
          value={data.id}
          control={<Radio />}
          label={data.label}
        />
      )),
    [businessType]
  )

  const onSubmit = useCallback(
    (data) => {
      const copy = { ...data }
      delete copy?.location
      const payload = {
        navigate: (path) => navigate(path),
        data: {
          ...copy,
          PartnerType: +copy.PartnerType,
          lat: foundLocation.lat,
          lon: foundLocation.lon,
          IsFromAdmin: true,
          Documents: [],
        },
      }
      dispatch(createPartner(payload))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [foundLocation]
  )

  return (
    <BgContainer>
      <div className='flex-column'>
        <Box
          sx={{ width: '55%', justifyContent: 'center', alignSelf: 'center' }}
        >
          <p style={{ fontSize: '30px', fontWeight: 100 }}>Details</p>
          <form
            className='flex-column margin-t-s'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='flex-y-center'>
              <FormInput
                labelStyle={{ fontWeight: 'bold' }}
                error={errors}
                label='Name of business'
                control={control}
                name='NameOfEstablishment'
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide salon name',
                  },
                }}
              />

              <FormInput
                type='tel'
                error={errors}
                labelStyle={{ fontWeight: 'bold' }}
                label='Contact number'
                control={control}
                name='PhoneNumber'
                iconPosition='left'
                icon={<CountryCodeIcon />}
                inputStyle={{ paddingLeft: '100px' }}
                maxLength={10}
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide contact number',
                  },
                  maxLength: {
                    value: 10,
                    message: 'invalid phone number',
                  },
                  minLength: {
                    value: 10,
                    message: 'invalid phone number',
                  },
                }}
              />

              <FormInput
                error={errors}
                label='Email ID'
                labelStyle={{ fontWeight: 'bold' }}
                control={control}
                name='Email'
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide email',
                  },
                }}
              />

              <div style={{ width: '100%' }} className='margin-b-s'>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  Business type
                </Typography>
                <RadioGroup
                  className='flex-row'
                  sx={{ my: 1 }}
                  name='PartnerType'
                  onChange={(e, value) => setValue('PartnerType', value)}
                >
                  {_businessType}
                </RadioGroup>
              </div>
              <div style={{ width: '100%' }} className='margin-b-s'>
                <Typography
                  variant='body1'
                  style={{ fontWeight: 'bold', textAlign: 'left' }}
                >
                  Address
                </Typography>
              </div>

              <FormInput
                error={errors}
                label='Postal address'
                control={control}
                name='Address'
              />

              <FormInput
                error={errors}
                label='State'
                control={control}
                name='State'
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide state name',
                  },
                }}
              />

              <FormInput
                error={errors}
                label='City'
                control={control}
                name='City'
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide city name',
                  },
                }}
              />

              <FormInput
                error={errors}
                label='Pin code'
                control={control}
                name='PinCode'
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide pincode',
                  },
                }}
              />

              <FormInput
                readOnly={true}
                labelStyle={{ fontWeight: 'bold' }}
                error={errors}
                label='Location'
                control={control}
                name='location'
                iconPosition='right'
                rules={{
                  required: {
                    value: true,
                    message: 'Please provide location',
                  },
                }}
                inputStyle={{ paddingRight: '40px' }}
                icon={
                  <IconButton onClick={getLocation}>
                    <MyLocationSharp
                      htmlColor={
                        foundLocation?.lat ? colors.purpleTint1 : 'inherit'
                      }
                    />
                  </IconButton>
                }
              />

              <div id='map'></div>
              <div
                style={{ width: '100%' }}
                className='flex-row-rev margin-t-s'
              >
                <div className='flex-row'>
                  <Button
                    label='Cancel'
                    variant='outlined'
                    onClick={() => navigate(-1)}
                  />
                  <Button btnLoading={btnLoading} type='submit' label='next' />
                </div>
              </div>
            </div>
          </form>
        </Box>
      </div>
    </BgContainer>
  )
}

export default CreatePartner
