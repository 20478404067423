import React, { memo, useEffect } from 'react'
import MuiAlert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { closeAlert } from '../../../data/reducers/common/alert.reducer'

const Alert = () => {
  const {
    message,
    show,
    type: status = 'success',
    props,
  } = useSelector((state) => state.alert)
  const dispatch = useDispatch()

  useEffect(() => {
    if (show) {
      const time = setTimeout(() => dispatch(closeAlert()), 3500)
      return () => clearTimeout(time)
    }
  }, [show])

  return (
    <div
      style={{zIndex: 10000}}
      className={`flex-row alert-custom transition  ${show && 'show-alert'} `}
    >
      <MuiAlert
        onClose={() => dispatch(closeAlert())}
        severity={status || 'info'}
        variant={props.variant || 'filled'}
        {...props}
      >
        {message || 'An Alert'}
      </MuiAlert>
    </div>
  )
}

export default memo(Alert)
