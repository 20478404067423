import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from '../../../../../assets/themes/colors/colors'
import BgContainer from '../../../../../components/UI/BgContainer/BgContainer'
import Button from '../../../../../components/UI/Button/Button'
import Connect from '../../../../../components/UI/Connect/Connect'
import { getDropDownValues } from '../../../../../data/reducers/common/asyncAction'
import {
  createPartnerSchedule,
  getPartnerSchedule,
  updatePartnerSchedule,
} from '../../../../../data/reducers/partners/schedule/asyncAction'
import { removeSeconds } from '../../../../../utils/helpers/time'
import ScheduleComponent from '../../../../../components/PartnerPageComponents/Schedule/Schedule'
import { getDataFromLocalStorage } from '../../../../../utils/helpers/localStorage'
import { keys } from '../../../../../constants/keys'

const Schedule = () => {
  const navigate = useNavigate()
  const methods = useForm()
  const { daysRange } = useSelector((state) => state.common)
  const { btnLoading, partnerSchedule, partnerScheduleByDays } = useSelector(
    (state) => state.partners_schedule
  )
  const dispatch = useDispatch()
  const params = useParams()
  const [selectedDays, setSelectedDays] = useState([])
  const [check, setCheck] = useState(false)
  const [workSchedule, setWorkSchedule] = useState({})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => !daysRange?.length && dispatch(getDropDownValues()), [])

  useEffect(() => {
    if (partnerScheduleByDays) {
      let workDays = partnerScheduleByDays?.filter(
        (data) => data?.isWorkDay === true
      )
      workDays?.length
        ? setSelectedDays(workDays?.map((data) => data.dayId))
        : setSelectedDays(daysRange?.map((day) => day.id))
    } else setSelectedDays(daysRange?.map((day) => day.id))
  }, [daysRange, partnerScheduleByDays])

  useEffect(() => {
    dispatch(getPartnerSchedule(params?.partnerId))
  }, [params?.partnerId])

  const selectDaysHandler = useCallback(
    (dayId) => {
      const isSelected = selectedDays?.includes(dayId)
      setSelectedDays([...selectedDays, dayId])
      if (isSelected)
        setSelectedDays((prev) => prev.filter((id) => +id !== +dayId))
      else setSelectedDays([...selectedDays, dayId])
    },
    [selectedDays]
  )

  const _renderDays = useMemo(
    () =>
      daysRange?.map((day, index) => (
        <IconButton key={index} onClick={() => selectDaysHandler(day.id)}>
          <Avatar
            style={avatarStyles(selectedDays?.includes(day.id) ? true : false)}
          >
            {day?.name[0]}
          </Avatar>
        </IconButton>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [daysRange, selectedDays]
  )

  const onSubmit = (data) => {
    const workStartTime = removeSeconds(
      workSchedule?.work?.fromTime?.toLocaleTimeString() // Converting Date Obj => HH:MM (Am/Pm)
    )
    const workEndTime = removeSeconds(
      workSchedule?.work?.toTime?.toLocaleTimeString()
    )
    const listBreakTimes = data?.break?.map((list) => ({
      breakStartTime: removeSeconds(list.fromTime?.toLocaleTimeString()),
      breakEndTime: removeSeconds(list.toTime?.toLocaleTimeString()),
    }))

    const workScheduleByDays = selectedDays?.map((dayId) => ({
      partnerId: +params?.partnerId,
      dayId,
      isWorkDay: true,
      listBreakTimes: [],
      workEndTime,
      workStartTime,
    }))

    const payload = {
      data: {
        ...partnerSchedule,
        partnerId: +params?.partnerId,
        workStartTime,
        workEndTime,
        listBreakTimes,
        workScheduleByDays,
      },
      navigate: () =>
        navigate(
          check ? `/partner/${params?.partnerId}/profile/schedule/days` : -1
        ),
    }
    if (partnerSchedule?.id) {
      dispatch(updatePartnerSchedule(payload))
    } else {
      delete payload.data.id
      dispatch(createPartnerSchedule(payload))
    }
  }

  return (
    <FormProvider {...methods}>
      <BgContainer
        title={
          getDataFromLocalStorage(keys.KEY_PARTNER_DATA)?.profileDetails
            ?.name || '  '
        }
        rightComponent={
          <Connect
            email={
              getDataFromLocalStorage(keys.KEY_PARTNER_DATA)?.profileDetails
                ?.email
            }
            phone={
              getDataFromLocalStorage(keys.KEY_PARTNER_DATA)?.profileDetails
                ?.phone
            }
          />
        }
      >
        <div className='flex-column'>
          <Box sx={{ justifyContent: 'center', alignSelf: 'center' }}>
            <p style={{ fontSize: '30px', fontWeight: 100 }}>Business Hours</p>
            <ScheduleComponent
              getWorkSchedule={(data) => setWorkSchedule(data)}
              closeCheckbox={false}
              editMode={true}
            />

            <div className='margin-t-m'>
              <h6 style={{ fontWeight: 'bold' }}>{'Days of week'}</h6>
              <div className='flex-x-evenly margin-t-s'>{_renderDays}</div>
              <div className='margin-t-s'>
                <FormControlLabel
                  control={<Checkbox />}
                  sx={{ fontSize: 15 }}
                  onChange={(e) => setCheck(e.target?.checked)}
                  label={
                    <p style={{ fontSize: '15px' }}>
                      Schedules differ based on the day of the week
                    </p>
                  }
                />
              </div>
            </div>

            <div style={{ width: '100%' }} className='flex-row-rev margin-t-m'>
              <div className='flex-row'>
                <Button
                  label='previous'
                  variant='outlined'
                  onClick={() => navigate(-1)}
                />
                <Button
                  type='submit'
                  label='next'
                  btnLoading={btnLoading}
                  onClick={methods.handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </Box>
        </div>
      </BgContainer>
    </FormProvider>
  )
}

const avatarStyles = (active) => ({
  backgroundColor: active ? colors.pinkTint2 : colors.grey5,
  color: 'black',
  fontSize: 16,
})

export default Schedule
