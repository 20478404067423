import makeover_icon from './makeover-icon.png'
import illustration_1 from './Illustration.png'
import makeover_icon_small from './makeover-icon-small.svg'
import onBoardSuccess from './onboard_success.svg'
import add_icon from './add_icon.svg'

export const icons = () => {
  return {
    makeover_icon,
    makeover_icon_small,
    illustration_1,
    onBoardSuccess,
    add_icon,
  }
}
