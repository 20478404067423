import React, { useEffect, useState } from 'react'

const ImagePreview = ({ files, defaultUrl = '' }) => {
  const [_fileUrl, _setFileUrl] = useState('')
  const name = files?.length > 0 ? files[0].name : 'Preview'
  const size = files?.length > 0 ? files[0].size : 0

  useEffect(() => {
    if (typeof files === 'string') {
      _setFileUrl(files)
      return
    }
    if (URL && Array.isArray(files)) {
      const imgUrl = files?.length > 0 ? URL.createObjectURL(files[0]) : ''
      _setFileUrl(imgUrl)
    }
  }, [files])
  return (
    <div className='render-preview'>
      <div className='image-container'>
        <img src={_fileUrl || defaultUrl} alt={name} />
      </div>
      {name && size ? (
        <div className='details'>
          {name} - {(+size / 1024000).toFixed(2)}MB
        </div>
      ) : null}
    </div>
  )
}

export default ImagePreview
