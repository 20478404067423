import { useEffect, useState } from 'react'
import { Routes, useLocation } from 'react-router-dom'
import Header from '../components/CommonComponents/Header/Header'
import Alert from '../components/UI/Alert/Alert'
import { navLinks } from '../utils/navigation/navLinks'

const CustomRoutes = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState('')
  const location = useLocation()
  const [selectedLink, setSelectedLink] = useState({ id: 2 })

  useEffect(() => {
    const activeLink = navLinks().find((link) =>
      location.pathname?.includes(link.url)
    )
    setSelectedLink(activeLink)
  }, [location?.pathname])

  useEffect(() => {
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      <Header selectedLink={selectedLink} setSelectedLink={setSelectedLink} />
      <Routes>{children}</Routes>
      <div className='flex-row'>
        <Alert />
      </div>
    </>
  )
}

export default CustomRoutes
