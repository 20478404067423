import React from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { colors } from '../../../assets/themes/colors/colors'

const Placeholder = () => (
  <div className='placeholder-preview'>
    <CloudUploadIcon style={{ fontSize: 50 }} htmlColor={colors.primary} />
    <p className='margin-t-s'>
      Click or drag image file to this area to upload.
    </p>
  </div>
)

export default Placeholder
