import { createTheme } from '@mui/material/styles'
import { colors } from './colors/colors'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#8E278F',
      light: '#cd7fcd',
    },
    secondary: {
      main: '#F2D9E8',
    },
    divider: '#bdbdbd',
    success: {
      main: '#4A670D',
      light: '#d1e4a8',
    },
    action: {},
  },
  typography: {
    fontFamily: 'Open Sans',
    button: {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 48,
          transition: 'all .4s ease',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        notchedOutline: {
          // border: 'none',
          transition: 'all .2s ease',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: 'green',
        },
      },
    },
  },
})
