import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from './Modal.js'
import Button from '../Button/Button'

const style = {
  width: 480,
  p: 3.5,
  bgcolor: 'white',
  borderRadius: 5,
}

const MuiModal = ({
  isOpen = false,
  title = '',
  message = '',
  children,
  showButtons = false,
  leftLabel = 'cancel',
  rightLabel = 'Submit',
  leftBtnLoading = false,
  rightBtnLoading = false,
  containerStyle = {},

  close = () => {},
  onLeftBtnClick = () => {},
  onRightBtnClick = () => {},
}) => {
  return (
    <>
      <Modal show={isOpen} close={close}>
        <Box sx={{ ...style, ...containerStyle }}>
          {title && <h4 style={{ textAlign: 'center' }}>{title}</h4>}
          {children && <div>{children}</div>}
          <>
            {message && <div className='margin-t-xs'>{message}</div>}
            {showButtons && (
              <div
                style={{ width: '100%' }}
                className='flex-row-rev margin-t-s'
              >
                <div className='flex-row'>
                  <Button
                    btnLoading={leftBtnLoading}
                    label={leftLabel}
                    variant='outlined'
                    onClick={onLeftBtnClick}
                  />
                  <Button
                    btnLoading={rightBtnLoading}
                    label={rightLabel}
                    onClick={onRightBtnClick}
                  />
                </div>
              </div>
            )}
          </>
        </Box>
      </Modal>
    </>
  )
}

export default MuiModal
