import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { colors } from '../../../assets/themes/colors/colors'

const SelectInput = ({
  control,
  name = '',
  label = false,
  data = [],
  defaultValue,
  rules = {},
  muiLabelStyle = {},
  setValue = () => {},
  textFieldProps = {},
  ...props
}) => {
  useEffect(
    () => defaultValue && setValue(name, defaultValue),
    [defaultValue, name]
  )

  return (
    <>
      {label && <FormLabel sx={{ mb: 1, ...muiLabelStyle }}>{label}</FormLabel>}
      <Controller
        control={control}
        name={name}
        rules={{ required: true, ...rules }}
        render={({ field: { value = '' } }) => (
          <FormControl
            sx={{
              width: '100%',
              border: `1.5px solid ${colors.grey4}`,
              borderRadius: 2,
            }}
          >
            <Autocomplete
              options={data}
              disablePortal
              value={data?.find((type) => type.id === value)?.label || ''}
              {...props}
              onChange={(e, value = '') =>
                setValue(name, value !== undefined ? value?.id : {})
              }
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField {...params} variant='outlined' {...textFieldProps} />
              )}
            />
          </FormControl>
        )}
      />
    </>
  )
}

export default SelectInput
