export const convertJsonToFormData = (jsonData = {}) => {
  const formData = new FormData()

  for (const key in jsonData) {
    if (Array.isArray(jsonData[key])) {
      const array = jsonData[key]
      const newKey = key
      delete jsonData[key]

      for (let i = 0; i < array.length; i++) {
        const data =
          typeof array[i] === 'object' ? JSON.stringify(array[i]) : array[i]
        formData.append(`${newKey}[${i}]`, data)
      }
    } else if (typeof jsonData[key] === 'string') {
      jsonData[key] === 'null'
        ? formData.append(key, '')
        : formData.append(key, jsonData[key])
    } else if (typeof jsonData[key] === 'object') {
      jsonData[key] === null
        ? formData.append(key, '')
        : formData.append(key, jsonData[key])
    } else {
      formData.append(key, jsonData[key])
    }
  }
  return formData
}
