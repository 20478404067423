import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedLink } from '../../../data/reducers/common/common.reducer'

function Background({ children, title, hideTitle = false }) {
  const dispatch = useDispatch()

  return (
    <div className='background'>
      <div className='bread-crumb'>
        <div
          style={{ maxWidth: '900px', margin: 'auto' }}
          className='flex-row-even'
        >
          <Link to='/'>
            <span
              onClick={() => dispatch(setSelectedLink({ id: 0 }))}
              style={{ color: '#666' }}
            >
              Home
            </span>
          </Link>
          <span style={{ margin: '0px 4px' }}>/</span>
          <span style={{ fontWeight: 'bold', color: 'black' }}>{title}</span>
        </div>
      </div>
      <div className='content'>
        {!hideTitle && <p className='background_title'>{title}</p>}
        <div className='content-card'>{children}</div>
      </div>
    </div>
  )
}

export default Background
